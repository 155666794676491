import types from './types';

export const listProductsRequest = () => ({
  type: types.LIST_REQUEST,
});
export const listProductsSuccess = ({ products }) => ({
  type: types.LIST_SUCCESS,
  payload: { products },
});
export const listProductsFailure = () => ({
  type: types.LIST_FAILURE,
});

export const searchProductsRequest = ({ filters }) => ({
  type: types.SEARCH_REQUEST,
  payload: { filters },
});
export const searchProductsSuccess = products => ({
  type: types.SEARCH_SUCCESS,
  payload: { products },
});
export const searchProductsFailure = () => ({
  type: types.SEARCH_FAILURE,
});

export const createRequest = name => ({
  type: types.CREATE_REQUEST,
  payload: name,
});
export const createSuccess = ({ product }) => ({
  type: types.CREATE_SUCCESS,
  payload: { product },
});
export const createFailure = () => ({
  type: types.CREATE_FAILURE,
});

export const setIsOpenedModal = modalName => ({
  type: types.SET_IS_OPENED_MODAL,
  payload: modalName,
});

export const updateRequest = product => ({
  type: types.UPDATE_REQUEST,
  payload: product,
});
export const updateSuccess = () => ({
  type: types.UPDATE_SUCCESS,
});
export const updateFailure = () => ({
  type: types.UPDATE_FAILURE,
});

export const deleteRequest = ({ id }) => ({
  type: types.DELETE_REQUEST,
  payload: { id },
});
export const deleteSuccess = () => ({
  type: types.DELETE_SUCCESS,
});
export const deleteFailure = () => ({
  type: types.DELETE_FAILURE,
});
