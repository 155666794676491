import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { FooterButtons } from '../../../styles/containers';

import { formatDate } from '../../../helpers/formatDate';

const statusOptions = [
  {
    label: 'Nova',
    value: 'nova',
  },
  {
    label: 'Autorizada',
    value: 'autorizada',
  },
  {
    label: 'Não Autorizada',
    value: 'nao_autorizada',
  },
  {
    label: 'Processada',
    value: 'processada',
  },
  {
    label: 'Aguardando Recurso',
    value: 'aguardando_recurso',
  },
  {
    label: 'Finalizada',
    value: 'finalizada',
  },
  {
    label: 'Cancelada',
    value: 'cancelada',
  },
];

export default function FormControleInterno({ data, onSubmit, loading }) {
  const [initialData, setInitialData] = useState(() => {
    const { numero_nota, status, competencia, data_emissao_nota } = data;
    return {
      numero_nota: numero_nota || '',
      competencia: (competencia && formatDate(competencia, 'yyyy-MM')) || '',
      data_emissao_nota:
        (data_emissao_nota && formatDate(data_emissao_nota, 'yyyy-MM-DD')) ||
        '',
      status,
    };
  });

  useEffect(() => {
    return () => {
      setInitialData({});
    };
  }, []);

  const onSubmitInvoice = useCallback(
    values => {
      if (onSubmit) onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialData}
      validationSchema={Yup.object().shape({
        numero_nota: Yup.string(),
        competencia: Yup.string(),
        data_emissao_nota: Yup.string().required('Informe a data de emissão'),
        status: Yup.string()
          .required('Informe o status')
          .nullable(),
      })}
      onSubmit={values => {
        onSubmitInvoice(values);
      }}
      enableReinitialize
    >
      {({ handleSubmit, values, errors, setFieldValue, touched }) => (
        <form onSubmit={handleSubmit}>
          <div className="card__header">
            <h3>Controle Interno</h3>
          </div>
          <Row>
            <Col>
              <Input
                label="Número da nota"
                name="numero_nota"
                value={values.numero_nota}
                error={errors.numero_nota && touched.numero_nota}
                errorMessage={errors.numero_nota}
                onChange={({ target }) =>
                  setFieldValue('numero_nota', target.value)
                }
              />
            </Col>
            <Col>
              <Field
                placeholder="Status"
                name="status"
                component={Select}
                options={statusOptions}
                label="Status"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Competência"
                type="month"
                name="competencia"
                value={values.competencia}
                error={errors.competencia && touched.competencia}
                errorMessage={errors.competencia}
                onChange={({ target }) =>
                  setFieldValue('competencia', target.value)
                }
              />
            </Col>
            <Col>
              <Input
                label="Data de emissão"
                type="date"
                name="data_emissao_nota"
                value={values.data_emissao_nota}
                error={errors.data_emissao_nota && touched.data_emissao_nota}
                errorMessage={errors.data_emissao_nota}
                onChange={({ target }) =>
                  setFieldValue('data_emissao_nota', target.value)
                }
              />
            </Col>
          </Row>

          <FooterButtons>
            <Link to="/invoices">
              <Button
                label="Cancelar"
                color="black"
                variant="clean"
                type="button"
              />
            </Link>

            <Button
              label="Salvar"
              color="success"
              type="submit"
              loading={loading}
              width="85px"
              className="font-medium"
            />
          </FooterButtons>
        </form>
      )}
    </Formik>
  );
}

FormControleInterno.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

FormControleInterno.defaultProps = {
  data: {},
  onSubmit: () => {},
  loading: false,
};
