import api from '../../api';

export default {
  index: async () => {
    const res = await api.get('/products');
    return res.data;
  },

  search: async filters => {
    const res = await api.get('/products/search', { params: { ...filters } });
    return res;
  },

  store: async payload => {
    const res = await api.post('/products', payload);
    return res.data;
  },

  update: async payload => {
    const res = await api.put('/products', payload);
    return res.data;
  },

  delete: async payload => {
    const res = await api.delete(`/products/${payload.id}`);
    return res.data;
  },
};
