import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  padding: 36px;

  width: 100%;
  max-width: 410px;

  animation: ${appearFromRight} 1s;

  h1 {
    color: var(--primary-text);
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #fff;
    padding: 18px;
    border-radius: 4px;

    button {
      margin-top: 18px;
      width: 100%;
    }
  }

  a {
    color: var(--primary-color-lighten);
    font-size: 16px;
    margin-top: 40px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }
  }
`;
