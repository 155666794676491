import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdChat } from 'react-icons/md';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';
import axios from 'axios';

import { toast } from 'react-toastify';
import api from '../../services/api';

import { useSocket } from '../../hooks/socket';

import {
  Container,
  Badge,
  NotificationContent,
  NotificationHeader,
  NotificationList,
  NotificationEmptyMessage,
  Notification,
} from './styles';

export default function ChatNotifications() {
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { profile: user } = useSelector(({ profile }) => profile);

  const history = useHistory();
  const notificationsContentRef = useRef(null);
  const buttonRef = useRef(null);

  const { socket } = useSocket();

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    (async () => {
      try {
        const response = await api.get('/chats/notifications/by-user', {
          cancelToken: source.token,
        });

        const formttedNotifications =
          response &&
          response.data.length &&
          response.data.map(_notification => {
            return {
              ..._notification,
              timeDistance: formatDistance(
                parseISO(_notification.updatedAt),
                new Date(),
                {
                  addSuffix: true,
                  locale: pt,
                }
              ),
            };
          });

        setNotifications(formttedNotifications);
      } catch (err) {
        toast.error('Erro ao carregar as notificações');
      }
    })();

    return () => source.cancel();
  }, []);

  useEffect(() => {
    if (user && user.id) {
      socket.on(`notifications/chats/user/${user.id}`, newNotification => {
        const formattedNewNotification = {
          ...newNotification,
          timeDistance: formatDistance(
            parseISO(newNotification.updatedAt),
            new Date(),
            {
              addSuffix: true,
              locale: pt,
            }
          ),
        };

        setNotifications(oldNotifications => {
          const isFirstNotification = !oldNotifications.length;
          if (isFirstNotification) return [formattedNewNotification];

          const notificationExists = oldNotifications.find(
            findNotitification => findNotitification._id === newNotification._id
          );

          if (notificationExists) {
            const arrayWithoutUpdatedNotification = oldNotifications.filter(
              oldNotification => oldNotification._id !== newNotification._id
            );
            return [
              formattedNewNotification,
              ...arrayWithoutUpdatedNotification,
            ];
          }

          return [formattedNewNotification, ...oldNotifications];
        });
      });
    }
  }, [socket, user]);

  function handleClickOutside(event) {
    if (
      notificationsContentRef.current &&
      !notificationsContentRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  });

  const checkIfHasUnread = useMemo(() => {
    return (
      notifications && notifications.find(notification => !notification.read)
    );
  }, [notifications]);

  const handleMarkAsRead = useCallback(
    async id => {
      try {
        const response = await api.patch(`/chats/notifications/${id}/read`);

        const updatedNotification = response.data;

        const updatedNotifications = notifications.map(notification =>
          notification._id === updatedNotification._id
            ? {
                ...updatedNotification,
                timeDistance: notification.timeDistance,
              }
            : notification
        );

        setNotifications(updatedNotifications);
      } catch (err) {
        toast.error('Erro ao atualizar notificação');
      }
    },
    [notifications]
  );

  const handleToChatPage = useCallback(
    id => {
      history.push(`/chat/${id}`);
      setVisible(false);
    },
    [history]
  );

  return (
    <Container>
      <Badge
        hasUnread={checkIfHasUnread}
        onClick={() => setVisible(!visible)}
        visible={visible}
        ref={buttonRef}
      >
        <MdChat color="#fff" size={18} />
      </Badge>

      <NotificationContent visible={visible} ref={notificationsContentRef}>
        <NotificationHeader>Mensagens</NotificationHeader>

        <NotificationList>
          {notifications && notifications.length ? (
            <>
              {notifications.map(notification => (
                <Notification
                  key={notification._id}
                  unread={!notification.read}
                >
                  <div className="info">
                    <h5>{notification.content}</h5>
                    <time>{notification.timeDistance}</time>
                  </div>
                  <div className="actions">
                    {!notification.read && (
                      <button
                        type="button"
                        className="button__mark-as-read"
                        onClick={() => handleMarkAsRead(notification._id)}
                      >
                        Marcar como lida
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={() => handleToChatPage(notification.chat_id)}
                    >
                      Visualizar
                    </button>
                  </div>
                </Notification>
              ))}
            </>
          ) : (
            <NotificationEmptyMessage>
              Não há notificações
            </NotificationEmptyMessage>
          )}
        </NotificationList>
      </NotificationContent>
    </Container>
  );
}
