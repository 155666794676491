import styled from 'styled-components';

export const Filters = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0;
  }

  .filter__text-input {
    padding: 8px;
  }

  .filter__text-input,
  .date-range-filter__input {
    appearance: none;
    background: transparent;
    border: 2px solid var(--input-border);
    border-radius: var(--input-radius);
    color: var(--primary-text);
    font-size: 14px;
    outline: none;
    height: 36px;
    width: 100%;

    &:focus {
      outline: 1px auto var(--input-focus);
    }

    @media screen and (max-width: 720px) {
      margin-right: 0px;
    }
  }
`;
