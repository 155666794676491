import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';

export const CustomField = styled(NumberFormat)`
  ${props =>
    props.displayType === 'input' &&
    css`
      appearance: none;
      background: var(--input-background);
      border: 2px solid var(--input-border);
      border-radius: var(--input-radius);
      color: var(--primary-text);
      font-size: 14px;
      padding: 8px;
      position: relative;
      outline: none;
      height: 36px;

      width: 100%;
      height: ${({ height }) => height};

      outline: ${({ error }) => error && `1px auto var(--input-border-error)`};

      &:disabled {
        background: var(--input-disabled);
      }

      &:focus {
        outline: ${({ error }) =>
          error
            ? '1px auto var(--input-border-error)'
            : '1px auto var(--input-focus)'};
      }
    `}
`;
