import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(47, 56, 69, 0.5);
  z-index: 25000;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading-center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 100px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 6px #9db5cb3b;
    border-radius: 50%;
  }

  .loading-logo {
    animation: FadeInOut 1.6s linear infinite;
    position: absolute;
  }

  .loading-square {
    position: absolute;
    animation: Rotate 1.6s linear infinite;
  }

  path {
    fill: #2095f3;
  }

  @keyframes Rotate {
    50% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes FadeInOut {
    50% {
      width: 50px;
      height: 50px;
      top: 25px;
      left: 25px;
      opacity: 1;
      transform: rotate(0deg);
    }
    65% {
      opacity: 0;
      transform: rotate(180deg);
      width: 0;
      height: 0;
      top: 50px;
      left: 50px;
    }
    75% {
      opacity: 0;
      transform: rotate(180deg);
      width: 0;
      height: 0;
      top: 50px;
      left: 50px;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg);
      width: 50px;
      height: 50px;
      top: 25px;
      left: 25px;
    }
  }
`;
