import styled from 'styled-components';

export const CardInfoWrapper = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;

  &.danger {
    background-color: #ffdde0;
    color: var(--danger-color);
    border: 1px solid var(--red-400);
    border-radius: 4px;
  }
`;

export const CardInfoIcon = styled.span`
  width: max-content;
  height: max-content;
  max-width: 32px;
  max-height: 32px;
`;

export const CardInfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardInfoTitle = styled.h4`
  font-size: 1.8rem;
`;

export const CardInfoDescription = styled.p`
  font-size: 1.6rem;
`;
