import types from './types';

export const fundosRequest = () => ({
  type: types.LIST_REQUEST,
});
export const fundosSuccess = ({ fundos }) => ({
  type: types.LIST_SUCCESS,
  payload: { fundos },
});
export const fundosFailure = () => ({
  type: types.LIST_FAILURE,
});

export const searchFundosRequest = ({ filters }) => ({
  type: types.SEARCH_REQUEST,
  payload: { filters },
});
export const searchFundosSuccess = fundos => ({
  type: types.SEARCH_SUCCESS,
  payload: { fundos },
});
export const searchFundosFailure = () => ({
  type: types.SEARCH_FAILURE,
});

export const fundoRequest = ({ id }) => ({
  type: types.SHOW_REQUEST,
  payload: { id },
});
export const fundoSuccess = fundo => ({
  type: types.SHOW_SUCCESS,
  payload: fundo,
});
export const fundoFailure = () => ({
  type: types.SHOW_FAILURE,
});
export const resetLoadingFundo = () => ({
  type: types.RESET_LOADING_SHOW,
});

export const createRequest = fundo => ({
  type: types.CREATE_REQUEST,
  payload: fundo,
});
export const createSuccess = () => ({
  type: types.CREATE_SUCCESS,
});
export const createFailure = () => ({
  type: types.CREATE_FAILURE,
});

export const updateRequest = fundo => ({
  type: types.UPDATE_REQUEST,
  payload: fundo,
});
export const updateSuccess = () => ({
  type: types.UPDATE_SUCCESS,
});
export const updateFailure = () => ({
  type: types.UPDATE_FAILURE,
});
