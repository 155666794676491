const Types = {
  LIST_REQUEST: '@invoice/LIST_REQUEST',
  LIST_FAILURE: '@invoice/LIST_FAILURE',
  LIST_SUCCESS: '@invoice/LIST_SUCCESS',

  ALL_DATA_TO_INVOICE_PAGE_STEP1_REQUEST:
    '@invoice/ALL_DATA_TO_INVOICE_PAGE_STEP1_REQUEST',
  ALL_DATA_TO_INVOICE_PAGE_STEP1_FAILURE:
    '@invoice/ALL_DATA_TO_INVOICE_PAGE_STEP1_FAILURE',
  ALL_DATA_TO_INVOICE_PAGE_STEP1_SUCCESS:
    '@invoice/ALL_DATA_TO_INVOICE_PAGE_STEP1_SUCCESS',

  SHOW_REQUEST: '@invoice/SHOW_REQUEST',
  SHOW_FAILURE: '@invoice/SHOW_FAILURE',
  SHOW_SUCCESS: '@invoice/SHOW_SUCCESS',
  RESET_LOADING_SHOW: '@invoice/RESET_LOADING_SHOW',

  CREATE_REQUEST: '@invoice/CREATE_REQUEST',
  CREATE_FAILURE: '@invoice/CREATE_FAILURE',
  CREATE_SUCCESS: '@invoice/CREATE_SUCCESS',
  
  CLONE_REQUEST: '@invoice/CLONE_REQUEST',
  CLONE_FAILURE: '@invoice/CLONE_FAILURE',
  CLONE_SUCCESS: '@invoice/CLONE_SUCCESS',

  UPDATE_TOTAL_VALUE: '@invoice/UPDATE_TOTAL_VALUE',

  UPDATE_STEP1_REQUEST: '@invoice/UPDATE_STEP1_REQUEST',
  UPDATE_STEP1_FAILURE: '@invoice/UPDATE_STEP1_FAILURE',
  UPDATE_STEP1_SUCCESS: '@invoice/UPDATE_STEP1_SUCCESS',

  UPDATE_STEP2_REQUEST: '@invoice/UPDATE_STEP2_REQUEST',
  UPDATE_STEP2_FAILURE: '@invoice/UPDATE_STEP2_FAILURE',
  UPDATE_STEP2_SUCCESS: '@invoice/UPDATE_STEP2_SUCCESS',

  UPDATE_STEP3_REQUEST: '@invoice/UPDATE_STEP3_REQUEST',
  UPDATE_STEP3_FAILURE: '@invoice/UPDATE_STEP3_FAILURE',
  UPDATE_STEP3_SUCCESS: '@invoice/UPDATE_STEP3_SUCCESS',

  UPDATE_STEP4_REQUEST: '@invoice/UPDATE_STEP4_REQUEST',
  UPDATE_STEP4_FAILURE: '@invoice/UPDATE_STEP4_FAILURE',
  UPDATE_STEP4_SUCCESS: '@invoice/UPDATE_STEP4_SUCCESS',

  VOUCHER_REQUEST: '@invoice/VOUCHER_REQUEST',
  VOUCHER_FAILURE: '@invoice/VOUCHER_FAILURE',
  VOUCHER_SUCCESS: '@invoice/VOUCHER_SUCCESS',
  VOUCHER_DELETE_REQUEST: '@invoice/VOUCHER_DELETE_REQUEST',
  VOUCHER_DELETE_FAILURE: '@invoice/VOUCHER_DELETE_FAILURE',
  VOUCHER_DELETE_SUCCESS: '@invoice/VOUCHER_DELETE_SUCCESS',

  DELETE_REQUEST: '@invoice/DELETE_REQUEST',
  DELETE_FAILURE: '@invoice/DELETE_FAILURE',
  DELETE_SUCCESS: '@invoice/DELETE_SUCCESS',

  DOWNLOAD_REPORT_REQUEST: '@invoice/DOWNLOAD_REPORT_REQUEST',
  DOWNLOAD_REPORT_FAILURE: '@invoice/DOWNLOAD_REPORT_FAILURE',
  DOWNLOAD_REPORT_SUCCESS: '@invoice/DOWNLOAD_REPORT_SUCCESS',

  CREATE_PRODUCT_REQUEST: '@invoice/CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_FAILURE: '@invoice/CREATE_PRODUCT_FAILURE',
  CREATE_PRODUCT_SUCCESS: '@invoice/CREATE_PRODUCT_SUCCESS',

  UPDATE_PRODUCT_REQUEST: '@invoice/UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_FAILURE: '@invoice/UPDATE_PRODUCT_FAILURE',
  UPDATE_PRODUCT_SUCCESS: '@invoice/UPDATE_PRODUCT_SUCCESS',

  DELETE_PRODUCT_REQUEST: '@invoice/DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_FAILURE: '@invoice/DELETE_PRODUCT_FAILURE',
  DELETE_PRODUCT_SUCCESS: '@invoice/DELETE_PRODUCT_SUCCESS',

  CREATE_PRODUCT_UNIT_REQUEST: '@invoice/CREATE_PRODUCT_UNIT_REQUEST',
  CREATE_PRODUCT_UNIT_FAILURE: '@invoice/CREATE_PRODUCT_UNIT_FAILURE',
  CREATE_PRODUCT_UNIT_SUCCESS: '@invoice/CREATE_PRODUCT_UNIT_SUCCESS',
  LIST_PRODUCT_UNIT_SUCCESS: '@invoice/LIST_PRODUCT_UNIT_SUCCESS',
};

export default Types;
