import styled from 'styled-components';

import colors from './colors';
import { BoxShadow } from './variables';

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  width: ${({ width }) => width || '100%'};

  .label {
    color: var(--gray-4);
    font-size: 12px;
    margin-bottom: 2px;
    text-align: left;
  }
`;

export const InputMessageError = styled.div`
  color: var(--input-error);
  display: inherit;
  font-size: 12px;
  margin-top: 4px;
`;

export const Card = styled.div`
  background: ${colors.backgroundCard};
  border-radius: 3px;
  padding: 2.8rem;
  box-shadow: ${BoxShadow.card};

  .card__header {
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const FooterButtons = styled.footer`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-top: 2.4rem;
  }

  a + button {
    margin-left: 24px;
  }
`;
