import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  profile: null,
  loadingUpdate: false,
};

export default function profile(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGNIN_SUCCESS': {
        draft.profile = action.payload.user;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        break;
      }

      case types.UPDATE_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_SUCCESS: {
        draft.profile = action.payload.profile;
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.UPDATE_AVATAR_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_AVATAR_SUCCESS: {
        draft.profile = action.payload.profile;
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_AVATAR_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      default:
    }
  });
}
