import types from './types';

export const notificationsRequest = queryParams => ({
  type: types.LIST_REQUEST,
  payload: queryParams,
});
export const notificationsSuccess = notifications => ({
  type: types.LIST_SUCCESS,
  payload: notifications,
});
export const notificationsFailure = () => ({
  type: types.LIST_FAILURE,
});

export const markAsReadRequest = ({ id }) => ({
  type: types.MARK_AS_READ_REQUEST,
  payload: { id },
});
export const markAsReadSuccess = notifications => ({
  type: types.MARK_AS_READ_SUCCESS,
  payload: notifications,
});
export const markAsReadFailure = () => ({
  type: types.MARK_AS_READ_FAILURE,
});
