import React from 'react';
import { PropTypes } from 'prop-types';
import { FaCheck } from 'react-icons/fa';

import { formatDate } from '../../helpers/formatDate';
import getLocationNumber from '../../helpers/getLocationNumber';

import { StepperList, Step } from './styles';

export default function Stepper({ current, updatedDates }) {
  const {
    created_at,
    data_emissao_nota,
    data_empenho,
    data_pagamento,
  } = updatedDates;

  const list = [
    {
      label: 'Secretaria',
      visited: getLocationNumber(current) >= 1,
      visitedLine: getLocationNumber(current) > 1,
      updatedDate: formatDate(created_at),
    },
    {
      label: 'Controle Interno',
      visited: getLocationNumber(current) >= 2,
      visitedLine: getLocationNumber(current) > 2,
      updatedDate: formatDate(data_emissao_nota),
    },
    {
      label: 'Contabilidade',
      visited: getLocationNumber(current) >= 3,
      visitedLine: getLocationNumber(current) > 3,
      updatedDate: formatDate(data_empenho),
    },
    {
      label: 'Tesouraria',
      visited: getLocationNumber(current) >= 4,
      visitedLine: getLocationNumber(current) > 4,
      updatedDate: formatDate(data_pagamento),
    },
  ];

  return (
    <StepperList>
      {list.map(step => (
        <Step
          key={step.label}
          // active={step.value === getLocationNumber(current)}
          visited={step.visited}
          visitedLine={step.visitedLine}
        >
          <div className="step__wrapper">
            <div className="step__indicator">
              {step.visited ? <FaCheck size={12} /> : ''}
            </div>
          </div>
          <h3 className="step__label">{step.label}</h3>
          <h4 className="step__date">{step.updatedDate}</h4>
        </Step>
      ))}
    </StepperList>
  );
}

Stepper.propTypes = {
  current: PropTypes.string,
  updatedDates: PropTypes.shape({
    created_at: PropTypes.string,
    data_emissao_nota: PropTypes.string,
    data_empenho: PropTypes.string,
    data_pagamento: PropTypes.string,
  }).isRequired,
};

Stepper.defaultProps = {
  current: 'sd',
};
