const Types = {
  SIGNIN_REQUEST: '@auth/SIGNIN_REQUEST',
  SIGNIN_SUCCESS: '@auth/SIGNIN_SUCCESS',
  SIGNIN_FAILURE: '@auth/SIGNIN_FAILURE',

  SIGNUP_REQUEST: '@auth/SIGNUP_REQUEST',

  SIGNOUT_REQUEST: '@auth/SIGNOUT_REQUEST',
};

export default Types;
