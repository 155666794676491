import { combineReducers } from 'redux';
import auth from './auth/reducer';
import profile from './profile/reducer';
import users from './users/reducer';
import password from './password/reducer';
import provider from './provider/reducer';
import invoice from './invoice/reducer';
import fundo from './fundo/reducer';
import notification from './notification/reducer';
import common from './common/reducer';
import product from './product/reducer';

export default combineReducers({
  auth,
  profile,
  users,
  password,
  provider,
  invoice,
  fundo,
  notification,
  common,
  product
});
