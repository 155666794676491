import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-grid-system';
import { IoMdWarning } from 'react-icons/io';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import CardInfo, {
  CardInfoIcon,
  CardInfoContent,
  CardInfoTitle,
  CardInfoDescription,
} from '../../../components/CardInfo';

export default function ModalDeleteConfirm({
  data,
  isOpen,
  onClose,
  onSubmit,
  loading,
}) {
  const email = data ? data.email : '';
  return (
    <Modal
      title="Excluir usuário"
      open={isOpen}
      width={{ width: '400px' }}
      closeAction={onClose}
    >
      <CardInfo className="mb-24">
        <CardInfoIcon>
          <IoMdWarning size={18} />
        </CardInfoIcon>
        <CardInfoContent>
          <CardInfoTitle className="mb-6">Aviso!</CardInfoTitle>
          <CardInfoDescription>
            Esta ação não pode ser revertida, deseja realmente excluir o usuário{' '}
            <br />
            <strong>{email}?</strong>
          </CardInfoDescription>
        </CardInfoContent>
      </CardInfo>
      <Row justify="end">
        <Button
          label="Cancelar"
          variant="outlined"
          color="black"
          height="34px"
          onClick={onClose}
          width="100px"
          disabled={loading}
        />
        <Button
          className="ml-12 font-medium"
          label="Sim"
          color="error"
          type="submit"
          height="34px"
          width="100px"
          loading={loading}
          onClick={() => onSubmit(data)}
        />
      </Row>
    </Modal>
  );
}

ModalDeleteConfirm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ModalDeleteConfirm.defaultProps = {
  loading: false,
};
