const Types = {
  LIST_REQUEST: '@notification/LIST_REQUEST',
  LIST_FAILURE: '@notification/LIST_FAILURE',
  LIST_SUCCESS: '@notification/LIST_SUCCESS',

  MARK_AS_READ_REQUEST: '@notification/MARK_AS_READ_REQUEST',
  MARK_AS_READ_FAILURE: '@notification/MARK_AS_READ_FAILURE',
  MARK_AS_READ_SUCCESS: '@notification/MARK_AS_READ_SUCCESS',
};

export default Types;
