import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MdHelpOutline } from 'react-icons/md';

import { Link } from 'react-router-dom';
import InvoiceNotifications from '../InvoiceNotifications';
import ChatNotifications from '../ChatNotifications';

import Profile from './Profile';

import { Container, Content, MenuBurguer, Nav, Aside } from './styles';

export default function Header({ onToggleSideNav, buttonRef }) {
  const { profile: user } = useSelector(({ profile }) => profile);

  const showInvoiceNotifications = useMemo(() => {
    if (!user) return false;

    const usersToShowInvoiceNotifications = [
      'controle_interno',
      'contabilidade',
      'tesoureiro',
    ];

    return usersToShowInvoiceNotifications.includes(user.type);
  }, [user]);

  return (
    <Container>
      <Content>
        <Nav>
          <MenuBurguer onClick={onToggleSideNav} type="button" ref={buttonRef}>
            <div />
            <div />
            <div />
          </MenuBurguer>

          <Link to="/invoices">
            <h3 className="logo">Logenpenho</h3>
          </Link>
        </Nav>
        <Aside>
          <ChatNotifications />
          {showInvoiceNotifications && <InvoiceNotifications />}

          <a
            href={process.env.REACT_APP_WHATSAPP_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="aside__help-link"
            title="Contatar Suporte"
          >
            <MdHelpOutline size={20} />
          </a>

          <Profile />
        </Aside>
      </Content>
    </Container>
  );
}

Header.propTypes = {
  onToggleSideNav: PropTypes.func,
  buttonRef: PropTypes.object.isRequired,
};

Header.defaultProps = {
  onToggleSideNav: () => {},
};
