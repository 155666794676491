import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';

import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Select from '../../../components/Select';

const invoicePriorityOptions = [
  {
    value: 'rotina',
    label: 'Rotina',
  },
  {
    value: 'urgencia',
    label: 'Urgência',
  },
];

const invoiceTypeOptions = [
  {
    value: 'aluguel',
    label: 'Aluguel',
  },
  {
    value: 'assessor',
    label: 'Assessor',
  },
  {
    value: 'compra_direta',
    label: 'Compra direta',
  },
  {
    value: 'diaria',
    label: 'Diaria',
  },
  {
    value: 'dispensa',
    label: 'Dispensa',
  },
  {
    value: 'por_licitacao',
    label: 'Por Licitação',
  },
  {
    value: 'recibo',
    label: 'Recibo',
  },
  {
    value: 'credenciamento',
    label: 'Credenciamento',
  },
];

export default function FormRequesterAndProvider({
  data,
  handleSubmitInvoice,
  fundos,
  providers,
}) {
  const [initialData, setInitialData] = useState({
    requester: '',
    destiny: '',
    fundo_id: '',
    fonte_recurso: '',
    justification: '',
    provider_id: '',
    priority: 'rotina',
    type: '',
  });

  useEffect(() => {
    if (data) {
      const {
        requester,
        fundo_id,
        destiny,
        fonte_recurso,
        justification,
        provider_id,
        priority,
        type,
      } = data;
      setInitialData({
        requester,
        destiny,
        fundo_id,
        fonte_recurso,
        justification,
        provider_id,
        priority: priority || '',
        type,
      });
    }
    return () => {
      setInitialData({});
    };
  }, [data]);

  const onSubmitInvoice = useCallback(
    values => {
      if (handleSubmitInvoice) handleSubmitInvoice(values);
    },
    [handleSubmitInvoice]
  );

  const fundoOptions = useMemo(() => {
     return fundos.map(fundo => ({
      value: fundo.id,
      label: fundo.name,
    }));
  }, [fundos])

  const providerOptions = useMemo(() => {
     return providers.map(fundo => ({
      value: fundo.id,
      label: fundo.name,
    }));
  }, [providers])

  return (
    <Formik
      initialValues={initialData}
      validationSchema={Yup.object().shape({
        requester: Yup.string().required('Informe o solicitante'),
        destiny: Yup.string().required('Informe o destino'),
        fundo_id: Yup.string().required('Informe o fundo'),
        fonte_recurso: Yup.string(),
        justification: Yup.string().required('Informe a justificativa'),
        provider_id: Yup.string().required('Informe o fornecedor'),
        priority: Yup.string().required('Informe a prioridade'),
        type: Yup.string(),
      })}
      onSubmit={values => {
        onSubmitInvoice(values);
      }}
      enableReinitialize
    >
      {({ handleSubmit, values, errors, setFieldValue, touched }) => (
        <form onSubmit={handleSubmit} id="form-sd">
          <Row>
            <Col>
              <Input
                label="Nome do solicitante"
                name="requester"
                value={values.requester}
                error={errors.requester && touched.requester}
                errorMessage={errors.requester}
                onChange={({ target }) =>
                  setFieldValue('requester', target.value)
                }
              />
            </Col>
            <Col>
              <Input
                label="Destino"
                name="destiny"
                value={values.destiny}
                error={errors.destiny && touched.destiny}
                errorMessage={errors.destiny}
                onChange={({ target }) =>
                  setFieldValue('destiny', target.value)
                }
              />
            </Col>
          </Row>

          <Field
            name="provider_id"
            component={Select}
            options={providerOptions}
            label="Fornecedor"
          />

          <Row>
            <Col>
              <Field
                name="priority"
                component={Select}
                options={invoicePriorityOptions}
                label="Prioridade"
              />
            </Col>
            <Col>
              <Field
                name="type"
                component={Select}
                options={invoiceTypeOptions}
                placeholder="Selecione"
                label="Tipo"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Field
                name="fundo_id"
                component={Select}
                options={fundoOptions}
                label="Fundo"
              />
            </Col>
            <Col md={6}>
              <Input
                label="Fonte do recurso"
                name="fonte_recurso"
                value={values.fonte_recurso || ''}
                error={errors.fonte_recurso && touched.fonte_recurso}
                errorMessage={errors.fonte_recurso}
                onChange={({ target }) =>
                  setFieldValue('fonte_recurso', target.value)
                }
              />
            </Col>
          </Row>
          <TextArea
            label="Justificativa"
            name="justification"
            value={values.justification}
            error={errors.justification && touched.justification}
            errorMessage={errors.justification}
            onChange={({ target }) =>
              setFieldValue('justification', target.value)
            }
            height="90px"
          />

          <button
            label="Salvar"
            id="button-submit-requester-form"
            type="submit"
            hidden
          />
        </form>
      )}
    </Formik>
  );
}

FormRequesterAndProvider.propTypes = {
  data: PropTypes.object,
  handleSubmitInvoice: PropTypes.func,
  fundos: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
};

FormRequesterAndProvider.defaultProps = {
  data: {},
  handleSubmitInvoice: () => {},
};
