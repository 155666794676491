import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  notifications: [],
  loadingList: true,
  loadingUpdate: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.LIST_REQUEST: {
        draft.loadingList = true;
        break;
      }
      case types.LIST_SUCCESS: {
        draft.notifications = action.payload;
        draft.loadingList = false;
        break;
      }
      case types.LIST_FAILURE: {
        draft.loadingList = false;
        break;
      }

      case types.MARK_AS_READ_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.MARK_AS_READ_SUCCESS: {
        draft.notifications = action.payload;
        draft.loadingUpdate = false;
        break;
      }
      case types.MARK_AS_READ_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      default:
    }
  });
}
