import React, { useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { InputLabel, InputMessageError } from '../../styles/containers';

import { Container } from './styles';

const CustomSelectComponent = ({
  field,
  form: { touched, errors, setFieldValue },
  maxHeightMenuList,
  isCreatable = false,
  createMessage = 'Criar',
  onCreateOption,
  ...props
}) => {
  const { options } = props;

  const [inputValue, setInputValue] = useState('');

  const handleChange = option => {
    if (option) {
      setFieldValue(field.name, option.value);
    } else {
      setFieldValue(field.name, '');
    }
  };

  const handleInputChange = _value => {
    setInputValue(_value);
  };

  const handleCreateOption = _value => {
    if (onCreateOption && _value) {
      onCreateOption(_value);
      setFieldValue(field.name, _value);
    }
  };

  return (
    <InputLabel>
      {props.label && <span className="label">{props.label}</span>}
      <Container
        maxHeightMenuList={maxHeightMenuList}
        error={touched[field.name] && errors[field.name] ? 'true' : ''}
      >
        {isCreatable ? (
          <CreatableSelect
            {...field}
            options={options}
            value={
              options
                ? options.find(option => option.value === field.value)
                : ''
            }
            onChange={handleChange}
            onCreateOption={handleCreateOption}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            classNamePrefix="react-select"
            error={touched[field.name] && errors[field.name] ? 'true' : ''}
            formatCreateLabel={inputValueOnCreate =>
              `${createMessage} "${inputValueOnCreate}"`
            }
            noOptionsMessage={() => 'Sem opção'}
            // menuIsOpen
            isClearable
            {...props}
          />
        ) : (
          <Select
            {...field}
            options={options}
            value={
              options
                ? options.find(option => option.value === field.value)
                : ''
            }
            onChange={handleChange}
            classNamePrefix="react-select"
            maxHeightMenuList={maxHeightMenuList}
            noOptionsMessage={() => 'Sem opção'}
            // menuIsOpen
            isClearable
            {...props}
          />
        )}
      </Container>
      {touched[field.name] && errors[field.name] && (
        <InputMessageError>{errors[field.name]}</InputMessageError>
      )}
    </InputLabel>
  );
};

export default CustomSelectComponent;
