import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const Info = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: var(--secondary-text);

  strong {
    &:first-child {
      margin-left: 4px;
    }
    & + strong {
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
`;

export const Button = styled.button`
  border: none;
  background: none;
  padding: 2px 4px;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 14px;
  color: var(--secondary-text);
  cursor: pointer;
  margin: 0 6px;

  display: flex;
  align-items: center;
  position: relative;
  transition: none;

  ${props =>
    props.active &&
    css`
      color: var(--primary-text);
      font-weight: bold;

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--primary-text);
        bottom: 0px;
        left: 0;
      }
    `}
`;

export const ArrowButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin: 0 3px;

  display: flex;
  align-items: center;

  &:disabled {
    cursor: initial;
    opacity: 0.7;
  }
`;
