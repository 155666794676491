import styled from 'styled-components';

export const ContainerDetails = styled.div`
  .title {
    margin-bottom: 6px;
    background: #dcdcdc42;
    font-weight: 500;
    padding: 5px 10px;
    border: none;
  }

  h4 {
    font-size: 1.6rem;
  }

  table {
    margin-bottom: 24px !important;

    th {
      font-size: 12px !important;
      padding-bottom: 0 !important;
      text-transform: capitalize !important;
    }
  }
`;

export const ContainerCancelledInvoice = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 6;

  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  span {
    background: #de5b52;
    box-shadow: 2px 2px 5px #1d1a1a3d;
    height: 25px;
    padding: 0 12px;
    border-radius: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    margin-top: -22px;
  }
`;
