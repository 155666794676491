import React, { useCallback } from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import DetailsInfo from '../../../components/DetailsInfo';

import { formatDate } from '../../../helpers/formatDate';

function ControleInterno({ invoice }) {
  const getInfo = useCallback(
    info => {
      const { numero_nota, competencia, data_emissao_nota } = invoice;

      const invoiceData = {
        numero_nota,
        competencia: formatDate(competencia, 'MM/yyyy'),
        data_emissao_nota: formatDate(data_emissao_nota),
        default: '---',
      };

      return invoiceData[info] || invoiceData.default;
    },
    [invoice]
  );

  return (
    <div>
      <h4 className="title">Controle Interno</h4>
      <Row>
        <Col md={4}>
          <DetailsInfo>
            <span>Número da nota</span>
            <p>{getInfo('numero_nota')}</p>
          </DetailsInfo>
        </Col>
        <Col md={4}>
          <DetailsInfo>
            <span>Competência</span>
            <p>{getInfo('competencia')}</p>
          </DetailsInfo>
        </Col>
        <Col md={4}>
          <DetailsInfo>
            <span>Data de emissão</span>
            <p>{getInfo('data_emissao_nota')}</p>
          </DetailsInfo>
        </Col>
      </Row>
    </div>
  );
}

ControleInterno.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default ControleInterno;
