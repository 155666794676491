import styled from 'styled-components';

export const WrapperBackground = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  background: #f0f0f0;
  padding: 0 10px 10px;
`;

export const WrapperContent = styled.div`
  min-height: 97vh;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 1340px;
  margin: 0 auto;
`;

export const Children = styled.div`
  margin-top: 76px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  @media screen and (min-width: 880px) {
    margin-left: 174px;
  }
`;
