import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../components/Button';
import FormProduct from '../../../components/FormProduct';
import ProductList from '../../../components/ProductList';
import ActivityIndicator from '../../../components/ActivityIndicator';

import FormRequesterAndProvider from './FormRequesterAndProvider';
import { CustomCard } from './commonStyles';

import {
  createInvoiceRequest,
  allDataToInvoicePageStep1Request,
} from '../../../store/modules/invoice/actions';

import { FooterButtons } from '../../../styles/containers';

export default function SDStore() {
  const [arrayProducts, setArrayProducts] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [successUpdateProduct, setSuccessUpdateProduct] = useState(false);

  const { loadingCreate, loadingShow } = useSelector(state => state.invoice);
  const fundos = useSelector(state => state.fundo.allFundos);
  const providers = useSelector(state => state.provider.allProviders);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(allDataToInvoicePageStep1Request({ invoiceId: '' }));
  }, [dispatch]);

  useEffect(() => {
    if (!loadingShow && providers && !providers.length) {
      toast.warn('Cadastre o fornecedor antes de adicionar a SD.');
      history.push('/providers/new');
    }
  }, [providers, loadingShow, history]);

  useEffect(() => {
    if (!loadingShow && fundos && !fundos.length) {
      toast.warn('Cadastre o fundo antes de adicionar a SD.');
      history.push('/fundos/new');
    }
  }, [fundos, loadingShow, history]);

  useEffect(() => {
    const totalValueProducts = arrayProducts.reduce((subTotal, item) => {
      return subTotal + Number(item.amount);
    }, 0);

    setTotalValue(totalValueProducts);
  }, [arrayProducts]);

  function handleAddProduct(values) {
    const productWithTempId = { ...values, id: new Date().getTime() };

    if (arrayProducts) {
      setArrayProducts([...arrayProducts, productWithTempId]);
    } else {
      setArrayProducts(productWithTempId);
    }
    /**
     * Reseto o formulário manualmente pois não tava conseguindo
     * resetar chamando o método 'resetForm()' do formik
     */
    document.querySelector('#button-reset-form-new-product').click();
  }

  function handleRemoveProduct(id) {
    const arrayProductRemoved = arrayProducts.filter(
      product => product.id !== id
    );
    setArrayProducts(arrayProductRemoved);
  }

  function handleUpdateProduct(productToUpdate) {
    const arrayProductUpdated = arrayProducts.map(product =>
      product.id === productToUpdate.id ? productToUpdate : product
    );
    setArrayProducts(arrayProductUpdated);
    setSuccessUpdateProduct(true);
    setSuccessUpdateProduct(false); // Reseto o estado para ser usado novamente
  }

  function handleClickButtonSubmit() {
    document.querySelector('#button-submit-requester-form').click();
  }

  function handleUpdateTotalValue(value) {
    setTotalValue(value);
  }

  function handleSubmitSD(values) {
    if (arrayProducts.length === 0) {
      toast.error('Insira os produtos para adicionar a SD');
      return;
    }

    const arrayProductsWithoutId = arrayProducts.map(product => {
      const { id, ...restProductData } = product;
      return restProductData;
    });

    const dataSDToSubmit = {
      products: arrayProductsWithoutId,
      total_value: totalValue,
      ...values,
    };

    dispatch(createInvoiceRequest(dataSDToSubmit));
  }

  return (
    <CustomCard>
      <div className="card__header mb-24">
        <h3>Emitir SD</h3>
      </div>

      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <>
          <FormRequesterAndProvider
            handleSubmitInvoice={values => handleSubmitSD(values)}
            fundos={fundos || []}
            providers={providers || []}
          />

          <FormProduct onSubmit={handleAddProduct} />
          <ProductList
            products={arrayProducts}
            totalValue={totalValue}
            handleRemoveProduct={handleRemoveProduct}
            handleUpdateProduct={handleUpdateProduct}
            successUpdate={successUpdateProduct}
            handleUpdateTotalValue={handleUpdateTotalValue}
            isEditable
          />

          <FooterButtons>
            <Link to="/invoices">
              <Button variant="clean" color="black" label="Cancelar" />
            </Link>
            <Button
              color="success"
              label="Adicionar"
              onClick={handleClickButtonSubmit}
              loading={loadingCreate}
              width="110px"
              className="font-medium"
            />
          </FooterButtons>
        </>
      )}
    </CustomCard>
  );
}
