import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { Spinner } from 'react-activity';

import { Body, Loading, TableEmpty } from './styles';

const defaultPropGetter = () => ({});

export default function Table({
  columns,
  data,
  getRowProps = defaultPropGetter,
  loading,
  hiddenColumns,
  className,
  style,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns,
    },
  });

  return (
    <>
      <Body>
        {loading && (
          <Loading>
            <Spinner size={15} color="#4b81d4" />
          </Loading>
        )}
        <table
          {...getTableProps()}
          className={`table ${className}`}
          style={style}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps({
                      className: column.className,
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {!loading && data.length === 0 && (
          <TableEmpty>Nenhum registro encontrado</TableEmpty>
        )}
      </Body>
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getRowProps: PropTypes.func,
  loading: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
};

Table.defaultProps = {
  loading: false,
  hiddenColumns: [],
  getRowProps: () => {},
  className: '',
  style: {},
};
