import types from './types';

export const updateProfileRequest = ({ profile, resetForm }) => ({
  type: types.UPDATE_REQUEST,
  payload: { profile, resetForm },
});
export const updateProfileSuccess = profile => ({
  type: types.UPDATE_SUCCESS,
  payload: { profile },
});
export const updateProfileFailure = () => ({
  type: types.UPDATE_FAILURE,
});

export const updateAvatarRequest = file => ({
  type: types.UPDATE_AVATAR_REQUEST,
  payload: { file },
});
export const updateAvatarSuccess = profile => ({
  type: types.UPDATE_AVATAR_SUCCESS,
  payload: { profile },
});
export const updateAvatarFailure = () => ({
  type: types.UPDATE_AVATAR_FAILURE,
});
