import { verificaCpfCnpj, validaCpf, validaCnpj } from './validateCpfCnpj';

/*
Formata um CPF ou CNPJ

@access public
@return string CPF ou CNPJ formatado
*/
export default function formatCpfCnpj(valor) {
  if (!valor) return '---';

  // O valor formatado
  let formatado = false;

  // Verifica se é CPF ou CNPJ
  const valida = verificaCpfCnpj(valor);

  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, '');

  // Valida CPF
  if (valida === 'CPF') {
    // Verifica se o CPF é válido
    if (validaCpf(valor)) {
      // Formata o CPF ###.###.###-##
      formatado = `${valor.substr(0, 3)}.`;
      formatado += `${valor.substr(3, 3)}.`;
      formatado += `${valor.substr(6, 3)}-`;
      formatado += `${valor.substr(9, 2)}`;
    }
  }

  // Valida CNPJ
  else if (valida === 'CNPJ') {
    // Verifica se o CNPJ é válido
    if (validaCnpj(valor)) {
      // Formata o CNPJ ##.###.###/####-##
      formatado = `${valor.substr(0, 2)}.`;
      formatado += `${valor.substr(2, 3)}.`;
      formatado += `${valor.substr(5, 3)}/`;
      formatado += `${valor.substr(8, 4)}-`;
      formatado += `${valor.substr(12, 14)}`;
    }
  }

  // Retorna o valor
  return formatado;
}
