import * as Yup from 'yup';
import axios from 'axios';

import extractNumbers from '../../../helpers/extractNumbers';
import validateCpfCnpj from '../../../helpers/validateCpfCnpj';

export const validationSchemaCreateProvider = Yup.object().shape({
  name: Yup.string().required('Insira o nome do fornecedor'),
  payment_account: Yup.string().required('Insira a conta para pagamento'),
  document_type: Yup.string().required(
    'Insira o tipo de documento do fornecedor'
  ),
  document_number: Yup.string()
    .required('O documento é obrigatorio')
    .test('is-cpf', 'Documento inválido', value => {
      if (value) {
        return validateCpfCnpj(value);
      }
    }),
  phone: Yup.string().test('size-phone', 'Telefone incompleto', value => {
    if (value) {
      const valueFomatted = extractNumbers(value);
      if (valueFomatted.length === 0 || valueFomatted.length === 11) {
        return true;
      }
      return false;
    }

    return true;
  }),
  email: Yup.string().email('Informe um email válido'),
  zipcode: Yup.string()
    .required('CEP é obrigatorio')
    .test('size-cep', 'CEP Incompleto', value => {
      if (value) {
        const valueFomatted = extractNumbers(value);
        if (valueFomatted.length === 8) {
          return true;
        }
        return false;
      }
    })
    .test('is-cep', 'CEP Inválido', async value => {
      if (value) {
        const valueFomatted = extractNumbers(value);
        if (valueFomatted.length === 8) {
          const response = await axios.get(
            `https://viacep.com.br/ws/${value}/json`
          );

          if (response.data.erro) {
            return false;
          }
          return true;
        }

        return false;
      }
    }),
  city: Yup.string().required('Informe a Cidade'),
  state: Yup.string().required('Informe o Estado'),
  country: Yup.string().required('Informe o país'),
  neighborhood: Yup.string().required('Informe o bairro'),
  address: Yup.string().required('Informe o logradouro'),
  street_number: Yup.string().required('Informe o número da casa/comércio'),
});
