import api from '../../api';

export default {
  get: async () => {
    const res = await api.get(`/users`);
    return res.data;
  },

  search: async filters => {
    const res = await api.get('/users/search', { params: { ...filters } });
    return res;
  },

  store: async user => {
    const res = await api.post(`/users`, user);
    return res.data;
  },

  show: async payload => {
    const { id } = payload;
    const res = await api.get(`/users/${id}`);
    return res.data;
  },

  update: async user => {
    const res = await api.put(`/users`, user);
    return res.data;
  },

  delete: async id => {
    const res = await api.delete(`/users/${id}`);
    return res.data;
  },
};
