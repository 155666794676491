export default function getLocationNumber(location) {
  const locations = {
    secretaria: 1,
    controle_interno: 2,
    contabilidade: 3,
    tesouraria: 4,
  };

  return locations[location] || locations.default;
}
