const Types = {
  LIST_REQUEST: '@users/LIST_REQUEST',
  LIST_FAILURE: '@users/LIST_FAILURE',
  LIST_SUCCESS: '@users/LIST_SUCCESS',

  SEARCH_REQUEST: '@users/SEARCH_REQUEST',
  SEARCH_FAILURE: '@users/SEARCH_FAILURE',
  SEARCH_SUCCESS: '@users/SEARCH_SUCCESS',

  SHOW_REQUEST: '@users/SHOW_REQUEST',
  SHOW_FAILURE: '@users/SHOW_FAILURE',
  SHOW_SUCCESS: '@users/SHOW_SUCCESS',
  RESET_LOADING_SHOW: '@users/RESET_LOADING_SHOW',

  CREATE_REQUEST: '@users/CREATE_REQUEST',
  CREATE_FAILURE: '@users/CREATE_FAILURE',
  CREATE_SUCCESS: '@users/CREATE_SUCCESS',

  UPDATE_REQUEST: '@users/UPDATE_REQUEST',
  UPDATE_FAILURE: '@users/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@users/UPDATE_SUCCESS',

  DELETE_REQUEST: '@users/DELETE_REQUEST',
  DELETE_FAILURE: '@users/DELETE_FAILURE',
  DELETE_SUCCESS: '@users/DELETE_SUCCESS',

  SET_IS_OPENED_MODAL: '@users/SET_IS_OPENED_MODAL',
};

export default Types;
