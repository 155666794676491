import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { debounce } from 'lodash';
import { MdPrint, MdEdit, MdRemoveRedEye } from 'react-icons/md';
import { TiDocumentText } from 'react-icons/ti';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { IoIosCopy } from 'react-icons/io';

import { parseISO, format } from 'date-fns';

import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Paginate from '../../../components/Paginate';

import Filters from './Filters';

import { Actions, CustomTable } from './styles';

import getLocationNumber from '../../../helpers/getLocationNumber';
import { formatNumberBR } from '../../../helpers/formatNumber';
import {
  getLocation,
  getStatus,
  getInvoiceType,
  getInvoicePriority,
} from '../../../helpers/translate';

import {
  downloadInvoiceReport,
  listInvoicesRequest,
  cloneInvoiceRequest,
} from '../../../store/modules/invoice/actions';
import { fundosRequest } from '../../../store/modules/fundo/actions';

export default function InvoiceList() {
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 8,
  });

  const [filters, setFilters] = useState({
    type: '',
    priority: '',
    location: '',
    status: '',
    user_id: '',
    fundo_id: '',
    search: '',
    start_date: '',
    end_date: '',
  });

  const { profile: user } = useSelector(({ profile }) => profile);
  const { invoices, loadingList } = useSelector(({ invoice }) => invoice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fundosRequest());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.type === 'secretario') {
      dispatch(
        listInvoicesRequest({ ...pagination, ...filters, user_id: user.id })
      );
    } else {
      dispatch(listInvoicesRequest({ ...pagination, ...filters }));
    }
  }, [dispatch, pagination, filters, user]);

  const checkIfUserIsAllowedToUpdateInvoice = useCallback(
    (invoice, loggedUser) => {
      const { location, user_id: invoiceOwnerId } = invoice;
      const { type: userType, id: userId } = loggedUser;

      const locationNumbers = getLocationNumber(location);

      const isAllowedSecretario =
        userType === 'secretario' &&
        userId === invoiceOwnerId &&
        locationNumbers >= 1;

      const isAllowedControleInterno =
        userType === 'controle_interno' && locationNumbers >= 1;

      const isAllowedContabilidade =
        userType === 'contabilidade' && locationNumbers >= 2;

      const isAllowedTesoureiro =
        userType === 'tesoureiro' && locationNumbers >= 3;

      if (isAllowedSecretario) return true;
      if (isAllowedControleInterno) return true;
      if (isAllowedContabilidade) return true;
      if (isAllowedTesoureiro) return true;

      return false;
    },
    []
  );

  const handleCloneInvoice = useCallback(
    id => {
      dispatch(cloneInvoiceRequest(id));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Nº Remessa',
        accessor: 'counter',
        className: 'txt-center',
      },
      {
        Header: 'Solicitante',
        accessor: 'requester',
      },
      {
        Header: 'Fundo',
        accessor: 'fundo.name',
      },
      {
        Header: 'Destino',
        accessor: 'destiny',
      },
      {
        Header: 'Fornecedor',
        accessor: 'provider.name',
      },
      {
        Header: 'Data/hora criação',
        accessor: 'created_at',
        className: 'txt-center',
        Cell: ({ cell }) => {
          const formattedDate = format(parseISO(cell.value), 'dd/MM/yyyy');
          const formattedHour = format(parseISO(cell.value), 'HH:mm:ss');
          return (
            <div>
              {formattedDate} <br /> {formattedHour}
            </div>
          );
        },
      },
      {
        Header: 'Tipo',
        accessor: 'type',
        className: 'txt-center',
        Cell: ({ cell }) => getInvoiceType(cell.value),
      },
      {
        Header: 'Prioridade',
        accessor: 'priority',
        className: 'txt-center',
        Cell: ({ cell }) => getInvoicePriority(cell.value),
      },
      {
        Header: 'Localização',
        accessor: 'location',
        className: 'txt-center',
        Cell: ({ cell }) => getLocation(cell.value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'txt-center',
        Cell: ({ cell }) => getStatus(cell.value),
      },
      {
        Header: 'Valor',
        id: 'total',
        className: 'txt-center',
        Cell: ({ row }) => {
          const { products, total_value } = row.original;

          let formattedValue = '';

          if (total_value) {
            formattedValue = formatNumberBR(total_value, 2);
          } else {
            const totalAmount = products.reduce(
              (amount, product) => Number(amount) + Number(product.amount),
              0
            );

            formattedValue = formatNumberBR(totalAmount, 2);
          }

          return `R$${formattedValue}`;
        },
      },
      {
        id: 'actions',
        className: 'txt-center',
        Cell: ({ row }) => {
          const invoice = row.original;
          const { id, pdf_url, voucher_url } = invoice;

          const userType = user.type;
          const updateUrl = `/invoice/${id}/step/${userType}`;
          const detailsUrl = `/invoice/${id}/details`;

          return (
            <Actions>
              {userType === 'secretario' && (
                <IoIosCopy
                  color="#424242"
                  size={15}
                  title="Duplicar"
                  className="action"
                  onClick={() => handleCloneInvoice(id)}
                />
              )}
              {checkIfUserIsAllowedToUpdateInvoice(invoice, user) && (
                <a
                  href={updateUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="action"
                >
                  <MdEdit color="#424242" size={17} title="Editar" />
                </a>
              )}

              {voucher_url && (
                <a
                  href={voucher_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="action"
                >
                  <FaFileInvoiceDollar
                    color="#424242"
                    size={16}
                    title="Comprovante de pagamento"
                  />
                </a>
              )}

              {userType === 'controle_interno' && (
                <a
                  href={`/invoice/${id}/pdf/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="action"
                >
                  <TiDocumentText
                    color="#424242"
                    size={17}
                    title="Editar PDF"
                  />
                </a>
              )}

              <a
                href={detailsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="action"
              >
                <MdRemoveRedEye
                  color="#424242"
                  size={17}
                  title="Ver detalhes"
                />
              </a>

              {pdf_url && (
                <a
                  href={pdf_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="action"
                >
                  <MdPrint color="#424242" size={17} title="Imprimir" />
                </a>
              )}
            </Actions>
          );
        },
      },
    ],
    [user, checkIfUserIsAllowedToUpdateInvoice, handleCloneInvoice]
  );

  const handleChangeFilter = useCallback(filter => {
    setFilters(stateFilters => ({ ...stateFilters, ...filter }));
    setPagination(statePagination => ({ ...statePagination, page: 1 }));
  }, []);

  const handleSearchWithDebounce = useRef(
    debounce(filter => {
      handleChangeFilter(filter);
    }, 500)
  ).current;

  const handleDownloadReport = () => {
    dispatch(downloadInvoiceReport(filters));
  };

  const getTableRowStyle = rowInfo => {
    const {
      original: { status },
    } = rowInfo;

    if (status === 'autorizada') {
      return { style: { background: '#f0f0f0' } };
    }
  };

  return (
    <Card>
      <div className="card__header">
        <h3>Notas</h3>

        <div className="d-flex">
          {user && user.type === 'secretario' && (
            <Link to="/invoice/new">
              <Button
                variant="outlined"
                label="Adicionar"
                height="3.4rem"
                className="font-medium"
              />
            </Link>
          )}

          <Button
            className="ml-12"
            variant="outlined"
            color="black"
            label="Baixar"
            iconSize="16"
            height="3.4rem"
            width="100px"
            onClick={handleDownloadReport}
            icon="FaDownload"
            disabled={
              invoices && invoices.invoices && !invoices.invoices.length
            }
          />
        </div>
      </div>

      <Filters
        onChange={handleChangeFilter}
        onChangeWithDebounce={handleSearchWithDebounce}
        filters={filters}
      />

      <Row>
        <Col>
          <CustomTable
            columns={columns}
            data={(invoices && invoices.invoices) || []}
            loading={loadingList}
            getRowProps={getTableRowStyle}
          />

          <Paginate
            records={(invoices && invoices.totalCount) || 0}
            current={pagination.page}
            limit={pagination.per_page}
            onChange={page =>
              setPagination(oldPagination => ({ ...oldPagination, page }))
            }
            delta={1}
            fixed={1}
          />
        </Col>
      </Row>
    </Card>
  );
}

InvoiceList.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
};

InvoiceList.defaultProps = {
  cell: {},
};
