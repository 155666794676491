import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import ActivityIndicator from '../../../components/ActivityIndicator';
import Card from '../../../components/Card';

import { FooterButtons } from '../../../styles/containers';
import ModalDeleteConfirm from './ModalDeleteConfirm';

import {
  showUserRequest,
  updateUserRequest,
  resetLoadingShow,
  deleteUserRequest,
  setIsOpenedModal,
} from '../../../store/modules/users/actions';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Insira o nome'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('Informe o email'),
  type: Yup.string().required('Informe o tipo do usuário'),
  password: Yup.string().min(6, 'A senha deve ter no mínimo 6 digitos'),
  password_confirmation: Yup.string().when('password', {
    is: val => val && val.length > 0,
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'As senhas não combinam')
      .required('Confirme a senha'),
  }),
});

const userTypeOptions = [
  {
    value: 'tesoureiro',
    label: 'Tesoureiro',
  },
  {
    value: 'contabilidade',
    label: 'Contabilidade',
  },
  {
    value: 'controle_interno',
    label: 'Controle Interno',
  },
  {
    value: 'secretario',
    label: 'Secretario',
  },
];

export default function UserUpdate({ match }) {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    type: '',
    password: '',
    password_confirmation: '',
  });

  const {
    loadingShow,
    loadingUpdate,
    user,
    loadingDelete,
    isOpenedModal,
  } = useSelector(({ users }) => users);

  const { id } = match.params;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showUserRequest(id));
    return () => dispatch(resetLoadingShow());
  }, [dispatch, id]);

  useEffect(() => {
    if (user) {
      const { name, email, type } = user;

      setUserData({
        name,
        email,
        type,
        password: '',
        password_confirmation: '',
      });
    }
  }, [user]);

  const handleUpdateUser = useCallback(
    values => {
      const { password, password_confirmation, ...restUserData } = values;

      const payload =
        password && password_confirmation
          ? { user_id: id, password, password_confirmation, ...restUserData }
          : { user_id: id, ...restUserData };

      dispatch(updateUserRequest(payload));
    },
    [id, dispatch]
  );

  const handleOpenModal = () => {
    dispatch(setIsOpenedModal('delete'));
  };

  const handleCloseModal = () => {
    if (loadingDelete) return;
    dispatch(setIsOpenedModal(''));
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserRequest(user.id));
  };

  return (
    <Card>
      <div className="d-flex justify-space-between align-center card__header">
        <h3>Editar Usuário</h3>
        {!loadingShow && (
          <Button
            color="error"
            label="Excluir usuário"
            width="100px"
            height="34px"
            variant="outlined"
            onClick={handleOpenModal}
            className="font-medium"
          />
        )}
        {isOpenedModal === 'delete' && (
          <ModalDeleteConfirm
            data={userData}
            isOpen={isOpenedModal === 'delete'}
            onClose={handleCloseModal}
            onSubmit={handleDeleteUser}
            loading={loadingDelete}
          />
        )}
      </div>

      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <Formik
          initialValues={userData || {}}
          onSubmit={values => {
            handleUpdateUser(values);
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Input
                    label="Nome"
                    name="name"
                    value={values.name}
                    error={errors.name && touched.name}
                    errorMessage={errors.name}
                    onChange={({ target }) =>
                      setFieldValue('name', target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Input
                    label="Email"
                    name="email"
                    value={values.email}
                    error={errors.email && touched.email}
                    errorMessage={errors.email}
                    onChange={({ target }) =>
                      setFieldValue('email', target.value)
                    }
                  />
                </Col>

                <Col md={6}>
                  <Field
                    component={Select}
                    label="Tipo de usuário"
                    name="type"
                    options={userTypeOptions}
                  />
                  {/* <CustomSelect
                    label="Tipo de usuário"
                    name="type"
                    value={values.type}
                    error={(errors.type && touched.type) || false}
                    errorMessage={errors.type}
                    onChange={({ target }) => {
                      setFieldValue('type', '');
                      setFieldValue('type', target.value);
                    }}
                  >
                    <option value="">Selecione</option>
                    <option value="tesoureiro">Tesoureiro</option>
                    <option value="contabilidade">Contabilidade</option>
                    <option value="controle_interno">Controle Interno</option>
                    <option value="secretario">Secretario</option>
                  </CustomSelect> */}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Input
                    label="Nova senha"
                    name="password"
                    value={values.password}
                    error={errors.password && touched.password}
                    errorMessage={errors.password}
                    onChange={({ target }) =>
                      setFieldValue('password', target.value)
                    }
                    type="password"
                  />
                </Col>
                <Col>
                  <Input
                    label="Confirme a nova senha"
                    name="password_confirmation"
                    value={values.password_confirmation}
                    error={
                      errors.password_confirmation &&
                      touched.password_confirmation
                    }
                    errorMessage={errors.password_confirmation}
                    onChange={({ target }) =>
                      setFieldValue('password_confirmation', target.value)
                    }
                    type="password"
                  />
                </Col>
              </Row>

              <FooterButtons>
                <Link to="/users">
                  <Button
                    label="Voltar"
                    variant="clean"
                    color="black"
                    width="100px"
                  />
                </Link>
                <Button
                  color="success"
                  label="Atualizar"
                  type="submit"
                  loading={loadingUpdate}
                  width="100px"
                  className="font-medium"
                />
              </FooterButtons>
            </form>
          )}
        </Formik>
      )}
    </Card>
  );
}

UserUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
