import React from 'react';
import { pagix } from 'pagix';
import PropTypes from 'prop-types';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import { Container, Info, ArrowButton, Button } from './styles';

const Paginate = ({ records, limit, current, delta, fixed, onChange }) => {
  const { start, end, middle, prev, next, from, to } = pagix({
    records,
    limit,
    current,
    delta,
    fixed,
  });

  const goToPrev = () => onChange(prev);
  const goToNext = () => onChange(next);

  return (
    <Container>
      {records > 0 && (
        <Info>
          Mostrando <strong> {from}</strong>-<strong>{to} </strong> de {records}
        </Info>
      )}

      {records > limit && (
        <>
          <ArrowButton
            disabled={current === 1}
            onClick={() => onChange(current - 1)}
          >
            <FaAngleLeft size={20} color="#424242" />
          </ArrowButton>

          {start.map(idx => (
            <Button
              key={idx}
              active={current === idx}
              onClick={() => onChange(idx)}
            >
              {idx}
            </Button>
          ))}

          {prev && (
            <Button bg="gray" ml={1} onClick={goToPrev}>
              ...
            </Button>
          )}

          {middle.map(idx => (
            <Button
              key={idx}
              active={current === idx}
              onClick={() => onChange(idx)}
            >
              {idx}
            </Button>
          ))}

          {next && (
            <Button bg="gray" ml={1} onClick={goToNext}>
              ...
            </Button>
          )}

          {end.map(idx => (
            <Button
              key={idx}
              active={current === idx}
              onClick={() => onChange(idx)}
            >
              {idx}
            </Button>
          ))}

          <ArrowButton
            onClick={() => onChange(current + 1)}
            disabled={current === end[0]}
          >
            <FaAngleRight size={20} color="#424242" />
          </ArrowButton>
        </>
      )}
    </Container>
  );
};

Paginate.propTypes = {
  records: PropTypes.number.isRequired,
  limit: PropTypes.number,
  current: PropTypes.number.isRequired,
  delta: PropTypes.number.isRequired,
  fixed: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

Paginate.defaultProps = {
  limit: 10,
};

export default Paginate;
