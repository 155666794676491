import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Card from '../../components/Card';

import { FooterButtons } from '../../styles/containers';

import { createUserRequest } from '../../store/modules/users/actions';

const initialValues = {
  name: '',
  email: '',
  type: '',
  password: '',
  password_confirmation: '',
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Insira o nome do usuário'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('Informe o email'),
  type: Yup.string().required('Informe o tipo do usuário'),
  password: Yup.string().required('Informe a senha'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não combinam')
    .required('Confirmação de senha obrigatória'),
});

const userTypeOptions = [
  {
    value: 'tesoureiro',
    label: 'Tesoureiro',
  },
  {
    value: 'contabilidade',
    label: 'Contabilidade',
  },
  {
    value: 'controle_interno',
    label: 'Controle Interno',
  },
  {
    value: 'secretario',
    label: 'Secretario',
  },
];

export default function UserStore() {
  const { loadingCreate } = useSelector(({ users }) => users);

  const dispatch = useDispatch();

  function handleAddUser(values) {
    dispatch(createUserRequest(values));
  }

  return (
    <Card>
      <div className="card__header">
        <h3>Cadastrar Usuário</h3>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          handleAddUser(values);
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Input
                  label="Nome"
                  name="name"
                  value={values.name}
                  error={errors.name && touched.name}
                  errorMessage={errors.name}
                  onChange={({ target }) => setFieldValue('name', target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  label="Email"
                  name="email"
                  value={values.email}
                  error={errors.email && touched.email}
                  errorMessage={errors.email}
                  onChange={({ target }) =>
                    setFieldValue('email', target.value)
                  }
                />
              </Col>

              <Col md={6}>
                <Field
                  component={Select}
                  label="Tipo de usuário"
                  name="type"
                  options={userTypeOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  label="Senha"
                  name="password"
                  value={values.password}
                  error={errors.password && touched.password}
                  errorMessage={errors.password}
                  onChange={({ target }) =>
                    setFieldValue('password', target.value)
                  }
                  type="password"
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Confirmar senha"
                  name="password_confirmation"
                  value={values.password_confirmation}
                  error={
                    errors.password_confirmation &&
                    touched.password_confirmation
                  }
                  errorMessage={errors.password_confirmation}
                  onChange={({ target }) =>
                    setFieldValue('password_confirmation', target.value)
                  }
                  type="password"
                />
              </Col>
            </Row>

            <FooterButtons>
              <Link to="/users">
                <Button
                  label="Voltar"
                  variant="clean"
                  color="black"
                  width="100px"
                />
              </Link>

              <Button
                color="success"
                label="Salvar"
                type="submit"
                loading={loadingCreate}
                width="85px"
                className="font-medium"
              />
            </FooterButtons>
          </form>
        )}
      </Formik>
    </Card>
  );
}
