import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  height: auto;

  .container {
    text-align: center;
    margin: 0 15px;

    h1 {
      font-size: 14rem;
      line-height: 120px;
      text-align: center;
    }

    a {
      display: flex;
      color: #727981;
      font-size: 2rem;
      transition: opacity 0.2s;
      width: max-content;
      margin: 20px auto;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`;
