import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import profile from './profile/sagas';
import users from './users/sagas';
import password from './password/sagas';
import provider from './provider/sagas';
import invoice from './invoice/sagas';
import fundo from './fundo/sagas';
import notification from './notification/sagas';
import product from './product/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    profile,
    users,
    password,
    provider,
    invoice,
    fundo,
    notification,
    product
  ]);
}
