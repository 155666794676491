import React, { createContext, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import socketio from 'socket.io-client';
import { useSelector } from 'react-redux';

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const { profile: user } = useSelector(({ profile }) => profile);

  const socket = useMemo(
    () =>
      socketio(process.env.REACT_APP_API_URL, {
        query: {
          user_id: user && user.id,
          user_type: user && user.type,
        },
      }),
    [user]
  );

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

function useSocket() {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('userSocket most be used within an SocketProvider');
  }

  return context;
}

SocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SocketProvider, useSocket };
