import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-grid-system';
import { debounce } from 'lodash';
import { MdEdit, MdDelete } from 'react-icons/md';

import Button from '../../components/Button';
import Table from '../../components/Table';
import Card from '../../components/Card';
import Paginate from '../../components/Paginate';
import { CustomInput } from '../../components/Input/styles';

import ModalFormProduct from './ModalFormProduct';
import ModalDeleteConfirm from './ModalDeleteConfirm';

import { 
  searchProductsRequest, 
  createRequest, 
  updateRequest,
  deleteRequest,
  setIsOpenedModal 
} from '../../store/modules/product/actions';

export default function ProductList() {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 8,
    search: '',
  });
  const [modalData, setModalData] = useState(null);

  const { 
    loadingList, 
    products, 
    isOpenedModal,
    loadingCreate,
    loadingUpdate,
    loadingDelete
  } = useSelector(({ product }) => product);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchProductsRequest({ filters }));
  }, [dispatch, filters]);

  const handleChangeFilter = filter => {
    setFilters(oldState => ({ ...oldState, ...filter }));
  };

  const handleSearchWithDebounce = useRef(
    debounce(filter => {
      handleChangeFilter(filter);
    }, 500)
  ).current;
  
  const handleOpenModal = useCallback((name, data) => {
    dispatch(setIsOpenedModal(name));
    setModalData(data);
  }, [dispatch])

  const handleCloseModal = () => {
    dispatch(setIsOpenedModal(''));
  }
  
  const handleCreateProduct = ({ name }) => {
    dispatch(createRequest({ name }))
  }

  const handleUpdateProduct = ({ name, id }) => {
    dispatch(updateRequest({ name, id }))
  }

  const handleDeleteProduct = ({ id }) => {
    dispatch(deleteRequest({ id }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        id: 'actions',
        className: 'txt-end',
        Cell: ({ row }) => {
          const product = row.original;          
          return (
            <>              
              <MdDelete 
                color="#424242" 
                size={17} 
                title="Deletar" 
                className='cursor-pointer' 
                onClick={() => handleOpenModal('delete', product)}
              />                                  
              <MdEdit
                color="#424242"
                size={17}
                title="Editar"
                className='ml-6 cursor-pointer' 
                onClick={() => handleOpenModal('update', product)}
              />                                                                            
            </>
          );
        },
      },
    ],
    [handleOpenModal]
  );

  return (
    <Card>
      <div className="card__header">
        <h3>Produtos</h3>    
        <Button
          color="primary"
          variant="outlined"
          label="Adicionar"
          height="3.4rem"
          className="font-medium"
          onClick={() => handleOpenModal('create')}
          width="90px"          
        />     
        {isOpenedModal === 'create' && (
          <ModalFormProduct 
            isOpen={isOpenedModal === 'create'} 
            onClose={() => handleCloseModal('')} 
            onSubmit={handleCreateProduct} 
            loading={loadingCreate} 
          />
        )}   
        {isOpenedModal === 'update' && (
          <ModalFormProduct 
            initialData={modalData}
            isOpen={isOpenedModal === 'update'} 
            onClose={() => handleCloseModal('')} 
            onSubmit={handleUpdateProduct}  
            loading={loadingUpdate}
          />
        )}   
        {isOpenedModal === 'delete' && (
          <ModalDeleteConfirm 
            data={modalData}
            isOpen={isOpenedModal === 'delete'} 
            onClose={() => handleCloseModal('')} 
            onSubmit={handleDeleteProduct}  
            loading={loadingDelete}
          />
        )}   
      </div>

      <CustomInput
        height="36px"
        placeholder="Buscar"
        mask={false}
        onChange={e => handleSearchWithDebounce({ search: e.target.value })}
        className="mb-12"
      />

      <Row>
        <Col>
          <Table
            columns={columns}
            data={(products && products.products) || []}
            loading={loadingList}
          />

          <Paginate
            records={(products && products.totalCount) || 0}
            current={filters.page}
            limit={filters.per_page}
            onChange={page => handleChangeFilter({ page })}
            delta={1}
            fixed={1}
          />
        </Col>
      </Row>
    </Card>
  );
}

ProductList.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
};

ProductList.defaultProps = {
  cell: {},
};
