import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FiArrowLeft } from 'react-icons/fi';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content, AnimationContainer } from './styles';
import { Banner } from '../styles';

import { forgotPasswordRequest } from '../../../store/modules/password/actions';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Informe um email válido')
    .required('Informe seu email'),
});

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.password.loading);

  function handleSendEmail(values) {
    dispatch(forgotPasswordRequest(values));
  }

  return (
    <Container>
      <Banner>
        <h1>Logenpenho</h1>
        <h2>Recupere a senha</h2>
      </Banner>

      <Content>
        <AnimationContainer>
          <h1>Solicitar nova senha</h1>

          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleSendEmail(values)}
          >
            {({ handleSubmit, values, touched, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Informe seu email"
                  value={values.email}
                  error={errors.email && touched.email}
                  errorMessage={errors.email}
                  onChange={({ target }) =>
                    setFieldValue('email', target.value)
                  }
                />

                <Button
                  type="submit"
                  label="Enviar para email"
                  color="primary"
                  loading={loading}
                />
              </form>
            )}
          </Formik>

          <Link to="/">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
}
