import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from '../Table';
import Action from '../Action';
import ModalProductEdit from '../Modals/ProductEdit';
import Amount from './Amount';

import formatNumber from '../../helpers/formatNumber';

import { Container, Header, ContainerAmount } from './styles';

export default function ProductList({
  products,
  totalValue,
  handleUpdateTotalValue,
  handleRemoveProduct,
  handleUpdateProduct,
  loadingUpdate,
  successUpdate,
  loadingDelete,
  isEditable,
  showHeader,
}) {
  const [modalEditIsVisible, setModalEditIsVisible] = useState(false);
  const [productEditting, setProductEditting] = useState({});

  useEffect(() => {
    if (successUpdate) {
      setModalEditIsVisible(false);
    }
  }, [successUpdate]);

  const columns = useMemo(
    () => [
      {
        Header: 'Descrição',
        accessor: 'description',
      },
      {
        Header: 'Unidade',
        accessor: 'unit',
        className: 'txt-center',
      },
      {
        Header: 'Quantidade',
        accessor: 'quantity',
        className: 'txt-center',
        Cell: ({ cell }) => formatNumber(cell.value, 3),
      },
      {
        Header: 'Val. Unit',
        accessor: 'unit_value',
        className: 'txt-center',
        Cell: ({ cell }) => `R$${formatNumber(cell.value, 3)}`,
      },
      {
        Header: 'Total',
        accessor: 'amount',
        className: 'txt-center',
        Cell: ({ cell }) => `R$${formatNumber(cell.value, 2)}`,
      },
      {
        Header: '#',
        accessor: 'id',
        className: 'txt-center',
        Cell: ({ cell, row }) => {
          const product = row.original;

          return (
            <>
              <Action
                top="0"
                right="10px"
                buttonWidth="20px"
                contentWidth="10rem"
                style={{ margin: '0 auto' }}
              >
                <button
                  type="button"
                  onClick={() => handleRemoveProduct(cell.value)}
                >
                  Deletar
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setModalEditIsVisible(true);
                    setProductEditting(product);
                  }}
                >
                  Editar
                </button>
              </Action>
            </>
          );
        },
      },
    ],
    [handleRemoveProduct]
  );

  return (
    <Container>
      {showHeader && (
        <Header>
          <h4>Descrição do produto/serviço</h4>

          <ContainerAmount padding="0px">
            <Amount
              value={totalValue}
              handleUpdateTotalValue={handleUpdateTotalValue}
              isEditable={isEditable}
            />
          </ContainerAmount>
        </Header>
      )}

      <Table
        columns={columns}
        data={products || []}
        loading={loadingDelete}
        hiddenColumns={!isEditable ? ['id'] : []}
        className="table--striped"
        style={{ marginBottom: 40 }}
      />

      {modalEditIsVisible && (
        <ModalProductEdit
          open={modalEditIsVisible}
          closeAction={() => setModalEditIsVisible(false)}
          product={productEditting}
          handleUpdateProduct={handleUpdateProduct}
          loading={loadingUpdate}
        />
      )}

      {!showHeader && (
        <Amount
          value={totalValue}
          handleUpdateTotalValue={handleUpdateTotalValue}
          isEditable={isEditable}
        />
      )}
    </Container>
  );
}

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleUpdateTotalValue: PropTypes.func,
  handleRemoveProduct: PropTypes.func,
  handleUpdateProduct: PropTypes.func,
  successUpdate: PropTypes.bool,
  loadingUpdate: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  isEditable: PropTypes.bool,
  showHeader: PropTypes.bool,
};

ProductList.defaultProps = {
  handleRemoveProduct: () => {},
  handleUpdateProduct: () => {},
  successUpdate: false,
  loadingUpdate: false,
  loadingDelete: false,
  isEditable: false,
  showHeader: false,
  handleUpdateTotalValue: () => {},
};
