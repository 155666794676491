import React from 'react';
import { Spinner } from 'react-activity';

import { Container } from './styles';

export default function ActivityIndicator() {
  return (
    <Container>
      <Spinner size={15} color="#4b81d4" />
    </Container>
  );
}
