import api from '../../api';

export default {
  index: async filters => {
    const res = await api.get(`/invoices`, {
      params: { ...filters },
    });

    return res;
  },

  downloadReport: async filters => {
    const res = await api.get(`/invoices/download/report`, {
      params: { ...filters },
      responseType: 'blob',
    });

    return res;
  },

  show: async payload => {
    const { id } = payload;
    const res = await api.get(`/invoices/${id}`);
    return res.data;
  },

  store: async payload => {
    const res = await api.post(`/invoices`, payload);
    return res.data;
  },

  clone: async payload => {
    const res = await api.post(`/invoices/${payload.id}/clone`);
    return res.data;
  },

  updateStep1: async payload => {
    const res = await api.patch(`/invoices/step1`, payload);
    return res.data;
  },

  updateStep2: async payload => {
    const res = await api.patch(`/invoices/step2`, payload);
    return res.data;
  },

  updateStep3: async payload => {
    const res = await api.patch(`/invoices/step3`, payload);
    return res.data;
  },

  updateStep4: async payload => {
    const res = await api.patch(`/invoices/step4`, payload);
    return res.data;
  },

  delete: async payload => {
    const { invoiceId } = payload;
    const res = await api.delete(`/invoices/${invoiceId}`);
    return res.data;
  },

  voucher: async payload => {
    const { invoiceId, voucher } = payload;

    const res = await api.patch(`/invoices/${invoiceId}/voucher`, voucher, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    return res.data;
  },

  voucherDelete: async payload => {
    const { invoiceId } = payload;
    const res = await api.delete(`/invoices/${invoiceId}/voucher`);
    return res.data;
  },
};
