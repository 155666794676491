import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import api from '../../../services/api';
import { getUserType } from '../../../helpers/translate';

import ActivityIndicator from '../../../components/ActivityIndicator';

import { ContainerContacts, Contact } from './styles';

export default function Contacts({ onContactClick }) {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get('/chats/contacts');

        setContacts(response.data);
      } catch (err) {
        toast.error('Erro ao carregar os contatos');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleLoadChat = useCallback(
    async receiver_id => {
      try {
        const response = await api.post(`/chats`, { receiver_id });

        const chat = response.data;

        history.push(`/chat/${chat._id}`);
      } catch (err) {
        toast.error('Erro ao iniciar conversa');
      }
    },

    [history]
  );

  if (loading) return <ActivityIndicator />;

  return (
    <>
      <ContainerContacts>
        {!!contacts.length &&
          contacts.map(contact => (
            <Contact
              title="Enviar mensagem"
              key={contact.id}
              onClick={() => {
                handleLoadChat(contact.id);
                onContactClick();
              }}
            >
              <strong>{contact.name}</strong>
              <span>{getUserType(contact.type)}</span>
            </Contact>
          ))}
      </ContainerContacts>
    </>
  );
}

Contacts.propTypes = {
  onContactClick: PropTypes.func,
};

Contacts.defaultProps = {
  onContactClick: () => {},
};
