import api from '../../api';

export default {
  store: async payload => {
    const res = await api.post('/invoices/products', payload);
    return res.data;
  },

  update: async payload => {
    const res = await api.put('/invoices/products', payload);
    return res.data;
  },

  delete: async payload => {
    const { id } = payload;
    const res = await api.delete(`/invoices/products/${id}`);

    return res.data;
  },

  createUnit: async payload => {
    const res = await api.post(`/invoices/products/units`, payload);
    return res.data;
  },
};
