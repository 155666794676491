import extractNumbers from './extractNumbers';

export default function addMaskToStringNumber(mask, stringNumber) {
  if (!stringNumber) return '';

  const stringWithOnlyNumbers = extractNumbers(stringNumber);
  let formattedValue = '';

  for (
    let im = 0, is = 0;
    im < mask.length && is < stringWithOnlyNumbers.length;
    im++
  ) {
    formattedValue +=
      mask[im] === 'X' ? stringWithOnlyNumbers.charAt(is++) : mask.charAt(im);
  }

  return formattedValue;
}
