import styled from 'styled-components';

export const WrapperTags = styled.div`
  margin-bottom: 2.4rem;
  position: relative;

  .button__clear-filters {
    position: absolute;
    right: 0;
    text-decoration: underline;
    font-size: 14px;

    :hover {
      opacity: 0.7;
    }
  }
`;
