import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { InputLabel, InputMessageError } from '../../styles/containers';

import { ContainerInput, CustomTextArea } from './styles';

function TextArea({
  label,
  value,
  type,
  name,
  error,
  errorMessage,
  placeholder,
  width,
  height,
  onChange,
  onBlur,
  className,
  ...rest
}) {
  function handleChange(e) {
    if (onChange) {
      onChange(e);
    }
  }

  function handleBlur(e) {
    if (onBlur) {
      onBlur(e);
    }
  }

  return (
    <InputLabel width={width} className={className}>
      {label && <span className="label">{label}</span>}
      <ContainerInput>
        <CustomTextArea
          value={value}
          name={name}
          error={error ? 'true' : ''}
          onBlur={e => handleBlur(e)}
          onChange={e => handleChange(e)}
          placeholder={placeholder}
          type={type}
          width={width}
          height={height}
          {...rest}
        />
      </ContainerInput>
      {error && <InputMessageError>{errorMessage}</InputMessageError>}
    </InputLabel>
  );
}

TextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

TextArea.defaultProps = {
  label: '',
  name: '',
  value: '',
  error: false,
  errorMessage: '',
  placeholder: '',
  width: '100%',
  height: '36px',
  type: 'text',
  className: '',
  onChange: () => {},
  onBlur: () => {},
};

export default memo(TextArea);
