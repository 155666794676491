import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LoadingIcon } from '../../assets/icon_loading_logo.svg';
import { ReactComponent as LoadingCenterIcon } from '../../assets/icon_loading_logo_center.svg';

import { Container } from './styles';

const PageLoading = ({ isLoading }) =>
  isLoading ? (
    <Container>
      <div className="loading-center">
        <LoadingIcon className="loading-logo" />
        <LoadingCenterIcon className="loading-square" />
      </div>
    </Container>
  ) : null;

PageLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default PageLoading;
