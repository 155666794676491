import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-right: 12px;

  @media (max-width: 700px) {
    position: unset;
  }
`;

export const Badge = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 36px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 5px;
  transition: background 0.2s ease 0s;
  color: var(--gray-2);

  &:hover {
    color: var(--gray-1);
    background: rgb(25, 24, 31);
  }

  ${props =>
    props.visible &&
    css`
      background: rgb(25, 24, 31);
      color: var(--gray-1);

      &::before {
        content: '';
        position: absolute;
        top: calc(100% + 9px);
        left: 50%;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0px 7.5px 8px;
        border-color: transparent transparent rgb(41, 41, 46);
      }
    `}

  ${props =>
    props.hasUnread &&
    css`
      &::after {
        position: absolute;
        right: 7px;
        top: 9px;
        width: 9px;
        height: 9px;
        background: #ff892e;
        content: '';
        border-radius: 50%;
      }
    `}
`;

export const NotificationContent = styled.div`
  position: absolute;
  right: 0px;
  top: calc(100% + 17px);
  width: 380px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 700px) {
    width: 100%;
    top: calc(100% + 7px);
  }
`;

export const NotificationHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: #24292e;
  padding: 0px 20px;
  border-radius: 3px 3px 0px 0px;
  font-size: 16px;
  font-weight: bold;
`;

export const NotificationList = styled.section`
  max-height: 260px;
  min-height: 125px;
  padding: 0px 20px;

  width: 100%;
  font-size: 14px;
  color: rgb(168, 168, 179);
  background: #2d333a;
  border-radius: 0px 0px 5px 5px;

  overflow-y: auto;

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* border-radius: 10px; */
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #30353a;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b3b3b3;
  }
`;

export const NotificationEmptyMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  font-size: 14px;
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  & + div {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .info {
    h5 {
      font-size: 14px;
      color: #fff;
      margin-bottom: 4px;
    }

    time {
      font-size: 12px;
      display: block;
      margin-bottom: 5px;
      color: #a8a8b3;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;

    button {
      border: 0;
      background: none;
      font-size: 12px;
      color: #a8a8b3;
      margin-bottom: 4px;
      text-align: end;

      &:hover {
        opacity: 0.7;
      }

      &.button__mark-as-read {
        &::after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #ff892e;
          border-radius: 50%;
          margin-left: 10px;
        }
      }
    }
  }
`;
