import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import ActivityIndicator from '../../../../components/ActivityIndicator';

import FormRequesterAndProvider from '../FormRequesterAndProvider';
import { CustomCard } from '../commonStyles';
import { FooterButtons } from '../../../../styles/containers';
import ProductContainer from './ProductContainer';
import ModalDeleteConfirm from './ModalDeleteConfirm';

import {
  updateStep1Request,
  resetLoadingShowInvoice,
  allDataToInvoicePageStep1Request,
  deleteRequest,
} from '../../../../store/modules/invoice/actions';

export default function SDUpdate({ match }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const invoiceId = match.params.id;

  const {
    invoice: invoiceData,
    loadingShow,
    loadingUpdate,
    loadingDelete,
  } = useSelector(({ invoice }) => invoice);
  const fundos = useSelector(state => state.fundo.allFundos);
  const providers = useSelector(state => state.provider.allProviders);
  const { profile: user } = useSelector(({ profile }) => profile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allDataToInvoicePageStep1Request({ invoiceId }));

    return () => dispatch(resetLoadingShowInvoice());
  }, [dispatch, invoiceId]);

  function handleClickButtonUdate() {
    document.querySelector('#button-submit-requester-form').click();
  }

  function handleUpdate(values) {
    const payload = {
      invoice: {
        ...values,
        total_value: invoiceData.total_value,
        id: invoiceId,
      },
    };

    dispatch(updateStep1Request(payload));
  }

  function handleDelete(_invoiceId) {
    dispatch(deleteRequest({ invoiceId: _invoiceId }));
  }

  return (
    <CustomCard>
      <div className="d-flex justify-space-between align-center card__header">
        <h3>Editar Nota</h3>
        {!loadingShow &&
          user &&
          invoiceData &&
          user.id === invoiceData.user_id && (
            <Button
              color="error"
              label="Excluir lançamento"
              width="100px"
              height="34px"
              variant="outlined"
              onClick={() => setModalIsOpen(true)}
              className="font-medium"
            />
          )}

        {modalIsOpen && (
          <ModalDeleteConfirm
            open={modalIsOpen}
            closeAction={() => setModalIsOpen(false)}
            handleConfirm={() => handleDelete(invoiceId)}
            loading={loadingDelete}
          />
        )}
      </div>

      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <>
          <FormRequesterAndProvider
            handleSubmitInvoice={values => handleUpdate(values)}
            fundos={fundos}
            providers={providers}
            data={invoiceData}
          />

          <ProductContainer
            invoiceId={invoiceData && invoiceData.id}
            products={invoiceData && invoiceData.products}
          />

          <FooterButtons>
            <Link to="/invoices">
              <Button color="black" variant="clean" label="Voltar" />
            </Link>
            <Button
              color="success"
              label="Atualizar"
              onClick={handleClickButtonUdate}
              loading={loadingUpdate}
              width="100px"
              className="font-medium"
            />
          </FooterButtons>
        </>
      )}
    </CustomCard>
  );
}

SDUpdate.propTypes = {
  match: PropTypes.object.isRequired,
};
