import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Card from '../../components/Card';
import ActivityIndicator from '../../components/ActivityIndicator';

import { FooterButtons } from '../../styles/containers';

import extractNumbers from '../../helpers/extractNumbers';
import { validaCnpj } from '../../helpers/validateCpfCnpj';

import {
  fundoRequest,
  updateRequest,
  resetLoadingFundo,
} from '../../store/modules/fundo/actions';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome do fundo'),
  cnpj: Yup.string()
    .test(
      'is-complete',
      'CNPJ incompleto',
      value => value && !(extractNumbers(value).length < 14)
    )
    .test('is-cnpj', 'CNPJ inválido', value => validaCnpj(value))
    .required('Informe o CNPJ do fundo'),
});

export default function FundoUpdate() {
  const { fundo: responseFundo, loadingShow, loadingUpdate } = useSelector(
    ({ fundo }) => fundo
  );

  const dispatch = useDispatch();
  const fundoId = useParams().id;

  useEffect(() => {
    dispatch(fundoRequest({ id: fundoId }));

    return () => dispatch(resetLoadingFundo());
  }, [dispatch, fundoId]);

  function handleUpdateFundo(values) {
    const { name, cnpj } = values;

    const formattedCnpj = extractNumbers(cnpj);
    const payload = { name, cnpj: formattedCnpj, id: fundoId };

    dispatch(updateRequest(payload));
  }

  return (
    <Card>
      <div className="card__header">
        <h3>Cadastrar Fundo</h3>
      </div>
      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <Formik
          initialValues={responseFundo}
          onSubmit={values => {
            handleUpdateFundo(values);
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Input
                    label="Nome"
                    name="name"
                    value={values.name}
                    error={errors.name && touched.name}
                    errorMessage={errors.name}
                    onChange={({ target }) =>
                      setFieldValue('name', target.value)
                    }
                  />
                </Col>
                <Col>
                  <Input
                    label="CNPJ"
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    value={values.cnpj}
                    error={errors.cnpj && touched.cnpj}
                    errorMessage={errors.cnpj}
                    onChange={({ target }) =>
                      setFieldValue('cnpj', target.value)
                    }
                  />
                </Col>
              </Row>

              <FooterButtons>
                <Link to="/fundos">
                  <Button
                    label="Voltar"
                    variant="clean"
                    color="black"
                    width="100px"
                  />
                </Link>

                <Button
                  color="success"
                  label="Atualizar"
                  type="submit"
                  loading={loadingUpdate}
                  width="100px"
                  className="font-medium"
                />
              </FooterButtons>
            </form>
          )}
        </Formik>
      )}
    </Card>
  );
}
