import React from 'react';
import { Router } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader';
import { setConfiguration } from 'react-grid-system';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';

import CustomContainerToast from './components/ContainerToast';

import GlobalStyle from './styles/global';
import Routes from './routes';
import history from './services/history';
import './config/reactotronConfig';

setConfiguration({ gutterWidth: 10 });
setConfig({
  showReactDomPatchNotification: false,
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <CustomContainerToast />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
