import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';

import {
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
} from './actions';

export function* forgotPasswordWorker({ payload }) {
  try {
    const { email } = payload;

    yield call(api.post, '/password/forgot', { email });

    toast.success('Link enviado com sucesso, confira seu email.');

    yield put(forgotPasswordSuccess());
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(forgotPasswordFailure());
  }
}

export function* resetPasswordWorker({ payload }) {
  try {
    yield call(api.post, '/password/reset', payload);

    toast.success('Nova senha cadastrada com sucesso!!');

    history.push('/');
    yield put(resetPasswordSuccess());
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(resetPasswordFailure());
  }
}

export default all([
  takeLatest('@password/FORGOT_PASSWORD_REQUEST', forgotPasswordWorker),
  takeLatest('@password/RESET_PASSWORD_REQUEST', resetPasswordWorker),
]);
