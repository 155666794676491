import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';

import api from '../../../services/api';
import apiInvoice from '../../../services/core/http/invoice';
import apiProduct from '../../../services/core/http/invoiceProduct';

import history from '../../../services/history';
import userNotAllowed from '../../../helpers/userNotAllowed';

import { fundosSuccess } from '../fundo/actions'
import { listProvidersSuccess } from '../provider/actions'
import { listProductsSuccess } from '../product/actions'


import {
  listInvoicesSuccess,
  listInvoicesFailure,
  showInvoiceSuccess,
  showInvoiceFailure,
  allDataToInvoicePageStep1Success,
  allDataToInvoicePageStep1Failure,
  createInvoiceSuccess,
  createInvoiceFailure,
  cloneInvoiceSuccess,
  cloneInvoiceFailure,
  updateStep1Success,
  updateStep1Failure,
  updateStep2Success,
  updateStep2Failure,
  updateStep3Success,
  updateStep3Failure,
  updateStep4Success,
  updateStep4Failure,
  voucherSuccess,
  voucherFailure,
  voucherDeleteSuccess,
  voucherDeleteFailure,
  deleteSuccess,
  deleteFailure,
  downloadInvoiceReportSuccess,
  downloadInvoiceReportFaliure,
  createProductSuccess,
  createProductFailure,
  updateProductSuccess,
  updateProductFailure,
  deleteProductSuccess,
  deleteProductFailure,
  createProductUnitSuccess,
  createProductUnitFailure,
  listProductUnitSuccess,
} from './actions';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import { signOut } from '../auth/actions';
import downloadFile from '../../../helpers/downloadFile';

export function* listInvoicesWorker({ payload }) {
  try {
    const response = yield call(apiInvoice.index, payload);

    const totalCount = Number(response.headers['x-total-count']);

    const responseWithCount = { invoices: response.data, totalCount };

    yield put(listInvoicesSuccess(responseWithCount));
  } catch ({ response }) {
    if (userNotAllowed(response.status)) {
      yield put(signOut());
      toast.error('Token expirado!');
      return;
    }

    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(listInvoicesFailure());
  }
}

export function* downloadReportWorker({ payload }) {
  try {
    yield put(commonLoadingStart());

    const response = yield call(apiInvoice.downloadReport, payload);

    downloadFile({
      type: 'application/pdf',
      fileName: 'relatorio',
      file: response.data,
    });

    yield put(downloadInvoiceReportSuccess());
  } catch ({ response }) {
    toast.warn(
      (response && response.data.message) || 'Erro ao gerar relatório'
    );

    yield put(downloadInvoiceReportFaliure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* allDataToInvoicePageStep1Worker({ payload }) {
  try {
    const { invoiceId } = payload;

    const [responseFundos, responseProviders, responseInvoice, responseUnits, responseProducts] = yield all([
      call(api.get, '/fundos'),
      call(api.get, '/providers'),
      invoiceId && call(api.get, `/invoices/${invoiceId}`),
      call(api.get, '/invoices/products/units'),
      call(api.get, '/products'),
    ]);

    yield put(fundosSuccess({ fundos: responseFundos.data }));
    yield put(listProvidersSuccess({ providers: responseProviders.data }));
    yield put(listProductUnitSuccess({ units: responseUnits.data }));
    yield put(listProductsSuccess({ products: responseProducts.data }));

    yield put(
      allDataToInvoicePageStep1Success({
        invoice: responseInvoice ? responseInvoice.data : null,
      })
    );
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    history.push('/invoices');
    yield put(allDataToInvoicePageStep1Failure());
  }
}

export function* showInvoiceWorker({ payload }) {
  try {
    const response = yield call(apiInvoice.show, payload);
    yield put(showInvoiceSuccess(response));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    history.push('/invoices');
    yield put(showInvoiceFailure());
  }
}

export function* createInvoiceWorker({ payload }) {
  try {
    const response = yield call(apiInvoice.store, payload);

    yield put(createInvoiceSuccess(response));
    history.push('/invoices');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(createInvoiceFailure());
  }
}

export function* cloneInvoiceWorker({ payload }) {

  try {
    yield put(commonLoadingStart());

    const newInvoice = yield call(apiInvoice.clone, payload);

    yield put(cloneInvoiceSuccess(newInvoice));

    if(newInvoice && newInvoice.id) {
      const route = `/invoice/${newInvoice.id}/step/secretario`
      history.push(route);
    }
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(cloneInvoiceFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateStep1Worker({ payload }) {
  try {
    const { invoice } = payload;

    yield call(apiInvoice.updateStep1, invoice);

    history.push('/invoices');
    yield put(updateStep1Success());
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateStep1Failure());
  }
}

export function* updateStep2Worker({ payload }) {
  try {
    yield call(apiInvoice.updateStep2, payload);

    history.push('/invoices');
    yield put(updateStep2Success());
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateStep2Failure());
  }
}

export function* updateStep3Worker({ payload }) {
  try {
    yield call(apiInvoice.updateStep3, payload);

    history.push('/invoices');
    yield put(updateStep3Success());
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateStep3Failure());
  }
}

export function* updateStep4Worker({ payload }) {
  try {
    yield call(apiInvoice.updateStep4, payload);

    history.push('/invoices');
    yield put(updateStep4Success());
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateStep4Failure());
  }
}

export function* voucherWorker({ payload }) {
  try {
    const response = yield call(apiInvoice.voucher, payload);
    yield put(voucherSuccess(response));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(voucherFailure());
  }
}

export function* voucherDeleteWorker({ payload }) {
  try {
    const response = yield call(apiInvoice.voucherDelete, payload);

    yield put(voucherDeleteSuccess(response));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(voucherDeleteFailure());
  }
}

export function* deleteWorker({ payload }) {
  try {
    yield call(apiInvoice.delete, payload);

    yield put(deleteSuccess());
    history.push('/invoices');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(deleteFailure());
  }
}

export function* createProductWorker({ payload }) {
  try {
    const product = yield call(apiProduct.store, payload);

    yield put(createProductSuccess(product));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(createProductFailure());
  }
}

export function* updateProductWorker({ payload }) {
  try {
    const product = yield call(apiProduct.update, payload);
    yield put(updateProductSuccess(product));
  } catch (err) {
    console.log(err);
    toast.error(
      (err.response && err.response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateProductFailure());
  }
}

export function* deleteProductWorker({ payload }) {
  try {
    yield call(apiProduct.delete, payload);

    yield put(deleteProductSuccess(payload));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(deleteProductFailure());
  }
}

export function* createProductUnitWorker({ payload }) {
  try {
    const response = yield call(apiProduct.createUnit, payload);

    yield put(createProductUnitSuccess({ unit: response }));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(createProductUnitFailure());
  }
}

export default all([
  takeLatest(types.LIST_REQUEST, listInvoicesWorker),
  takeLatest(types.DOWNLOAD_REPORT_REQUEST, downloadReportWorker),
  takeLatest(types.SHOW_REQUEST, showInvoiceWorker),
  takeLatest(
    types.ALL_DATA_TO_INVOICE_PAGE_STEP1_REQUEST,
    allDataToInvoicePageStep1Worker
  ),
  takeLatest(types.CREATE_REQUEST, createInvoiceWorker),
  takeLatest(types.CLONE_REQUEST, cloneInvoiceWorker),
  takeLatest(types.UPDATE_STEP1_REQUEST, updateStep1Worker),
  takeLatest(types.UPDATE_STEP2_REQUEST, updateStep2Worker),
  takeLatest(types.UPDATE_STEP3_REQUEST, updateStep3Worker),
  takeLatest(types.UPDATE_STEP4_REQUEST, updateStep4Worker),
  takeLatest(types.VOUCHER_REQUEST, voucherWorker),
  takeLatest(types.VOUCHER_DELETE_REQUEST, voucherDeleteWorker),
  takeLatest(types.DELETE_REQUEST, deleteWorker),
  takeLatest(types.CREATE_PRODUCT_REQUEST, createProductWorker),
  takeLatest(types.UPDATE_PRODUCT_REQUEST, updateProductWorker),
  takeLatest(types.DELETE_PRODUCT_REQUEST, deleteProductWorker),
  takeLatest(types.CREATE_PRODUCT_UNIT_REQUEST, createProductUnitWorker),
]);
