import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CustomAction, Container, FilterColumn, CustomButton } from './styles';

const SelectFilters = ({ onSelect, values }) => {
  const [activeStatus, setActiveStatus] = useState({
    type: '',
    priority: '',
    location: '',
    status: '',
  });

  useEffect(() => {
    if (values) {
      setActiveStatus(values);
    }
  }, [values]);

  const getOptions = type => {
    const options = {
      priority: [
        { label: 'Todos', value: '' },
        { label: 'Rotina', value: 'rotina' },
        { label: 'Urgência', value: 'urgencia' },
      ],
      type: [
        { label: 'Todos', value: '' },
        { label: 'Aluguel', value: 'aluguel' },
        { label: 'Assessor', value: 'assessor' },
        { label: 'Compra direta', value: 'compra_direta' },
        { label: 'Diaria', value: 'diaria' },
        { label: 'Dispensa', value: 'dispensa' },
        { label: 'Recibo', value: 'recibo' },
        { label: 'Por Licitação', value: 'por_licitacao' },
      ],
      location: [
        { label: 'Todos', value: '' },
        { label: 'Secretaria', value: 'secretaria' },
        { label: 'Controle Interno', value: 'controle_interno' },
        { label: 'Contabilidade', value: 'contabilidade' },
        { label: 'Tesouraria', value: 'tesouraria' },
      ],
      status: [
        { label: 'Todos', value: '' },
        { label: 'Nova', value: 'nova' },
        { label: 'Autorizada', value: 'autorizada' },
        { label: 'Não autorizada', value: 'nao_autorizada' },
        { label: 'Processada', value: 'processada' },
        { label: 'Aguardando Recurso', value: 'aguardando_recurso' },
        { label: 'Finalizada', value: 'finalizada' },
        { label: 'Cancelada', value: 'cancelada' },
      ],
      default: [],
    };

    return options[type] || options.default;
  };

  const handleSelectOption = selectedOption => {
    const newActiveStatus = { ...activeStatus, ...selectedOption };

    onSelect(newActiveStatus);
    setActiveStatus(newActiveStatus);
  };

  return (
    <CustomAction
      icon="FaFilter"
      iconSize={15}
      color="#767676"
      top="100%"
      right="0px"
      buttonWidth="20px"
      contentWidth="max-content"
      closeOnClick={false}
    >
      <h4>Filtrar por</h4>
      <Container>
        <FilterColumn className="w-100">
          <h5 className="filter-title">Prioridade:</h5>

          {getOptions('priority').map(option => (
            <CustomButton
              key={option.value}
              onClick={() => handleSelectOption({ priority: option.value })}
              selected={activeStatus.priority === option.value || false}
              type="button"
            >
              {option.label}
            </CustomButton>
          ))}
        </FilterColumn>

        <FilterColumn className="w-130">
          <h5 className="filter-title">Localização:</h5>

          {getOptions('location').map(option => (
            <CustomButton
              key={option.value}
              onClick={() => handleSelectOption({ location: option.value })}
              selected={activeStatus.location === option.value || false}
              type="button"
            >
              {option.label}
            </CustomButton>
          ))}
        </FilterColumn>

        <FilterColumn className="w-130">
          <h5 className="filter-title">Tipo:</h5>

          {getOptions('type').map(option => (
            <CustomButton
              key={option.value}
              onClick={() => handleSelectOption({ type: option.value })}
              selected={activeStatus.type === option.value || false}
              type="button"
            >
              {option.label}
            </CustomButton>
          ))}
        </FilterColumn>

        <FilterColumn className="w-170">
          <h5 className="filter-title">Status:</h5>
          {getOptions('status').map(option => (
            <CustomButton
              key={option.value}
              onClick={() => handleSelectOption({ status: option.value })}
              selected={activeStatus.status === option.value || false}
              type="button"
            >
              {option.label}
            </CustomButton>
          ))}
        </FilterColumn>
      </Container>
    </CustomAction>
  );
};

SelectFilters.propTypes = {
  onSelect: PropTypes.func.isRequired,
  values: PropTypes.shape({
    type: PropTypes.string,
    priority: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
  }),
};

SelectFilters.defaultProps = {
  values: {
    type: '',
    priority: '',
    location: '',
    status: '',
  },
};

export default SelectFilters;
