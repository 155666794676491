import api from '../../api';

export default {
  update: async profile => {
    const res = await api.put(`/profile/me`, profile);
    return res.data;
  },
  updateAvatar: async file => {
    const res = await api.patch(`/users/avatar`, file);
    return res.data;
  },
};
