import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns/esm';
import { TiMessages } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useSocket } from '../../../hooks/socket';
import api from '../../../services/api';

import avatarImage from '../../../assets/avatar.png';
import ActivityIndicator from '../../../components/ActivityIndicator';

import {
  StartChatMessage,
  Container,
  ReceiverInfo,
  OnlineInfo,
  ContainerMessages,
  ContainerMessage,
  Message,
  Sender,
  CustomButton,
} from './styles';

export default function Messenger({ chatId }) {
  const [messages, setMessages] = useState([]);
  const [sendMessage, setSendMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingSendMessage, setLoadingSendMessage] = useState(false);
  const [receiverIsOnline, setReceiverIsOnline] = useState(false);
  const [receiver, setReceiver] = useState(false);

  const { profile: currentUser } = useSelector(({ profile }) => profile);

  const { socket } = useSocket();
  const history = useHistory();

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    if (chatId) {
      (async () => {
        try {
          setLoading(true);

          const response = await api.get(`/chats/${chatId}/messages`, {
            cancelToken: source.token,
          });

          const formattedMessages =
            response.data &&
            response.data.messages.map(_message => {
              return {
                ..._message,
                createdAt: format(
                  parseISO(_message.createdAt),
                  "dd/MM/yyyy 'às' HH:mm:ss"
                ),
              };
            });

          setMessages(formattedMessages);

          setReceiver(() => {
            return (
              response.data &&
              response.data.users.find(user => user.id !== currentUser.id)
            );
          });
        } catch ({ response }) {
          toast.error(
            (response && response.data.message) ||
              'Erro de comunicação com o servidor'
          );

          history.push('/chat');
        } finally {
          setLoading(false);
        }
      })();
    }
    return () => source.cancel();
  }, [chatId, history, currentUser.id]);

  useEffect(() => {
    if (chatId) {
      socket.emit('subscribe/chat', chatId);
    }
    return () => socket.emit('unsubscribe/chat', chatId);
  }, [socket, chatId]);

  useEffect(() => {
    if (receiver && receiver.id) {
      socket.emit('user/online', receiver.id);
    }
  }, [socket, receiver]);

  useEffect(() => {
    if (receiver && receiver.id) {
      socket.on(`user/${receiver.id}/online`, ({ isOnline }) => {
        setReceiverIsOnline(isOnline);
      });
    }
    return () => receiver && socket.off(`user/${receiver.id}/online`);
  }, [socket, receiver]);

  useEffect(() => {
    if (chatId) {
      socket.on(`chat/${chatId}/message`, message => {
        if (message.chat_id !== chatId) return;

        const formattedMessage = {
          ...message,
          createdAt: format(
            parseISO(message.createdAt),
            "dd/MM/yyyy 'às' HH:mm:ss"
          ),
        };

        setMessages(oldMessages => {
          const messageExists = oldMessages.find(
            findMessage => findMessage._id === message._id
          );
          if (messageExists) return oldMessages;

          return [...oldMessages, formattedMessage];
        });

        const containerChatMessages = document.querySelector('#chat-messages');
        if (containerChatMessages) {
          containerChatMessages.scrollTop = containerChatMessages.scrollHeight;
        }
      });
    }

    return () => socket.off(`chat/${chatId}/message`);
  }, [socket, chatId]);

  const handleSendMessage = useCallback(
    async e => {
      if (!sendMessage) return;

      try {
        e.preventDefault();

        setLoadingSendMessage(true);

        await api.post(`chats/${chatId}/messages`, {
          message: sendMessage.trim(),
        });

        setSendMessage('');
      } catch (err) {
        toast.error('Erro ao enviar mensagem');
      } finally {
        setLoadingSendMessage(false);
      }
    },
    [chatId, sendMessage]
  );

  function onKeyPressEnter(event) {
    if (event.which === 13) {
      event.preventDefault();
      document.querySelector('#btn-sender-form').click();
    }
  }

  if (!chatId)
    return (
      <StartChatMessage>
        <TiMessages size={30} />
        Selecione um contato <br />
        para mandar mensagem
      </StartChatMessage>
    );

  if (loading) return <ActivityIndicator />;

  return (
    <Container>
      <ReceiverInfo>
        <img
          src={(receiver && receiver.avatar_url) || avatarImage}
          alt="Avatar"
        />
        <div>
          <strong>{receiver && receiver.name}</strong>
          <OnlineInfo isOnline={!!receiverIsOnline}>
            {receiverIsOnline ? 'Online' : 'Offline'}
          </OnlineInfo>
        </div>
      </ReceiverInfo>

      <ContainerMessages id="chat-messages">
        {messages &&
          !!messages.length &&
          messages.map(message => (
            <ContainerMessage
              key={message._id}
              isYourMessage={message.sender_id === currentUser.id}
            >
              <Message
                isYourMessage={message.sender_id === currentUser.id}
                title={
                  message.sender_id === currentUser.id ? 'Você' : receiver.name
                }
              >
                <span>{message.text}</span>
                <time>{message.createdAt}</time>
              </Message>
            </ContainerMessage>
          ))}
      </ContainerMessages>

      <Sender onSubmit={handleSendMessage} onKeyPress={onKeyPressEnter}>
        <textarea
          placeholder="Digite uma mensagem"
          required
          value={sendMessage}
          onChange={e => setSendMessage(e.target.value)}
        />
        <CustomButton
          type="submit"
          id="btn-sender-form"
          label="Enviar"
          loading={loadingSendMessage}
        />
      </Sender>
    </Container>
  );
}

Messenger.propTypes = {
  chatId: PropTypes.string,
};

Messenger.defaultProps = {
  chatId: '',
};
