import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.nav`
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  height: max-content;

  margin-top: 77px;
  border-radius: 5px;

  position: fixed !important;
  min-width: 155px !important;
  z-index: 12;

  transition: transform 0.5s ease !important;
  transform: ${({ expanded }) =>
    expanded ? 'translateX(0)' : 'translateX(-208px)'};

  @media screen and (max-width: 878px) {
    height: 100%;
    top: 0;
    margin: 0;
    left: 0;
    padding-top: 77px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
      0 1px 3px 1px rgb(60 64 67 / 15%);
  }

  @media screen and (min-width: 880px) {
    transform: translateX(0);
  }
`;

export const ItemList = styled.div`
  padding: 10px;
`;

export const Item = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  color: var(--primary-text);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;

  cursor: pointer;

  &.active {
    color: var(--blue-800) !important;
    background: var(--blue-100);
    border-radius: 12px;
  }

  & .icon {
    align-self: center;
    margin-right: 5px;
  }
`;
