import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import {
  fundosSuccess,
  fundosFailure,
  fundoSuccess,
  fundoFailure,
  createSuccess,
  createFailure,
  updateSuccess,
  updateFailure,
  searchFundosSuccess,
  searchFundosFailure,
} from './actions';

import apiFundos from '../../../services/core/http/fundos';
import history from '../../../services/history';

export function* fundosWorker({ payload }) {
  try {
    const fundos = yield call(apiFundos.index, payload);   

    yield put(fundosSuccess({ fundos }));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(fundosFailure());
  }
}

export function* searchFundosWorker({ payload }) {
  try {
    const { filters } = payload;

    const response = yield call(apiFundos.search, filters);

    const totalCount = Number(response.headers['x-total-count']);
    const responseWithCount = { fundos: response.data, totalCount };

    yield put(searchFundosSuccess(responseWithCount));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(searchFundosFailure());
  }
}

export function* fundoWorker({ payload }) {
  try {
    const fundo = yield call(apiFundos.show, payload);

    yield put(fundoSuccess(fundo));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    history.push('/fundos');

    yield put(fundoFailure());
  }
}

export function* createFundoWorker({ payload }) {
  try {
    yield call(apiFundos.store, payload);

    yield put(createSuccess());

    history.push('/fundos');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(createFailure());
  }
}

export function* updateFundoWorker({ payload }) {
  try {
    yield call(apiFundos.update, payload);
    yield put(updateSuccess());

    history.push('/fundos');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateFailure());
  }
}

export default all([
  takeLatest(types.LIST_REQUEST, fundosWorker),
  takeLatest(types.SEARCH_REQUEST, searchFundosWorker),
  takeLatest(types.SHOW_REQUEST, fundoWorker),
  takeLatest(types.CREATE_REQUEST, createFundoWorker),
  takeLatest(types.UPDATE_REQUEST, updateFundoWorker),
]);
