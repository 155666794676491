import moment from 'moment-timezone';

moment.tz.setDefault('America/Sao_Paulo');

// caso a hr venha 00:00 :::: o timezone tem uma diferença de 3h e vai vir com um dia a menos
// usar o utc exclui a hr
export const formatDate = (value, mask = 'DD/MM/YYYY') => {
  return value
    ? moment(value)
        .utc()
        .format(mask)
    : '';
};
