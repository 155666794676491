import React, { useCallback } from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import DetailsInfo from '../../../components/DetailsInfo';

import { formatDate } from '../../../helpers/formatDate';

function Tesouraria({ invoice }) {
  const getInfo = useCallback(
    info => {
      const {
        fonte_recurso,
        data_pagamento,
        status,
        previsao_pagamento_inicio,
        previsao_pagamento_fim,
      } = invoice;

      const invoiceData = {
        fonte_recurso,
        data_pagamento:
          data_pagamento && formatDate(data_pagamento, 'DD/MM/yyyy'),
        status,

        previsao_pagamento:
          previsao_pagamento_inicio &&
          previsao_pagamento_fim &&
          `${formatDate(
            previsao_pagamento_inicio,
            'DD/MM/yyyy'
          )} até ${formatDate(previsao_pagamento_fim, 'DD/MM/yyyy')}`,
        default: '---',
      };

      return invoiceData[info] || invoiceData.default;
    },
    [invoice]
  );

  return (
    <div>
      <h4 className="title">Tesouraria</h4>
      <Row>
        <Col md={4}>
          <DetailsInfo>
            <span>Fonte de recurso</span>
            <p>{getInfo('fonte_recurso')}</p>
          </DetailsInfo>
        </Col>

        <Col md={4}>
          <DetailsInfo>
            <span>Previsão de pagamento</span>
            <p>{getInfo('previsao_pagamento')}</p>
          </DetailsInfo>
        </Col>

        <Col md={4}>
          <DetailsInfo>
            <span>Data de pagamento</span>
            <p>{getInfo('data_pagamento')}</p>
          </DetailsInfo>
        </Col>
      </Row>
    </div>
  );
}

Tesouraria.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default Tesouraria;
