import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { Formik, Field } from 'formik';
import axios from 'axios';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Card from '../../components/Card';
import ActivityIndicator from '../../components/ActivityIndicator';

import { FooterButtons } from '../../styles/containers';

import extractNumbers from '../../helpers/extractNumbers';
import { validationSchemaCreateProvider } from '../../services/Utils/ValidatorSchema/provider';

import {
  showProviderRequest,
  updateProviderRequest,
  resetLoadingShow,
} from '../../store/modules/provider/actions';

const documentOptions = [
  {
    value: 'cpf',
    label: 'CPF',
  },
  {
    value: 'cnpj',
    label: 'CNPJ',
  },
];

export default function ProviderUpdate({ match }) {
  const { id } = match.params;

  const responseFetchProvider = useSelector(state => state.provider.provider);
  const loadingUpdate = useSelector(state => state.provider.loadingUpdate);
  const loadingFetch = useSelector(state => state.provider.loadingShow);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showProviderRequest(id));

    return () => dispatch(resetLoadingShow());
  }, [dispatch, id]);

  async function handleFetchCep({ values, setValues }) {
    const { zipcode } = values;

    if (zipcode) {
      const zipcodeFormatted = extractNumbers(zipcode);

      if (zipcodeFormatted.length === 8) {
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${zipcodeFormatted}/json`
          );

          if (response.data.erro) {
            throw new Error('CEP inválido');
          }
          const { bairro, localidade, logradouro, uf } = response.data;

          setValues({
            ...values,
            address: logradouro || values.address,
            neighborhood: bairro || values.neighborhood,
            city: localidade || values.city,
            state: uf || values.state,
            country: 'Brasil',
          });
        } catch (e) {
          setValues({
            ...values,
            address: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
          });
        }
      }
    }
  }

  function handleUpdateProvider(values) {
    dispatch(updateProviderRequest(values));
  }

  const renderDocumentLabel = useCallback(documentType => {
    const types = {
      cpf: 'CPF',
      cnpj: 'CNPJ',
      default: 'CNPJ',
    };

    return types[documentType] || types.default;
  }, []);

  return (
    <Card>
      <div className="card__header">
        <h3>Fornecedor</h3>
      </div>

      {loadingFetch ? (
        <ActivityIndicator />
      ) : (
        <Formik
          initialValues={responseFetchProvider || {}}
          onSubmit={values => {
            handleUpdateProvider(values);
          }}
          validationSchema={validationSchemaCreateProvider}
          enableReinitialize
        >
          {({
            handleSubmit,
            values,
            setValues,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Input
                    label="Nome"
                    name="name"
                    value={values.name}
                    error={errors.name && touched.name}
                    errorMessage={errors.name}
                    onChange={({ target }) =>
                      setFieldValue('name', target.value)
                    }
                  />
                </Col>
                <Col>
                  <Input
                    label="Conta para pagamento"
                    name="payment_account"
                    value={values.payment_account}
                    error={errors.payment_account && touched.payment_account}
                    errorMessage={errors.payment_account}
                    onChange={({ target }) =>
                      setFieldValue('payment_account', target.value)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Field
                    component={Select}
                    label="Tipo de documento"
                    name="document_type"
                    options={documentOptions}
                  />
                </Col>
                <Col>
                  <Input
                    label={renderDocumentLabel(values.document_type)}
                    name="document_number"
                    mask={
                      values.document_type === 'cnpj'
                        ? '99.999.999/9999-99'
                        : '999.999.999-99'
                    }
                    value={values.document_number}
                    error={errors.document_number && touched.document_number}
                    errorMessage={errors.document_number}
                    onChange={({ target }) =>
                      setFieldValue(
                        'document_number',
                        extractNumbers(target.value)
                      )
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Input
                    label="Telefone para contato"
                    name="phone"
                    mask="(99) 9 9999-9999"
                    value={values.phone}
                    error={errors.phone && touched.phone}
                    errorMessage={errors.phone}
                    onChange={({ target }) =>
                      setFieldValue('phone', target.value)
                    }
                  />
                </Col>
                <Col>
                  <Input
                    label="Email"
                    name="email"
                    value={values.email || ''}
                    error={errors.email && touched.email}
                    errorMessage={errors.email}
                    onChange={({ target }) =>
                      setFieldValue('email', target.value)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Input
                    name="zipcode"
                    label="CEP"
                    mask="99999-999"
                    value={values.zipcode}
                    error={errors.zipcode && touched.zipcode}
                    errorMessage={errors.zipcode}
                    onBlur={() => handleFetchCep({ values, setValues })}
                    onChange={({ target }) =>
                      setFieldValue('zipcode', target.value)
                    }
                  />
                </Col>
                <Col md={6}>
                  <Input
                    name="city"
                    label="Cidade"
                    value={values.city}
                    error={errors.city && touched.city}
                    errorMessage={errors.city}
                    onChange={({ target }) =>
                      setFieldValue('city', target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Input
                    name="state"
                    label="Estado"
                    value={values.state}
                    error={errors.state && touched.state}
                    errorMessage={errors.state}
                    onChange={({ target }) =>
                      setFieldValue('state', target.value)
                    }
                  />
                </Col>
                <Col md={6}>
                  <Input
                    name="country"
                    label="País"
                    value={values.country}
                    error={errors.country && touched.country}
                    errorMessage={errors.country}
                    onChange={({ target }) =>
                      setFieldValue('country', target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    name="address"
                    label="Logradouro"
                    value={values.address}
                    error={errors.address && touched.address}
                    errorMessage={errors.address}
                    onChange={({ target }) =>
                      setFieldValue('address', target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Input
                    name="neighborhood"
                    label="Bairro"
                    value={values.neighborhood}
                    error={errors.neighborhood && touched.neighborhood}
                    errorMessage={errors.neighborhood}
                    onChange={({ target }) =>
                      setFieldValue('neighborhood', target.value)
                    }
                  />
                </Col>
                <Col md={6}>
                  <Input
                    name="street_number"
                    label="Numero"
                    value={values.street_number}
                    error={errors.street_number && touched.street_number}
                    errorMessage={errors.street_number}
                    onChange={({ target }) =>
                      setFieldValue('street_number', target.value)
                    }
                  />
                </Col>
              </Row>

              <FooterButtons>
                <Link to="/providers">
                  <Button
                    label="Voltar"
                    variant="clean"
                    color="black"
                    width="100px"
                  />
                </Link>

                <Button
                  color="success"
                  label="Salvar"
                  type="submit"
                  width="85px"
                  loading={loadingUpdate}
                  className="font-medium"
                />
              </FooterButtons>
            </form>
          )}
        </Formik>
      )}
    </Card>
  );
}

ProviderUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
