import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaCaretDown } from 'react-icons/fa';

import { getUserType } from '../../../helpers/translate';
import { signOut } from '../../../store/modules/auth/actions';

import avatarImage from '../../../assets/avatar.png';

import { Container, Info, Content, Button } from './styles';

export default function Profile() {
  const [visible, setVisible] = useState(false);

  const { profile: user } = useSelector(({ profile }) => profile);

  const history = useHistory();
  const dispatch = useDispatch();

  const notificationsContentRef = useRef(null);
  const buttonRef = useRef(null);

  function handleClickOutside(event) {
    if (
      notificationsContentRef.current &&
      !notificationsContentRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  });

  function handleToggleVisible() {
    setVisible(!visible);
  }

  const handleProfilePage = useCallback(() => {
    history.push('/profile');
    setVisible(false);
  }, [history]);

  const handleSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <Container>
      {user && (
        <Info onClick={handleToggleVisible} visible={visible} ref={buttonRef}>
          <div>
            <strong>{user.name}</strong>
            <span>{getUserType(user.type)}</span>
          </div>

          <img src={user.avatar_url || avatarImage} alt={user.name} />

          <FaCaretDown size={16} color="#fff" />
        </Info>
      )}

      <Content visible={visible} ref={notificationsContentRef}>
        <Button onClick={() => handleProfilePage()}>Perfil</Button>
        <Button onClick={() => handleSignOut()}>Sair</Button>
      </Content>
    </Container>
  );
}
