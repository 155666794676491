import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import * as FontAwesome from 'react-icons/fa';

import { Container, Content } from './styles';

export default function Action({
  children,
  label,
  top,
  right,
  bottom,
  left,
  buttonWidth,
  color,
  icon,
  iconSize,
  fontColor,
  contentWidth,
  closeOnclick,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);

  const Icon = FontAwesome[icon];

  const wrapperRef = useRef(null);

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }
  function handleClickInside(event) {
    if (
      wrapperRef.current &&
      wrapperRef.current.contains(event.target) &&
      !closeOnclick
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('click', handleClickInside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('click', handleClickInside);
    };
  });

  return (
    <Container width={buttonWidth} {...rest}>
      <button
        type="button"
        className="action__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {label && <span className="action__label">{label}</span>}
        {icon && <Icon size={iconSize} color={color} />}
      </button>

      <Content
        ref={wrapperRef}
        isOpen={isOpen}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        contentWidth={contentWidth}
      >
        {children}
      </Content>
    </Container>
  );
}

Action.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  position: PropTypes.string,
  buttonWidth: PropTypes.string,
  color: PropTypes.string,
  fontColor: PropTypes.string,
  contentWidth: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  closeOnclick: PropTypes.bool,
};

Action.defaultProps = {
  label: '',
  position: 'right',
  icon: 'FaEllipsisV',
  iconSize: 15,
  buttonWidth: '',
  color: '#424242',
  fontColor: '',
  contentWidth: 'max-content',
  top: '',
  right: '',
  bottom: '',
  left: '',
  closeOnclick: true,
};
