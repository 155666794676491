import styled from 'styled-components';
import Table from '../../../components/Table';

export const Filters = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0;
  }

  .filter__text-input {
    padding: 8px;
  }

  .filter__text-input,
  .date-range-filter__input {
    appearance: none;
    background: transparent;
    border: 2px solid var(--input-border);
    border-radius: var(--input-radius);
    color: var(--primary-text);
    font-size: 14px;
    outline: none;
    height: 36px;
    width: 100%;

    &:focus {
      outline: 1px auto var(--input-focus);
    }

    @media screen and (max-width: 720px) {
      margin-right: 0px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  .action {
    cursor: pointer;
    margin-left: 12px;

    :hover {
      opacity: 0.7;
    }
  }
`;

export const CustomTable = styled(Table)`
  thead th {
    font-size: 13px !important;
    line-height: 14px !important;
    padding-bottom: 2px !important;
  }

  tbody td {
    font-size: 13px !important;
    padding: 8px 5px !important;
    line-height: 13px !important;
  }
`;
