import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: ${({ width }) => width || '100%'};
  text-align: center;

  .action__button {
    cursor: pointer;
    display: flex;
    width: 100%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;

    .is-disabled {
      cursor: not-allowed !important;
      opacity: 0.5;

      & > a {
        display: inline-block; /* For IE11/ MS Edge bug */
        pointer-events: none;
        text-decoration: none;
      }
    }
  }

  .action__label {
    color: #fff;
    padding: 0 5px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  position: absolute;
  word-break: break-word;
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};

  width: ${({ contentWidth }) => contentWidth || 'max-content'};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 12;
  border-radius: 3px;

  & > a,
  & > button {
    padding: 0.6rem 1.2rem;
    width: 100%;
    color: #424242;
    font-weight: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    &:hover {
      background: #00000013;
      cursor: pointer;
    }

    svg {
      margin-right: 5px;
    }

    :first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    :last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`;
