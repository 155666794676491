import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content, AnimationContainer } from './styles';
import { Banner } from '../styles';

import { resetPasswordRequest } from '../../../store/modules/password/actions';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .required('Senha é obrigatória'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Senhas não combinam')
    .required('Confirmação de senha obrigatória'),
});

export default function RecoveryPassword({ location }) {
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const dispatch = useDispatch();

  const loading = useSelector(state => state.password.loading);

  function handleSignUp({ password, password_confirmation }) {
    dispatch(resetPasswordRequest({ token, password, password_confirmation }));
  }

  return (
    <Container>
      <Banner>
        <h1>Logenpenho</h1>
        <h2>Resetar a senha</h2>
      </Banner>
      <Content>
        <AnimationContainer>
          <h1>Cadastrar nova senha</h1>

          <Formik
            initialValues={{ password: '', password_confirmation: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleSignUp(values)}
          >
            {({ handleSubmit, errors, setFieldValue, touched, values }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  type="password"
                  name="password"
                  label="Nova senha"
                  value={values.password}
                  error={errors.password && touched.password}
                  errorMessage={errors.password}
                  onChange={({ target }) =>
                    setFieldValue('password', target.value)
                  }
                />

                <Input
                  type="password"
                  name="password_confirmation"
                  label="Confirme a senha"
                  value={values.password_confirmation}
                  error={
                    errors.password_confirmation &&
                    touched.password_confirmation
                  }
                  errorMessage={errors.password_confirmation}
                  onChange={({ target }) =>
                    setFieldValue('password_confirmation', target.value)
                  }
                />

                <Button
                  type="submit"
                  label="Salvar nova senha"
                  color="primary"
                  loading={loading}
                />
              </form>
            )}
          </Formik>
        </AnimationContainer>
      </Content>
    </Container>
  );
}

RecoveryPassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
