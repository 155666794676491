import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.4rem;
  padding-left: 10px;

  span {
    color: var(--secondary-text);
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 12px;
    text-align: left;
  }

  p {
    color: var(--primary-text);
    font-size: 14px;
    word-break: break-word;
  }
`;
