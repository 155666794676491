import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';

import DetailsInfo from '../../../components/DetailsInfo';

import formatCpfCnpj from '../../../helpers/formatCpfCnpj';

function Secretario({ invoice }) {
  const getInfo = useCallback(
    info => {
      const { fundo, destiny, justification, provider } = invoice;
      const {
        zipcode,
        city,
        state,
        address,
        street_number,
        neighborhood,
        document_number,
        phone,
      } = provider;

      const invoiceData = {
        fundo: fundo && fundo.name,
        fundo_cnpj: fundo && formatCpfCnpj(fundo.cnpj),
        destiny,
        justification,
        providerAddress: `${zipcode}. ${city}-${state}. ${address}, ${street_number}. ${neighborhood}`,
        providerDocument: formatCpfCnpj(document_number),
        providerPhone: phone,
        default: '---',
      };

      return invoiceData[info] || invoiceData.default;
    },
    [invoice]
  );

  return (
    <div>
      <h4 className="title">Solicitante</h4>
      <Row>
        <Col md={6}>
          <DetailsInfo>
            <span>Fundo</span>
            <p>{getInfo('fundo')}</p>
          </DetailsInfo>
        </Col>
        <Col md={6}>
          <DetailsInfo>
            <span>Destino</span>
            <p>{getInfo('destiny')}</p>
          </DetailsInfo>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <DetailsInfo>
            <span>Justificativa</span>
            <p>{getInfo('justification')}</p>
          </DetailsInfo>
        </Col>
      </Row>

      <h4 className="title">Fornecedor</h4>
      <Row>
        <Col md={4}>
          <DetailsInfo>
            <span>Endereço</span>
            <p>{getInfo('providerAddress')}</p>
          </DetailsInfo>
        </Col>

        <Col md={4}>
          <DetailsInfo>
            <span>CPF/CNPJ</span>
            <p>{getInfo('providerDocument')}</p>
          </DetailsInfo>
        </Col>
        <Col md={4}>
          <DetailsInfo>
            <span>Telefone para contato</span>
            <p>{getInfo('providerPhone')}</p>
          </DetailsInfo>
        </Col>
      </Row>
    </div>
  );
}

Secretario.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default Secretario;
