import React from 'react';

import { InputLabel } from '../../styles/containers';

import { CustomSelect } from './styles';

const CustomSelectComponent = ({ label, ...props }) => {
  return (
    <InputLabel>
      {label && <span className="label">{label}</span>}

      <CustomSelect
        classNamePrefix="react-select"
        // menuIsOpen
        {...props}
      />
    </InputLabel>
  );
};

export default CustomSelectComponent;
