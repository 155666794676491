import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DateRangeFilter from '../../../../components/DateRangeFilter';
import ReactSelect from '../../../../components/ReactSelect';

import FilterTags from './FilterTags';
import SelectFilters from './SelectFilters';

import * as S from './styles';

const Filters = ({ filters, onChangeWithDebounce, onChange }) => {
  const [filtersData, setFiltersData] = useState({
    type: '',
    priority: '',
    location: '',
    status: '',
    user_id: '',
    fundo_id: '',
    search: '',
    start_date: '',
    end_date: '',
  });
  const [selectedFundo, setSelectedFundo] = useState({ label: '', value: '' });
  const [clearDateRange, setClearDateRange] = useState(false);

  const fundos = useSelector(state => state.fundo.allFundos);

  useEffect(() => {
    setFiltersData(filters);
  }, [filters]);

  const fundoOptions = useMemo(() => {
      return fundos.map(fundo => ({
      value: fundo.id,
      label: fundo.name,
    }));
  }, [fundos])

  useEffect(() => {
    if (filtersData && fundoOptions) {
      const findFundo = fundoOptions.find(
        fundo => fundo.value === filtersData.fundo_id
      );
      setSelectedFundo(findFundo || null);
    }
  }, [filtersData, fundoOptions]);

  useEffect(() => {
    if (!filtersData.start_date || !filtersData.end_date) {
      setClearDateRange(true);
    } else {
      setClearDateRange(false);
    }
  }, [filtersData]);

  const handleChangeWithDebounce = filter => {
    setFiltersData({ ...filtersData, ...filter });
    onChangeWithDebounce(filter);
  };

  const handleChange = filter => {
    setFiltersData({ ...filtersData, ...filter });
    onChange(filter);
  };

  const handleDeleteFilter = filter => {
    setFiltersData({ ...filtersData, ...filter });
    onChange(filter);
  };

  const handleResetFilters = () => {
    const initialFiltersState = {
      type: '',
      priority: '',
      location: '',
      status: '',
      user_id: '',
      fundo_id: '',
      search: '',
      start_date: '',
      end_date: '',
    };
    setFiltersData(initialFiltersState);
    onChange(initialFiltersState);
  };

  return (
    <>
      <S.Filters>
        <div className="grid grid-cols-1 lg_grid-cols-12 gap-2">
          <input
            onChange={e => handleChangeWithDebounce({ search: e.target.value })}
            value={filtersData.search}
            placeholder="Buscar"
            className="col-span-12 sm_col-span-6 filter__text-input"
            type="text"
          />

          <div className="col-span-12 sm_col-span-3">
            <ReactSelect
              value={selectedFundo}
              onChange={option =>
                handleChange({ fundo_id: option ? option.value : '' })
              }
              options={fundoOptions || []}
              placeholder="Filtrar pelo fundo"
              isClearable
              maxHeightMenuList="200px"
            />
          </div>

          <div className="col-span-12 sm_col-span-3 grid grid-cols-12 gap-4">
            <div className="col-span-11">
              <DateRangeFilter
                onChangeValue={range => handleChange(range)}
                className="mb-12"
                clear={clearDateRange}
              />
            </div>

            <div className="col-span-1 mb-12 d-flex justify-end">
              <SelectFilters
                onSelect={({ type, priority, status, location }) =>
                  handleChange({ type, priority, status, location })
                }
                values={{
                  type: filtersData.type,
                  priority: filtersData.priority,
                  location: filtersData.location,
                  status: filtersData.status,
                }}
                buttonWidth="40px"
              />
            </div>
          </div>
        </div>
      </S.Filters>

      <FilterTags
        filters={filtersData}
        onDelete={handleDeleteFilter}
        selectedFundo={selectedFundo}
        onClearAll={handleResetFilters}
      />
    </>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    type: PropTypes.string,
    priority: PropTypes.string,
    location: PropTypes.string,
    user_id: PropTypes.string,
    fundo_id: PropTypes.string,
    search: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  onChangeWithDebounce: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Filters;
