import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

import Chats from './Chats';
import Contacts from './Contacts';
import Messenger from './Messenger';

import {
  Container,
  ContainerMenu,
  Tabs,
  CustomButton,
  ButtonToggleNav,
} from './styles';

export default function Chat() {
  const [tabActive, setTabActive] = useState('chats');
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const menuRef = useRef();

  const { id: chatId } = useParams();
  const location = useLocation();
  const history = useHistory();

  function handleClickOutside(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsNavExpanded(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const tab = query.get('tab') ? query.get('tab') : 'chats';
    setTabActive(tab);
  }, [location]);

  const handleLoadContactsTab = useCallback(() => {
    const contactsUrl = chatId
      ? `/chat/${chatId}?tab=contacts`
      : `/chat?tab=contacts`;

    history.push(contactsUrl);
  }, [history, chatId]);

  const handleLoadChatsTab = useCallback(() => {
    const chatUrl = chatId ? `/chat/${chatId}` : '/chat';

    history.push(chatUrl);
  }, [history, chatId]);

  return (
    <Container>
      <ContainerMenu isExpanded={isNavExpanded} ref={menuRef}>
        <Tabs>
          <CustomButton
            onClick={() => handleLoadChatsTab()}
            active={tabActive === 'chats'}
          >
            Mensagens
          </CustomButton>

          <CustomButton
            onClick={() => handleLoadContactsTab()}
            label="Contatos"
            active={tabActive === 'contacts'}
          >
            Contatos
          </CustomButton>
        </Tabs>

        {tabActive === 'chats' && (
          <Chats chatId={chatId} onChatClick={() => setIsNavExpanded(false)} />
        )}
        {tabActive === 'contacts' && (
          <Contacts onContactClick={() => setIsNavExpanded(false)} />
        )}
      </ContainerMenu>

      <ButtonToggleNav
        onClick={() => setIsNavExpanded(!isNavExpanded)}
        className="button--open"
      >
        <FaAngleRight size={22} />
      </ButtonToggleNav>

      <Messenger chatId={chatId} />
    </Container>
  );
}
