import styled from 'styled-components';

import colors from '../../styles/colors';
import { BoxShadow } from '../../styles/variables';

export const Container = styled.div`
  background: ${colors.backgroundDark};
  height: 56px;
  padding: 0 1.4rem;
  color: ${colors.textLight};
  box-shadow: ${BoxShadow.header};
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  left: 0;
  z-index: 2000;
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1340px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const MenuBurguer = styled.button`
  display: inline-block;
  cursor: pointer;
  padding: 10px;

  & > div {
    width: 25px;
    height: 3px;
    background-color: ${colors.backgroundDefault};
    margin: 5px 0;
    border-radius: 3px;

    transition: all 0.4s ease;
  }

  &:hover {
    background-color: rgba(60, 64, 67, 0.078);
    outline: none;
    border-radius: 50%;
  }

  @media screen and (min-width: 880px) {
    display: none;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;

  .logo {
    display: none;
    color: #fafafa;
  }

  @media screen and (min-width: 880px) {
    .logo {
      display: flex;
    }
  }
`;

export const Aside = styled.aside`
  display: flex;
  align-items: center;

  .aside__help-link {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
`;
