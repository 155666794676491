import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  user: {},
  users: [],
  loadingList: true,
  loadingCreate: false,
  loadingShow: true,
  loadingUpdate: false,
  loadingDelete: false,
  isOpenedModal: '',
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.LIST_REQUEST: {
        draft.loadingList = true;
        break;
      }
      case types.LIST_SUCCESS: {
        draft.users = action.payload.users;
        draft.loadingList = false;
        break;
      }
      case types.LIST_FAILURE: {
        draft.loadingList = false;
        break;
      }

      case types.SEARCH_REQUEST: {
        draft.loadingList = true;
        break;
      }
      case types.SEARCH_SUCCESS: {
        draft.users = action.payload.users;
        draft.loadingList = false;
        break;
      }
      case types.SEARCH_FAILURE: {
        draft.loadingList = false;
        break;
      }

      case types.CREATE_REQUEST: {
        draft.loadingCreate = true;
        break;
      }
      case types.CREATE_SUCCESS: {
        draft.loadingCreate = false;
        break;
      }
      case types.CREATE_FAILURE: {
        draft.loadingCreate = false;
        break;
      }

      case types.SHOW_REQUEST: {
        draft.loadingShow = true;
        break;
      }
      case types.SHOW_SUCCESS: {
        draft.user = action.payload.user;
        draft.loadingShow = false;
        break;
      }
      case types.SHOW_FAILURE: {
        draft.loadingShow = false;
        break;
      }
      case types.RESET_LOADING_SHOW: {
        draft.loadingShow = true;
        break;
      }

      case types.UPDATE_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_SUCCESS: {
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.DELETE_REQUEST: {
        draft.loadingDelete = true;
        break;
      }
      case types.DELETE_SUCCESS: {
        draft.loadingDelete = false;
        draft.isOpenedModal = '';
        break;
      }
      case types.DELETE_FAILURE: {
        draft.loadingDelete = false;
        break;
      }

      case types.SET_IS_OPENED_MODAL: {
        draft.isOpenedModal = action.payload;
        break;
      }

      default:
    }
  });
}
