import styled, { css } from 'styled-components';

export const ContainerContacts = styled.div`
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b3b3b3;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s ease;
  border-radius: 12px;

  :hover {
    background: var(--blue-100);
  }

  strong {
    color: var(--primary-text);
    display: flex;
    margin-bottom: 2px;
  }

  span {
    font-size: 12px;
    color: var(--secondary-text);
  }

  ${props =>
    props.active &&
    css`
      background-color: var(--blue-100);

      strong {
        color: var(--primary-color);
      }

      span {
        color: var(--blue-500);
      }
    `};
`;
