import styled, { css } from 'styled-components';

import Button from '../../../components/Button';

export const StartChatMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
  text-align: center;
  color: #727981;
  font-size: 14px;

  svg {
    margin-bottom: 3px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 12px;
  position: relative;
`;

export const ReceiverInfo = styled.section`
  width: 100%;
  box-shadow: 0 0px 1px 0 rgba(60, 64, 67, 0.3),
    0 1px 0px 1px rgba(60, 64, 67, 0.15);
  background: #fff;

  padding: 16px 64px 16px;
  height: 64px;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    margin-right: 6px;
    object-fit: cover;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  strong {
    color: var(--primary-text);
  }

  @media screen and (min-width: 580px) {
    padding: 16px 24px;
  }
`;

export const OnlineInfo = styled.span`
  color: var(--secondary-text);
  font-size: 12px;
  margin-top: 2px;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${props =>
      props.isOnline ? 'var(--green-800)' : 'var(--red-700)'};
    border-radius: 50%;
    left: 38px;
    top: 3px;
  }
`;

export const ContainerMessages = styled.section`
  width: 100%;
  overflow-y: auto;
  padding: 12px;
  margin-top: 48px;

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #b3b3b3;
  }
`;

export const ContainerMessage = styled.div`
  width: 100%;
  display: flex;

  ${props =>
    !props.isYourMessage &&
    css`
      justify-content: flex-start;
    `}

  ${props =>
    props.isYourMessage &&
    css`
      justify-content: flex-end;
    `}
`;

export const Message = styled.div`
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    padding: 8px 12px;
    border-radius: 12px;
    word-break: break-all;
    font-size: 14px;
    text-align: left;
    margin-bottom: 3px;
    max-width: 90%;

    @media screen and (min-width: 580px) {
      max-width: 60%;
    }
  }

  time {
    color: #ababab;
    font-size: 11px;
    margin: 0 6px;
  }

  ${props =>
    !props.isYourMessage &&
    css`
      span {
        background: #f2f3f5;
        color: #4e5560;
        border-radius: 18px 18px 18px 2px;
      }
    `}

  ${props =>
    props.isYourMessage &&
    css`
      align-items: flex-end;

      span {
        background: var(--blue-500);
        color: #fff;
        border-radius: 18px 18px 3px 18px;
      }
    `}
`;

export const Sender = styled.form`
  margin-top: 18px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  padding: 18px 24px;
  display: flex;
  width: 100%;

  textarea {
    border: none;
    height: 42px;
    width: 100%;
    border-radius: 3px;
    resize: none;
  }
`;

export const CustomButton = styled(Button)`
  background: var(--primary-color);
  color: var(--primary-color-contrast);
  padding: 8px 12px;
  margin-left: 12px;
  border-radius: 3px;
  min-width: 80px;
`;
