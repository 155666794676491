import styled from 'styled-components';

export const Container = styled.div`
  padding: 2.2rem 2.8rem;
  border-radius: 4px;
  background: #fff;
  position: relative;

  > h3 {
    margin-bottom: 1.8rem;
    font-size: 1.6rem;
    font-weight: 500;
  }

  > h4 {
    font-size: 1.4rem;
    color: #65696c;
    margin-bottom: 1rem;
  }

  div {
    padding-left: 0;

    > span {
      font-size: 12px;
    }
    > p {
      font-size: 14px;
    }
  }

  div .amount {
    padding-left: 12px;
    margin-top: 12px;
  }

  .table {
    margin-bottom: 0;
  }

  table {
    margin-bottom: 6px !important;
  }

  th,
  td {
    font-size: 12px !important;
    padding: 1px 2px !important;
    color: #65696c !important;
  }

  &::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    content: '';
    display: block;
    height: 6px;
    background-color: transparent;
    background-size: 17px 17px;
    background-position: 0 0px, 40px 40px;
    background-image: radial-gradient(closest-side, transparent 99%, #fff);
  }
`;
