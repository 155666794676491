import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-grid-system';

import ActivityIndicator from '../../../components/ActivityIndicator';
import InvoiceSummary from '../../../components/InvoiceSummary';
import Card from '../../../components/Card';
import Form from './Form';

import {
  showInvoiceRequest,
  updateStep3Request,
  resetLoadingShowInvoice,
} from '../../../store/modules/invoice/actions';

export default function Step3Contabilidade({ match }) {
  const { invoice, loadingUpdate, loadingShow } = useSelector(
    state => state.invoice
  );

  const dispatch = useDispatch();
  const invoiceId = match.params.id;

  useEffect(() => {
    dispatch(showInvoiceRequest(invoiceId));

    return () => dispatch(resetLoadingShowInvoice());
  }, [dispatch, invoiceId]);

  function handleUpdate(values) {
    const payload = {
      ...values,
      id: invoice.id,
    };
    dispatch(updateStep3Request(payload));
  }

  return (
    <>
      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <>
          <Row>
            <Col>
              <Card>
                <Form
                  data={invoice}
                  loading={loadingUpdate}
                  onSubmit={values => handleUpdate(values)}
                  isEditable
                />
              </Card>
            </Col>
            <Col>
              <InvoiceSummary invoice={invoice} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

Step3Contabilidade.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
