const Types = {
  UPDATE_REQUEST: '@profile/UPDATE_REQUEST',
  UPDATE_SUCCESS: '@profile/UPDATE_SUCCESS',
  UPDATE_FAILURE: '@profile/UPDATE_FAILURE',

  UPDATE_AVATAR_REQUEST: '@profile/UPDATE_AVATAR_REQUEST',
  UPDATE_AVATAR_SUCCESS: '@profile/UPDATE_AVATAR_SUCCESS',
  UPDATE_AVATAR_FAILURE: '@profile/UPDATE_AVATAR_FAILURE',
};

export default Types;
