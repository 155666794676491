import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-grid-system';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Input from '../../components/Input';

export default function ModalFormProduct({
  initialData, 
  isOpen,
  onClose,
  onSubmit,
  loading,
}) {
  const [data, setData] = useState({ name: ''})

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData])  

  const handleInputChange = event => {
    const { value } = event.target;
    setData(prevState => ({ ...prevState, name: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if(data.name) {
      onSubmit(data)
    }
  }

  return (
    <Modal
      title={initialData ? "Editar produto" : 'Adicionar produto'}
      open={isOpen}
      width={{ width: '400px' }}
      closeAction={onClose}
    >      
      <form onSubmit={handleSubmit}>
        <Input 
          label="Nome" 
          value={data.name}
          onChange={handleInputChange} 
        />     
      </form>
      <Row justify="end">
        <Button
          label="Cancelar"
          variant="outlined"
          color="black"
          height="34px"
          onClick={onClose}
          width="100px"
        />
        <Button
          className="ml-12 font-medium"
          label="Salvar"
          type="submit"
          height="34px"
          width="100px"
          loading={loading}
          onClick={handleSubmit}
        />
      </Row>
    </Modal>
  );
}

ModalFormProduct.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ModalFormProduct.defaultProps = {
  initialData: null,
  loading: false,
};
