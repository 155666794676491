import styled from 'styled-components';
import { darken, lighten } from 'polished';
import styledBy from 'styled-by';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  font-size: 14px;
  border-radius: 3px;
  padding: 0 15px;
  transition: 180ms ease-in-out;
  cursor: pointer;

  ${styledBy('variant', {
    contained: ({ color }) => `
      background: ${color.primary};
      color: ${color.secondary};
      border: none;
      :hover {
        background: ${darken(0.05, color.primary)};
      }   
      .rai-spinner-outer {
        border-color: ${lighten(0.025, color.secondary)}!important;
      }      
      .rai-spinner-inner {
        border-color: ${darken(0.08, color.secondary)};
      }      
    `,
    outlined: ({ color }) => `
      background: transparent;
      color: ${color.primary};
      border: 1px solid ${color.primary};
      .rai-spinner-outer {
        border-color: ${lighten(0.025, color.primary)}!important;
      }      
      .rai-spinner-inner {
        border-color: ${darken(0.08, color.primary)};
      }
      // :hover {
      //   background: ${darken(0.02, color.primary)}; 
      //   color: #fff;        
      //   .rai-spinner-outer {
      //     border-color: #ddd!important;
      //   }      
      //   .rai-spinner-inner {
      //     border-color:${lighten(0.5, color.primary)};
      //   }
      // }
    `,
    clean: ({ color }) => `
      background: transparent;
      color: ${darken(0.04, color.primary)};
      border: none;
      .rai-spinner-outer {
        border-color: ${darken(0.05, color.primary)}!important;
      }      
      .rai-spinner-inner {
        border-color: ${darken(0.08, color.primary)};
      }
      :hover {
        background: #fafafa;
      }
    `,
  })};

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  color: ${({ customColor }) => customColor};
  background-color: ${({ customBackground }) => customBackground};

  .rai-spinner-outer {
    border-color: ${({ customColor }) =>
      customColor && darken(0.05, customColor)}!important;
  }
  .rai-spinner-inner {
    border-color: ${({ customColor }) =>
      customColor && darken(0.08, customColor)};
  }

  :hover {
    background-color: ${({ customBackground }) =>
      customBackground && `${darken(0.02, customBackground)}`};
  }

  .button__icon {
    margin-left: ${({ label }) => label && '10px'};
    display: ${({ loading }) => (loading ? 'none' : 'flex')};
  }

  .button__label {
    display: ${({ loading }) => (loading ? 'none' : 'flex')};
  }
`;
