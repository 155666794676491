import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { debounce } from 'lodash';

import Button from '../../components/Button';
import Table from '../../components/Table';
import Card from '../../components/Card';
import Paginate from '../../components/Paginate';
import { CustomInput } from '../../components/Input/styles';

import { getUserType } from '../../helpers/translate';

import { searchUsersRequest } from '../../store/modules/users/actions';

export default function List() {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 8,
    search: '',
  });

  const { loadingList, users: userData } = useSelector(({ users }) => users);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(searchUsersRequest({ filters }));
  }, [dispatch, filters]);

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Tipo',
        accessor: 'type',
        Cell: ({ cell }) => getUserType(cell.value),
      },
    ],
    []
  );

  const handleChangeFilter = filter => {
    setFilters(oldState => ({ ...oldState, ...filter }));
  };

  const handleSearchWithDebounce = useRef(
    debounce(filter => {
      handleChangeFilter({ ...filter, page: 1 });
    }, 500)
  ).current;

  const getRowProps = rowInfo => {
    const {
      original: { id },
    } = rowInfo;

    return {
      title: 'Ver detalhes',
      onClick: () => history.push(`/user/${id}`),
    };
  };

  return (
    <Card>
      <div className="card__header">
        <h3>Usuários</h3>

        <Link to="/user/new">
          <Button
            color="primary"
            variant="outlined"
            label="Adicionar"
            height="3.4rem"
            className="font-medium"
          />
        </Link>
      </div>

      <CustomInput
        height="36px"
        placeholder="Buscar"
        mask={false}
        onChange={e => handleSearchWithDebounce({ search: e.target.value })}
        className="mb-12"
      />

      <Row>
        <Col>
          <Table
            columns={columns}
            data={(userData && userData.users) || []}
            loading={loadingList}
            getRowProps={getRowProps}
            rowTitle="Ver detalhes"
            className="table__row--clickable"
          />

          <Paginate
            records={(userData && userData.totalCount) || 0}
            current={filters.page}
            limit={filters.per_page}
            onChange={page => handleChangeFilter({ page })}
            delta={1}
            fixed={1}
          />
        </Col>
      </Row>
    </Card>
  );
}

List.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
};

List.defaultProps = {
  cell: {},
};
