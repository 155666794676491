import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { FullScreen, Children } from './styles';

export default function Modal({ open, width, title, children, closeAction }) {
  const modal = useRef();

  // Handle the key press close modal on 'ESC' event.
  const handleKeyUp = useCallback(
    e => {
      const keys = {
        27: () => {
          e.preventDefault();
          window.removeEventListener('keyup', handleKeyUp, false);

          if (closeAction) {
            closeAction();
          }
        },
      };

      if (keys[e.keyCode]) {
        keys[e.keyCode]();
      }
    },
    [closeAction]
  );

  const handleOutsideClick = useCallback(
    e => {
      if (modal.current.parentNode === e.target) {
        document.removeEventListener('click', handleOutsideClick, false);
        if (closeAction) {
          closeAction();
        }
      }
    },
    [closeAction]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleKeyUp, handleOutsideClick, open]);

  return (
    <FullScreen className="modal" open={open ? 1 : 0}>
      <Children ref={modal} style={width}>
        <header>
          <h3 className="modal__title">{title}</h3>
        </header>
        {children}
      </Children>
    </FullScreen>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  closeAction: PropTypes.func,
  width: PropTypes.objectOf(PropTypes.any),
};

Modal.defaultProps = {
  closeAction: null,
  width: '5.5rem',
};
