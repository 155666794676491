import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  provider: {},
  allProviders: [],
  providers: null,
  loadingFetch: true,
  loadingCreate: false,
  loadingShow: true,
  loadingUpdate: false,
  loadingDownload: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.LIST_REQUEST: {
        draft.loadingFetch = true;
        break;
      }
      case types.LIST_SUCCESS: {
        draft.allProviders = action.payload.providers;
        draft.loadingFetch = false;
        break;
      }
      case types.LIST_FAILURE: {
        draft.loadingFetch = false;
        break;
      }

      case types.SEARCH_REQUEST: {
        draft.loadingFetch = true;
        break;
      }
      case types.SEARCH_SUCCESS: {
        draft.providers = action.payload.providers;
        draft.loadingFetch = false;
        break;
      }
      case types.SEARCH_FAILURE: {
        draft.loadingFetch = false;
        break;
      }

      case types.SHOW_REQUEST: {
        draft.loadingShow = true;
        break;
      }
      case types.SHOW_SUCCESS: {
        draft.provider = action.payload.provider;
        draft.loadingShow = false;
        break;
      }
      case types.SHOW_FAILURE: {
        draft.loadingShow = false;
        break;
      }
      case types.RESET_LOADING_SHOW: {
        draft.loadingShow = true;
        break;
      }

      case types.CREATE_REQUEST: {
        draft.loadingCreate = true;
        break;
      }
      case types.CREATE_SUCCESS: {
        draft.provider = action.payload.provider;
        draft.loadingCreate = false;
        break;
      }
      case types.CREATE_FAILURE: {
        draft.loadingCreate = false;
        break;
      }

      case types.UPDATE_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_SUCCESS: {
        draft.provider = action.payload.provider;
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.DOWNLOAD_REPORT_REQUEST: {
        draft.loadingDownload = true;
        break;
      }
      case types.DOWNLOAD_REPORT_SUCCESS: {
        draft.loadingDownload = false;
        break;
      }
      case types.DOWNLOAD_REPORT_FAILURE: {
        draft.loadingDownload = false;
        break;
      }

      default:
    }
  });
}
