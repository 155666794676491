import styled from 'styled-components';

export const ContainerInput = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

export const CustomTextArea = styled.textarea`
  appearance: none;
  background: var(--input-background);
  border: 2px solid var(--input-border);
  border-radius: var(--input-radius);
  color: var(--primary-text);
  font-size: 14px;
  padding: 8px;
  position: relative;
  outline: none;
  height: 36px;

  width: 100%;
  height: ${({ height }) => height};

  outline: ${({ error }) => error && `1px auto var(--input-border-error)`};

  &:disabled {
    background: var(--input-disabled);
  }

  &:focus {
    outline: ${({ error }) =>
      error
        ? '1px auto var(--input-border-error)'
        : '1px auto var(--input-focus)'};
  }
`;
