import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaFileAlt, FaBarcode, FaTruck, FaMoneyCheckAlt, FaUsers } from 'react-icons/fa';
import { MdMessage } from 'react-icons/md';

import { Container, ItemList, Item } from './styles';

export default function SideNavCustom({
  stateSideNav,
  sideNavRef,
  onLinkClick,
}) {
  const { profile: user } = useSelector(({ profile }) => profile);

  const { pathname } = useLocation();

  const links = [
    {
      id: 1,
      label: 'Notas',
      icon: <FaFileAlt size={18} color="#424242" />,
      to: '/invoices',
      active: pathname.includes('invoice'),
    },
    {
      id: 2,
      label: 'Produtos',
      icon: <FaBarcode size={18} color="#424242" />,
      to: '/products',
      active: pathname.includes('product'),
    },
    {
      id: 3,
      label: 'Fornecedores',
      icon: <FaTruck size={18} color="#424242" />,
      to: '/providers',
      active: pathname.includes('provider'),
    },
    {
      id: 4,
      label: 'Fundos',
      icon: <FaMoneyCheckAlt size={18} color="#424242" />,
      to: '/fundos',
      active: pathname.includes('fundo'),
    },
    {
      id: 5,
      label: 'Usuários',
      icon: <FaUsers size={18} color="#424242" />,
      to: '/users',
      active: pathname.includes('user'),
      auth: ['prefeito'],
    },
    {
      id: 6,
      label: 'Mensagens',
      icon: <MdMessage size={18} color="#424242" />,
      to: '/chat',
      active: pathname.includes('chat'),
    },
  ];

  return (
    <Container ref={sideNavRef} expanded={stateSideNav}>
      <ItemList>
        {links.map(link => {
          if (user && link.auth && !link.auth.includes(user.type)) return '';

          return (
            <Item
              key={link.id}
              className={link.active ? 'active' : ''}
              to={link.to}
              onClick={onLinkClick}
            >
              <span className="icon">{link.icon}</span>
              {link.label}
            </Item>
          );
        })}
      </ItemList>
    </Container>
  );
}

SideNavCustom.propTypes = {
  stateSideNav: PropTypes.bool.isRequired,
  sideNavRef: PropTypes.object.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};
