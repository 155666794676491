import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import history from '../../../services/history';

import apiProvider from '../../../services/core/http/provider';

import {
  listProvidersSuccess,
  listProvidersFailure,
  searchProvidersSuccess,
  searchProvidersFailure,
  showProviderSuccess,
  showProviderFailure,
  createProviderSuccess,
  createProviderFailure,
  updateProviderSuccess,
  updateProviderFailure,
  downloadProviderReportSuccess,
  downloadProviderReportFaliure
} from './actions';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import downloadFile from '../../../helpers/downloadFile';

export function* listProvidersWorker() {
  try {
    const response = yield call(apiProvider.get);

    yield put(listProvidersSuccess({ providers: response.data }));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(listProvidersFailure());
  }
}

export function* searchProvidersWorker({ payload }) {
  try {
    const { filters } = payload;

    const response = yield call(apiProvider.search, filters);

    const totalCount = Number(response.headers['x-total-count']);
    const responseWithCount = { providers: response.data, totalCount };

    yield put(searchProvidersSuccess(responseWithCount));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(searchProvidersFailure());
  }
}

export function* showProviderWorker({ payload }) {
  try {
    const response = yield call(apiProvider.show, payload);

    yield put(showProviderSuccess(response));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    history.push('/providers');
    yield put(showProviderFailure());
  }
}

export function* createProviderWorker({ payload }) {
  try {
    const response = yield call(apiProvider.store, payload.provider);

    yield put(createProviderSuccess(response.data));

    toast.success('Fornecedor adicionado com sucesso!');
    history.push('/providers');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(createProviderFailure());
  }
}

export function* updateProviderWorker({ payload }) {
  try {
    const response = yield call(apiProvider.update, payload.provider);

    yield put(updateProviderSuccess(response.data));

    toast.success('Fornecedor editado com sucesso!');
    history.push('/providers');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(updateProviderFailure());
  }
}

export function* downloadReportWorker({ payload }) {
  try {
    yield put(commonLoadingStart());

    const response = yield call(apiProvider.downloadReport, payload);

    downloadFile({
      type: 'application/pdf',
      fileName: 'relatorio',
      file: response.data,
    });

    yield put(downloadProviderReportSuccess());
  } catch ({ response }) {
    toast.warn(
      (response && response.data.message) || 'Erro ao gerar relatório'
    );

    yield put(downloadProviderReportFaliure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.LIST_REQUEST, listProvidersWorker),
  takeLatest(types.SEARCH_REQUEST, searchProvidersWorker),
  takeLatest(types.SHOW_REQUEST, showProviderWorker),
  takeLatest(types.CREATE_REQUEST, createProviderWorker),
  takeLatest(types.UPDATE_REQUEST, updateProviderWorker),
  takeLatest(types.DOWNLOAD_REPORT_REQUEST, downloadReportWorker),
]);
