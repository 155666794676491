import styled from 'styled-components';

export const FullScreen = styled.div`
  display: ${p => (p.open === 1 ? 'flex' : 'none')};

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000b3;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
  animation: fadeIn 380ms ease-in-out 1;

  @keyframes fadeIn {
    from {
      background-color: $gray-86;
    }
    to {
      background-color: #000000cc;
    }
  }
`;

export const Children = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  cursor: default;
  -webkit-animation: slideIn 350ms cubic-bezier(0.42, 0, 0.21, 1) 1;
  animation: slideIn 350ms cubic-bezier(0.42, 0, 0.21, 1) 1;

  @keyframes slideIn {
    from {
      transform: translateY(-120px);
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .modal__title {
    margin-bottom: 1.6rem;
  }
`;
