import styled from 'styled-components';
import { shade } from 'polished';

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  width: 150px;
  height: 150px;
  justify-content: center;
  margin: 0 auto 24px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #ff9000;
    color: #fff;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 0.2s;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #312e38;
    }

    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;
