import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FormProduct from '../../../../components/FormProduct';
import ProductList from '../../../../components/ProductList';

import {
  createProductRequest,
  updateProductRequest,
  deleteProductRequest,
  updateTotalValue,
} from '../../../../store/modules/invoice/actions';

function ProductContainer({ invoiceId }) {
  const {
    invoice,
    loadingCreate,
    loadingProductUpdate,
    successUpdate,
    loadingDelete,
  } = useSelector(state => state.invoice);

  const dispatch = useDispatch();

  function handleAddProduct(values) {
    const payload = { invoice_id: invoiceId, ...values };
    dispatch(createProductRequest(payload));

    /**
     * Reseto o formulário manualmente pois não tava conseguindo
     * resetar chamando o método 'resetForm()' do formik
     */
    document.querySelector('#button-reset-form-new-product').click();
  }

  function handleRemoveProduct(id) {
    dispatch(deleteProductRequest({ id }));
  }

  function handleUpdateTotalValue(value) {
    dispatch(updateTotalValue({ total_value: value }));
  }

  function handleUpdateProduct(values) {
    dispatch(updateProductRequest(values));
  }

  return (
    <>
      <FormProduct
        onSubmit={handleAddProduct}
        loading={loadingCreate}
      />
      <ProductList
        products={(invoice && invoice.products) || []}
        totalValue={(invoice && invoice.total_value) || 0}
        handleUpdateTotalValue={handleUpdateTotalValue}
        handleRemoveProduct={handleRemoveProduct}
        handleUpdateProduct={handleUpdateProduct}
        successUpdate={successUpdate}
        loadingUpdate={loadingProductUpdate}
        loadingDelete={loadingDelete}
        isEditable
      />
    </>
  );
}

ProductContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default ProductContainer;
