import types from './types';

export const signInRequest = ({ email, password }) => ({
  type: types.SIGNIN_REQUEST,
  payload: { email, password },
});

export const signInSuccess = ({ token, user }) => ({
  type: types.SIGNIN_SUCCESS,
  payload: { token, user },
});

export const signFailure = () => ({
  type: types.SIGNIN_FAILURE,
});

export const signUpRequest = (name, email, password) => ({
  type: types.SIGNUP_REQUEST,
  payload: { name, email, password },
});

export const signOut = () => ({
  type: types.SIGNOUT_REQUEST,
});
