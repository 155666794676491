import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel, InputMessageError } from '../../styles/containers';

import { CustomField } from './styles';

const Currency = ({
  inputRef,
  name,
  onChange,
  prefix,
  sufix,
  decimalSeparator,
  thousandSeparator,
  allowNegative,
  decimalScale,
  fixedDecimalScale,
  displayType,
  width,
  height,
  className,
  label,
  error,
  errorMessage,
  ...rest
}) => {
  return (
    <InputLabel width={width} className={className}>
      {label && <span className="label">{label}</span>}
      <CustomField
        {...rest}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              name,
              value: values.floatValue,
            },
          });
        }}
        // isNumericString
        prefix={prefix}
        decimalSeparator={decimalSeparator}
        thousandSeparator={thousandSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={allowNegative}
        displayType={displayType}
        width={width}
        height={height}
        error={error ? 'true' : ''}
      />
      {error && <InputMessageError>{errorMessage}</InputMessageError>}
    </InputLabel>
  );
};

Currency.propTypes = {
  inputRef: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  sufix: PropTypes.string,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  decimalScale: PropTypes.number,
  displayType: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

Currency.defaultProps = {
  inputRef: null,
  name: '',
  onChange: () => {},
  prefix: 'R$ ',
  sufix: '',
  decimalSeparator: ',',
  thousandSeparator: '.',
  fixedDecimalScale: false,
  allowNegative: false,
  decimalScale: 3,
  displayType: 'input',
  error: '',
  errorMessage: '',
  label: '',
  width: '',
  height: '36px',
  className: '',
};

export default Currency;
