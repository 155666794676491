import api from '../../api';

import extractNumbers from '../../../helpers/extractNumbers';

export default {
  get: async () => {
    const res = await api.get(`/providers`);
    return res;
  },

  search: async filters => {
    const res = await api.get(`/providers/search`, { params: { ...filters } });
    return res;
  },

  show: async payload => {
    const { id } = payload;
    const res = await api.get(`/providers/${id}`);

    return res.data;
  },

  store: async provider => {
    const { document_number, zipcode, phone, ...rest } = provider;

    const providerFormatted = {
      ...rest,
      document_number: extractNumbers(document_number),
      zipcode: extractNumbers(zipcode),
      phone: extractNumbers(phone),
    };

    const res = await api.post(`/providers`, providerFormatted);

    return res.data;
  },

  update: async provider => {
    const {
      id,
      name,
      document_type,
      document_number,
      payment_account,
      phone,
      email,
      zipcode,
      city,
      state,
      country,
      neighborhood,
      address,
      street_number,
    } = provider;

    const providerFormatted = {
      id,
      name,
      payment_account,
      document_type,
      document_number: extractNumbers(document_number),
      phone: extractNumbers(phone),
      email,
      zipcode: extractNumbers(zipcode),
      city,
      state,
      country,
      neighborhood,
      address,
      street_number,
    };

    const res = await api.put(`/providers`, providerFormatted);

    return res.data;
  },

  downloadReport: async filters => {
    const res = await api.get(`/providers/download/report`, {
      params: { ...filters },
      responseType: 'blob',
    });

    return res;
  },
};
