import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  products: { products: [], totalCount: 0 },
  allProducts: [],
  loadingList: true,
  loadingCreate: false,
  isOpenedModal: '',
  loadingUpdate: false,
  loadingDelete: false,
};

export default function product(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.LIST_REQUEST: {
        draft.loadingList = true;
        break;
      }
      case types.LIST_SUCCESS: {
        draft.allProducts = action.payload.products;
        draft.loadingList = false;
        break;
      }
      case types.LIST_FAILURE: {
        draft.loadingList = false;
        break;
      }

      case types.SEARCH_REQUEST: {
        draft.loadingList = true;
        break;
      }
      case types.SEARCH_SUCCESS: {
        draft.products = action.payload.products;
        draft.loadingList = false;
        break;
      }
      case types.SEARCH_FAILURE: {
        draft.loadingList = false;
        break;
      }      

      case types.CREATE_REQUEST: {
        draft.loadingCreate = true;
        break;
      }
      case types.CREATE_SUCCESS: {
        draft.loadingCreate = false;
        draft.isOpenedModal = '';
        break;
      }
      case types.CREATE_FAILURE: {
        draft.loadingCreate = false;
        break;
      }

      case types.SET_IS_OPENED_MODAL: {        
        draft.isOpenedModal = action.payload;
        break;
      }

      case types.UPDATE_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_SUCCESS: {
        draft.loadingUpdate = false;
        draft.isOpenedModal = '';
        break;
      }
      case types.UPDATE_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.DELETE_REQUEST: {
        draft.loadingDelete = true;
        break;
      }
      case types.DELETE_SUCCESS: {
        draft.loadingDelete = false;
        draft.isOpenedModal = '';
        break;
      }
      case types.DELETE_FAILURE: {
        draft.loadingDelete = false;
        break;
      }

      default:
    }
  });
}
