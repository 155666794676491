import types from './types';

export const listUsersRequest = () => ({
  type: types.LIST_REQUEST,
});
export const listUsersSuccess = users => ({
  type: types.LIST_SUCCESS,
  payload: { users },
});
export const listUsersFailure = () => ({
  type: types.LIST_FAILURE,
});

export const searchUsersRequest = ({ filters }) => ({
  type: types.SEARCH_REQUEST,
  payload: { filters },
});
export const searchUsersSuccess = users => ({
  type: types.SEARCH_SUCCESS,
  payload: { users },
});
export const searchUsersFailure = () => ({
  type: types.SEARCH_FAILURE,
});

export const createUserRequest = user => ({
  type: types.CREATE_REQUEST,
  payload: { user },
});
export const createUserSuccess = () => ({
  type: types.CREATE_SUCCESS,
});
export const createUserFailure = () => ({
  type: types.CREATE_FAILURE,
});

export const showUserRequest = id => ({
  type: types.SHOW_REQUEST,
  payload: { id },
});
export const showUserSuccess = user => ({
  type: types.SHOW_SUCCESS,
  payload: { user },
});
export const showUserFailure = () => ({
  type: types.SHOW_FAILURE,
});
export const resetLoadingShow = () => ({
  type: types.RESET_LOADING_SHOW,
});

export const updateUserRequest = user => ({
  type: types.UPDATE_REQUEST,
  payload: { user },
});
export const updateUserSuccess = () => ({
  type: types.UPDATE_SUCCESS,
});
export const updateUserFailure = () => ({
  type: types.UPDATE_FAILURE,
});

export const deleteUserRequest = id => ({
  type: types.DELETE_REQUEST,
  payload: { id },
});
export const deleteUserSuccess = () => ({
  type: types.DELETE_SUCCESS,
});
export const deleteUserFailure = () => ({
  type: types.DELETE_FAILURE,
});

export const setIsOpenedModal = modalName => ({
  type: types.SET_IS_OPENED_MODAL,
  payload: modalName,
});
