import React, { useCallback } from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import DetailsInfo from '../DetailsInfo';
import ProductList from '../ProductList';

import formatCpfCnpj from '../../helpers/formatCpfCnpj';

import { Container } from './styles';

function InvoiceSummary({ invoice }) {
  const getInfo = useCallback(
    info => {
      const { requester, destiny, justification, provider } = invoice;
      const {
        name,
        zipcode,
        city,
        state,
        address,
        street_number,
        neighborhood,
        document_number,
        phone,
      } = provider;

      const invoiceData = {
        requester,
        destiny,
        justification,
        providerName: name,
        providerAddress: `${zipcode}. ${city}-${state}. ${address}, ${street_number}. ${neighborhood}`,
        providerDocument: formatCpfCnpj(document_number),
        providerPhone: phone,
        default: '---',
      };

      return invoiceData[info] || invoiceData.default;
    },
    [invoice]
  );

  const totalValue = invoice.products.reduce((subTotal, item) => {
    return subTotal + Number(item.amount);
  }, 0);

  return (
    <Container>
      <h3>Resumo da nota</h3>
      <Row>
        <Col md={6} sm={6}>
          <DetailsInfo>
            <span>Solicitante</span>
            <p>{getInfo('requester')}</p>
          </DetailsInfo>
        </Col>
        <Col md={6} sm={6}>
          <DetailsInfo>
            <span>Destino</span>
            <p>{getInfo('destiny')}</p>
          </DetailsInfo>
        </Col>
      </Row>
      <Row>
        <Col>
          <DetailsInfo>
            <span>Justificativa</span>
            <p>{getInfo('justification')}</p>
          </DetailsInfo>
        </Col>
      </Row>

      <h4>Fornecedor</h4>
      <Row>
        <Col md={6}>
          <DetailsInfo>
            <span>Nome</span>
            <p>{getInfo('providerName')}</p>
          </DetailsInfo>
        </Col>
        <Col md={6}>
          <DetailsInfo>
            <span>Endereço</span>
            <p>{getInfo('providerAddress')}</p>
          </DetailsInfo>
        </Col>

        <Col md={6} sm={6}>
          <DetailsInfo>
            <span>CPF/CNPJ</span>
            <p>{getInfo('providerDocument')}</p>
          </DetailsInfo>
        </Col>
        <Col md={6} sm={6}>
          <DetailsInfo>
            <span>Telefone para contato</span>
            <p>{getInfo('providerPhone')}</p>
          </DetailsInfo>
        </Col>
      </Row>

      <ProductList
        products={(invoice && invoice.products) || []}
        totalValue={invoice.total_value || totalValue || 0}
      />
    </Container>
  );
}

InvoiceSummary.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default InvoiceSummary;
