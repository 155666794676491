export const getStatus = status => {
  const arrayStatus = {
    nova: 'Nova',
    processada: 'Processada',
    autorizada: 'Autorizada',
    nao_autorizada: 'Não Autorizada',
    aguardando_recurso: 'Aguardando Recurso',
    finalizada: 'Finalizada',
    cancelada: 'Cancelada',
    default: '---',
  };
  return arrayStatus[status] || arrayStatus.default;
};

export const getLocation = location => {
  const arrayLocation = {
    secretaria: 'Secretaria',
    controle_interno: 'Controle Interno',
    contabilidade: 'Contabilidade',
    tesouraria: 'Tesouraria',
    default: '---',
  };

  return arrayLocation[location] || arrayLocation.default;
};

export const getInvoicePriority = priority => {
  const priorities = {
    rotina: 'Rotina',
    urgencia: 'Urgência',
    default: '---',
  };

  return priorities[priority] || priorities.default;
};

export const getInvoiceType = type => {
  const types = {
    aluguel: 'Aluguel',
    assessor: 'Assessor',
    compra_direta: 'Compra direta',
    diaria: 'Diaria',
    dispensa: 'Dispensa',
    por_licitacao: 'Por Licitação',
    recibo: 'Recibo',
    credenciamento: 'Credenciamento',
    default: '',
  };

  return types[type] || types.default;
};

export const getUserType = userValue => {
  const arrayStatus = {
    secretario: 'Secretario',
    controle_interno: 'Controle Interno',
    contabilidade: 'Contabilidade',
    tesoureiro: 'Tesoureiro',
    prefeito: 'Prefeito',
    default: '---',
  };

  return arrayStatus[userValue] || arrayStatus.default;
};

export const translate = (name = 'default') => {
  const arrayNames = {
    date_interval: 'Período',
    location: 'Localização',
    secretaria: 'Secretaria',
    controle_interno: 'Controle Interno',
    contabilidade: 'Contabilidade',
    tesouraria: 'Tesouraria',
    status: 'Status',
    nova: 'Nova',
    processada: 'Processada',
    autorizada: 'Autorizada',
    nao_autorizada: 'Não Autorizada',
    aguardando_recurso: 'Aguardando Recurso',
    finalizada: 'Finalizada',
    cancelada: 'Cancelada',
    priority: 'Prioridade',
    rotina: 'Rotina',
    urgencia: 'Urgência',
    type: 'Tipo',
    aluguel: 'Aluguel',
    assessor: 'Assessor',
    compra_direta: 'Compra direta',
    diaria: 'Diaria',
    dispensa: 'Dispensa',
    por_licitacao: 'Por Licitação',
    credenciamento: 'Credenciamento',
    recibo: 'Recibo',
    fundo_id: 'Fundo ID',
    search: 'Pesquisa global',
    default: name,
  };

  return arrayNames[name] || arrayNames.default;
};
