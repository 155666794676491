import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';

import { useDispatch, useSelector } from 'react-redux';
import { createProductUnitRequest } from '../../../store/modules/invoice/actions';

import Input from '../../Input';
import Button from '../../Button';
import Modal from '../../Modal';
import Currency from '../../Currency';
import Select from '../../Select';

const validationSchemaEdtiProduct = Yup.object().shape({
  description: Yup.string().required(' '),
  unit: Yup.string().required(' '),
  quantity: Yup.string().required(' '),
  unit_value: Yup.string().required(' '),
  amount: Yup.string().required(' '),
});

export default function ModalProductEdit({
  product,
  open,
  closeAction,
  handleUpdateProduct,
  loading,
}) {
  const { unitsToSelect } = useSelector(state => state.invoice);

  const dispatch = useDispatch();

  const handleCreateProductUnit = name => {
    dispatch(createProductUnitRequest({ name }));
  };

  const handleUpdate = values => {
    const { id, description, unit, quantity, unit_value, amount } = values;

    handleUpdateProduct({
      id,
      description,
      unit,
      quantity,
      unit_value,
      amount,
    });
  };

  return (
    <Modal
      title="Editar Produto"
      open={open}
      width={{ width: '400px', overflowY: 'auto' }}
      closeAction={closeAction}
    >
      <Formik
        initialValues={product}
        validationSchema={validationSchemaEdtiProduct}
        onSubmit={handleUpdate}
        enableReinitialize
      >
        {({ handleSubmit, values, errors, touched, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Input
                  name="description"
                  label="Descrição"
                  value={values.description}
                  error={errors.description && touched.description}
                  onChange={({ target }) =>
                    setFieldValue('description', target.value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Field
                  component={Select}
                  name="unit"
                  label="Unid."
                  isCreatable
                  onCreateOption={option => handleCreateProductUnit(option)}
                  options={unitsToSelect}
                  maxHeightMenuList="110px"
                />
              </Col>
              <Col md={6}>
                <Currency
                  label="Qtd."
                  name="quantity"
                  value={Number(values.quantity)}
                  error={errors.quantity && touched.quantity}
                  onChange={({ target }) => {
                    setFieldValue(
                      'amount',
                      Number(target.value) * Number(values.unit_value)
                    );
                    setFieldValue('quantity', target.value);
                  }}
                  prefix=""
                  fixedDecimalScale
                />
              </Col>
            </Row>

            <Row className="mb-12">
              <Col md={6}>
                <Currency
                  label="Val. unit."
                  name="unit_value"
                  value={Number(values.unit_value)}
                  error={errors.unit_value && touched.unit_value}
                  onChange={({ target }) => {
                    setFieldValue(
                      'amount',
                      Number(target.value) * Number(values.quantity)
                    );
                    setFieldValue('unit_value', target.value);
                  }}
                  fixedDecimalScale
                />
              </Col>
              <Col md={6}>
                <Currency
                  label="Total"
                  name="amount"
                  value={Number(values.amount)}
                  error={errors.amount && touched.amount}
                  disabled
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Col>
            </Row>

            <Row justify="end">
              <Button
                label="Cancelar"
                variant="clean"
                color="black"
                height="34px"
                onClick={closeAction}
                width="100px"
              />

              <Button
                label="Atualizar"
                color="success"
                variant="outlined"
                type="submit"
                height="34px"
                width="100px"
                loading={loading}
              />
            </Row>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

ModalProductEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  closeAction: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  handleUpdateProduct: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ModalProductEdit.defaultProps = {
  loading: false,
};
