import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-grid-system';

import ActivityIndicator from '../../../components/ActivityIndicator';
import InvoiceSummary from '../../../components/InvoiceSummary';
import Card from '../../../components/Card';

import Form from './Form';

import {
  showInvoiceRequest,
  updateStep4Request,
  voucherRequest,
  voucherDeleteRequest,
  resetLoadingShowInvoice,
} from '../../../store/modules/invoice/actions';

export default function Step4Tesouraria({ match }) {
  const { invoice, loadingUpdate, loadingShow } = useSelector(
    state => state.invoice
  );

  const dispatch = useDispatch();
  const invoiceId = match.params.id;

  useEffect(() => {
    dispatch(showInvoiceRequest(invoiceId));

    return () => dispatch(resetLoadingShowInvoice());
  }, [dispatch, invoiceId]);

  const handleUpdate = useCallback(
    values => {
      const payload = {
        ...values,
        id: invoiceId,
        status: values.status,
      };
      dispatch(updateStep4Request(payload));
    },
    [dispatch, invoiceId]
  );

  const handleSubmitVoucher = useCallback(
    voucher => {
      dispatch(voucherRequest({ voucher, invoiceId }));
    },
    [dispatch, invoiceId]
  );

  const handleDeleteVoucher = useCallback(
    id => {
      dispatch(voucherDeleteRequest({ invoiceId: id }));
    },
    [dispatch]
  );

  return (
    <>
      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <>
          <Row>
            <Col>
              <Card>
                <Form
                  invoice={invoice}
                  loading={loadingUpdate}
                  onSubmit={values => handleUpdate(values)}
                  onSubmitVoucher={voucher => handleSubmitVoucher(voucher)}
                  onDeleteVoucher={id => handleDeleteVoucher(id)}
                />
              </Card>
            </Col>

            <Col>
              <InvoiceSummary invoice={invoice} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

Step4Tesouraria.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
