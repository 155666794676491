import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ActivityIndicator from '../../../components/ActivityIndicator';
import Stepper from '../../../components/Stepper';
import ProductList from '../../../components/ProductList';
import Card from '../../../components/Card';

import Secretario from './Secretario';
import ControleInterno from './ControleInterno';
import Contabilidade from './Contabilidade';
import Tesouraria from './Tesouraria';

import {
  showInvoiceRequest,
  resetLoadingShowInvoice,
} from '../../../store/modules/invoice/actions';

import { ContainerDetails, ContainerCancelledInvoice } from './styles';

export default function InvoiceDetails() {
  const invoiceId = useParams().id;

  const { invoice: invoiceData, loadingShow } = useSelector(
    ({ invoice }) => invoice
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showInvoiceRequest(invoiceId));

    return () => dispatch(resetLoadingShowInvoice());
  }, [dispatch, invoiceId]);

  return (
    <>
      {loadingShow ? (
        <ActivityIndicator />
      ) : (
        <>
          <Card className="mb-12 relative">
            {invoiceData && invoiceData.status === 'cancelada' && (
              <ContainerCancelledInvoice>
                <span>Lançamento cancelado</span>
              </ContainerCancelledInvoice>
            )}

            {invoiceData && (
              <Stepper
                current={invoiceData && invoiceData.location}
                updatedDates={invoiceData}
              />
            )}
          </Card>

          <Card>
            <ContainerDetails>
              <Secretario invoice={invoiceData} />
              <ProductList
                products={(invoiceData && invoiceData.products) || []}
                showHeader
                totalValue={(invoiceData && invoiceData.total_value) || 0}
              />
              <ControleInterno invoice={invoiceData} />
              <Contabilidade invoice={invoiceData} />
              <Tesouraria invoice={invoiceData} />
            </ContainerDetails>
          </Card>
        </>
      )}
    </>
  );
}
