import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FiCamera } from 'react-icons/fi';

import { toast } from 'react-toastify';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Card from '../../components/Card';

import { FooterButtons } from '../../styles/containers';

import {
  updateProfileRequest,
  updateAvatarRequest,
} from '../../store/modules/profile/actions';

import avatarImage from '../../assets/avatar.png';

import { AvatarInput } from './styles';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Insira o nome'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('Informe o email'),
  old_password: Yup.string().when('password', {
    is: val => !!(val && val.length >= 0),
    then: Yup.string().required('Insira a senha atual'),
  }),
  password: Yup.string().min(6, 'Insira uma senha com no mínimo 6 caracteres'),
  password_confirmation: Yup.string().when('old_password', {
    is: val => val && val.length >= 0,
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'As senhas não combinam')
      .required('Confirme a nova senha'),
  }),
});

export default function ProfileUpdate() {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    old_password: '',
    password: '',
    password_confirmation: '',
  });

  const { loadingUpdate, profile: user } = useSelector(
    ({ profile }) => profile
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const { name, email } = user;

      setUserData({
        name,
        email,
        old_password: '',
        password: '',
        password_confirmation: '',
      });
    }
  }, [user]);

  const handleUpdateUser = useCallback(
    ({ values, resetForm }) => {
      const { name, email, ...passwordsData } = values;

      const profileData = {
        name,
        email,
        ...(passwordsData.old_password ? passwordsData : {}),
      };

      dispatch(updateProfileRequest({ profile: profileData, resetForm }));
    },
    [dispatch]
  );

  const handleAvatarChange = useCallback(
    e => {
      const file = e.target.files[0];

      if (file) {
        const FILE_MAX_SIZE = 262144; // 256KB
        const hasAcceptableSize = file.size < FILE_MAX_SIZE;

        if (!hasAcceptableSize) {
          toast.error('Erro!! O tamanho máximo aceito é 256KB');
          return;
        }

        const data = new FormData();
        data.append('avatar', file);

        dispatch(updateAvatarRequest(data));
      }
    },
    [dispatch]
  );

  return (
    <Card>
      <div className="card__header">
        <h3>Meu Perfil</h3>
      </div>

      <AvatarInput>
        <img src={user.avatar_url || avatarImage} alt={user.name} />
        <label htmlFor="avatar">
          <FiCamera />
          <input type="file" id="avatar" onChange={handleAvatarChange} />
        </label>
      </AvatarInput>

      <Formik
        initialValues={userData || {}}
        onSubmit={(values, { resetForm }) => {
          handleUpdateUser({ values, resetForm });
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Input
                  label="Nome"
                  name="name"
                  value={values.name}
                  error={errors.name && touched.name}
                  errorMessage={errors.name}
                  onChange={({ target }) => setFieldValue('name', target.value)}
                />
              </Col>

              <Col md={6}>
                <Input
                  label="Email"
                  name="email"
                  value={values.email}
                  error={errors.email && touched.email}
                  errorMessage={errors.email}
                  onChange={({ target }) =>
                    setFieldValue('email', target.value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Input
                  label="Senha atual"
                  name="old_password"
                  value={values.old_password}
                  error={errors.old_password && touched.old_password}
                  errorMessage={errors.old_password}
                  onChange={({ target }) =>
                    setFieldValue('old_password', target.value)
                  }
                  type="password"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  label="Nova senha"
                  name="password"
                  value={values.password}
                  error={errors.password && touched.password}
                  errorMessage={errors.password}
                  onChange={({ target }) =>
                    setFieldValue('password', target.value)
                  }
                  type="password"
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Confirme a nova senha"
                  name="password_confirmation"
                  value={values.password_confirmation}
                  error={
                    errors.password_confirmation &&
                    touched.password_confirmation
                  }
                  errorMessage={errors.password_confirmation}
                  onChange={({ target }) =>
                    setFieldValue('password_confirmation', target.value)
                  }
                  type="password"
                />
              </Col>
            </Row>

            <FooterButtons>
              <Link to="/users">
                <Button
                  label="Voltar"
                  variant="clean"
                  color="black"
                  width="100px"
                />
              </Link>
              <Button
                label="Atualizar"
                type="submit"
                loading={loadingUpdate}
                width="100px"
                className="font-medium"
              />
            </FooterButtons>
          </form>
        )}
      </Formik>
    </Card>
  );
}
