import styled, { css } from 'styled-components';

export const Container = styled.div`
  .header {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 14px;
      color: #424242;
      text-align: left;
    }
  }
`;

const dragActive = css`
  border-color: #12a454;
  background: #12a45424;
`;
const dragReject = css`
  border-color: #e83f5b;
  background: #e83f5b24;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border: 1.5px solid #969cb3;
  background: #969cb324;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive}

  ${props => props.isDragReject && dragReject}
`;

const messageColors = {
  default: '#969cb3',
  error: '#e83f5b',
  success: '#12a454',
};

export const UploadMessage = styled.p`
  display: flex;
  font-size: 16px;
  line-height: 24px;
  padding: 48px 0;

  color: ${({ type }) => messageColors[type || 'default']};

  justify-content: center;
  align-items: center;
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;

  svg {
    margin-right: 3px;
  }

  p {
    font-size: 14px;
    color: #969cb3;
  }
`;
