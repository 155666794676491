import React, { useState, useRef, useEffect } from 'react';
import { MdModeEdit } from 'react-icons/md';
import { FaCheck, FaTimes } from 'react-icons/fa';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Currency from '../Currency';

import { ContainerAmount } from './styles';

const Amount = ({ value, isEditable, handleUpdateTotalValue }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [changedValue, setChangedValue] = useState(value);

  const inputRef = useRef(null);

  useEffect(() => {
    setChangedValue(value);
  }, [value]);

  const handleEdit = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    setIsEditing(true);
  };

  const handleInputChange = event => {
    setChangedValue(event.target.value);
  };

  const handleUpdateValue = () => {
    setIsEditing(false);
    handleUpdateTotalValue(changedValue);
  };

  return (
    <ContainerAmount padding="10px" className="amount">
      Total:
      <Currency
        inputRef={inputRef}
        value={changedValue}
        onChange={handleInputChange}
        displayType="input"
        className={classnames('value__input', {
          'value--hide': !isEditing,
          'value--show': isEditing,
        })}
        fixedDecimalScale
      />
      <Currency
        value={Number(value)}
        displayType="text"
        className={classnames('value__span', {
          'value--hide': isEditing,
        })}
        hidden={isEditing}
        fixedDecimalScale
      />
      {isEditable && (
        <>
          {isEditing ? (
            <>
              <FaCheck
                size={24}
                className="blue ml-12"
                title="Atualizar"
                onClick={handleUpdateValue}
              />
              <FaTimes
                size={20}
                title="Cancelar"
                onClick={() => setIsEditing(false)}
                className="ml-12"
              />
            </>
          ) : (
            <MdModeEdit
              onClick={handleEdit}
              size={24}
              className={classnames({ blue: isEditing })}
              title="Editar"
            />
          )}
        </>
      )}
    </ContainerAmount>
  );
};

Amount.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleUpdateTotalValue: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

Amount.defaulProps = {
  isEditable: false,
};

export default Amount;
