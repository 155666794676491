import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Route from './Route';

import PageLoading from '../components/PageLoading';

import SignIn from '../pages/auth/SignIn';
import ForgotPassword from '../pages/auth/ForgotPassword';
import RecoveryPassword from '../pages/auth/RecoveryPassword';

import Users from '../pages/Users/List';
import UserStore from '../pages/Users/Store';
import UserUpdate from '../pages/Users/Update';

import Profile from '../pages/Profile';

import InvoiceList from '../pages/Invoice/List';

import SDStore from '../pages/Invoice/Step1SD/Store';
import InvoiceDetails from '../pages/Invoice/Details';
import InvoiceUpdateStep1SD from '../pages/Invoice/Step1SD/Update';
import InvoiceUpdateStep2ControleInterno from '../pages/Invoice/Step2ControleInterno';
import InvoiceUpdateStep3Contabilidade from '../pages/Invoice/Step3Contabilidade';
import InvoiceUpdateStep4Tesouraria from '../pages/Invoice/Step4Tesouraria';

import Product from '../pages/Product';

import Providers from '../pages/Provider/List';
import ProviderStore from '../pages/Provider/Store';
import ProviderUpdate from '../pages/Provider/Update';

import Fundos from '../pages/Fundo/List';
import FundoStore from '../pages/Fundo/Store';
import FundoUpdate from '../pages/Fundo/Update';

import Chat from '../pages/Chat';

import NotFound from '../pages/NotFound';

export default function Routes() {
  const { isLoading } = useSelector(({ common }) => common);

  return (
    <>
      <PageLoading isLoading={isLoading} />
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/reset-password" exact component={RecoveryPassword} />

        <Route path="/profile" exact component={Profile} isPrivate />

        <Route
          path="/users"
          exact
          component={Users}
          isPrivate
          auth={['prefeito']}
        />
        <Route
          path="/user/new"
          exact
          component={UserStore}
          isPrivate
          auth={['prefeito']}
        />
        <Route
          path="/user/:id"
          exact
          component={UserUpdate}
          isPrivate
          auth={['prefeito']}
        />

        <Route path="/invoices" exact component={InvoiceList} isPrivate />
        <Route path="/invoice/new" component={SDStore} isPrivate />
        <Route
          path="/invoice/:id/details"
          component={InvoiceDetails}
          isPrivate
        />

        <Route
          path="/invoice/:id/pdf/edit"
          component={InvoiceUpdateStep1SD}
          isPrivate
        />
        <Route
          path="/invoice/:id/step/secretario"
          component={InvoiceUpdateStep1SD}
          isPrivate
          auth={['secretario']}
        />
        <Route
          path="/invoice/:id/step/controle_interno"
          component={InvoiceUpdateStep2ControleInterno}
          isPrivate
          auth={['controle_interno']}
        />
        <Route
          path="/invoice/:id/step/contabilidade"
          component={InvoiceUpdateStep3Contabilidade}
          isPrivate
          auth={['contabilidade']}
        />
        <Route
          path="/invoice/:id/step/tesoureiro"
          component={InvoiceUpdateStep4Tesouraria}
          isPrivate
          auth={['tesoureiro']}
        />

        <Route path="/products" exact component={Product} isPrivate />

        <Route path="/providers" exact component={Providers} isPrivate />
        <Route path="/providers/new" component={ProviderStore} isPrivate />
        <Route path="/providers/:id" component={ProviderUpdate} isPrivate />

        <Route path="/fundos" exact component={Fundos} isPrivate />
        <Route path="/fundo/new" component={FundoStore} isPrivate />
        <Route path="/fundo/:id" component={FundoUpdate} isPrivate />

        <Route path="/chat" exact component={Chat} isPrivate />
        <Route path="/chat/:id" component={Chat} isPrivate />

        <Route component={NotFound} />
      </Switch>
    </>
  );
}
