import api from '../../api';

export default {
  index: async filters => {
    const res = await api.get('/notifications', { params: { ...filters } });
    return res.data;
  },

  update: async payload => {
    const { id } = payload;
    const res = await api.patch(`/notifications/${id}/read`);
    return res.data;
  },
};
