import styled from 'styled-components';

export const Body = styled.div`
  display: block;
  width: 100%;
  min-height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  table {
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: 'Roboto', sans-serif !important;
    color: var(--primary-text);
  }

  .table {
    width: 100%;
    margin-bottom: 24px;
  }

  .table th,
  .table td {
    font-size: 14px;
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
  }

  .table td:last-child {
    border-right: 0;
  }

  .table thead th {
    font-weight: normal;
    color: var(--primary-text);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0px;
  }

  .table__row--clickable tbody {
    tr {
      cursor: pointer;
      :hover {
        background: #a2a2a230;
      }
    }
  }

  /* .table--striped tbody > tr:nth-child(odd) > td,
  .table--striped tbody > tr:nth-child(odd) > th {
    background-color: #f3f3f39e;
  } */
  .table--striped tbody > tr:hover {
    background-color: #f3f3f39e;
  }

  /* Small Sizes */
  @media (max-width: 767px) {
    /* Responsive Table */
    .table-responsive {
      display: block;
      position: relative;
      width: 100%;
    }

    .table-responsive thead th {
      max-width: 110px;
    }

    .table-responsive thead,
    .table-responsive tbody,
    .table-responsive th,
    .table-responsive td,
    .table-responsive tr {
      display: block;
    }
    .table-responsive td,
    .table-responsive th {
      height: 60px;
      text-align: center !important;
    }

    .table-responsive thead {
      float: left;
    }

    .table-responsive tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    .table-responsive tbody tr {
      display: inline-block;
    }
  }
`;

export const Loading = styled.div`
  min-width: 100%;
  min-height: 50px;
  height: 100%;
  background: #fafafa7a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 0;
`;

export const TableEmpty = styled.p`
  margin-top: -12px;
  font-size: 14px;
  text-align: center;
  color: #767676;
`;
