import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import history from '../../../services/history';

import api from '../../../services/core/http/users';

import * as actions from './actions';

export function* listUsersWorker() {
  try {
    const users = yield call(api.get);

    yield put(actions.listUsersSuccess(users));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(actions.listUsersFailure());
  }
}

export function* searchUsersWorker({ payload }) {
  try {
    const { filters } = payload;

    const response = yield call(api.search, filters);

    const totalCount = Number(response.headers['x-total-count']);
    const responseWithCount = { users: response.data, totalCount };

    yield put(actions.listUsersSuccess(responseWithCount));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(actions.listUsersFailure());
  }
}

export function* createUserWorker({ payload }) {
  try {
    yield call(api.store, payload.user);

    yield put(actions.createUserSuccess());

    toast.success('Usuário adicionado com sucesso');
    history.push('/users');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(actions.createUserFailure());
  }
}

export function* showUserWorker({ payload }) {
  try {
    const response = yield call(api.show, payload);

    yield put(actions.showUserSuccess(response));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    history.push('/users');
    yield put(actions.showUserFailure());
  }
}

export function* updateUserWorker({ payload }) {
  try {
    yield call(api.update, payload.user);

    yield put(actions.updateUserSuccess());

    toast.success('Usuário editado com sucesso!');
    history.push('/users');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(actions.updateUserFailure());
  }
}

export function* deleteUserWorker({ payload }) {
  try {
    yield call(api.delete, payload.id);

    yield put(actions.deleteUserSuccess());

    toast.success('Usuário deletado com sucesso!');
    history.push('/users');
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );

    yield put(actions.deleteUserFailure());
  }
}

export default all([
  takeLatest(types.LIST_REQUEST, listUsersWorker),
  takeLatest(types.SEARCH_REQUEST, searchUsersWorker),
  takeLatest(types.CREATE_REQUEST, createUserWorker),
  takeLatest(types.SHOW_REQUEST, showUserWorker),
  takeLatest(types.UPDATE_REQUEST, updateUserWorker),
  takeLatest(types.DELETE_REQUEST, deleteUserWorker),
]);
