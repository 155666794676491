import styled from 'styled-components';

export const Container = styled.div`
  padding: 2.2rem 2.8rem;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  /* margin: 25px 0px 40px; */
  border-radius: 4px;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-image: initial;

  .card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
    min-height: 38px;

    h3 {
      font-size: 2rem;
    }
  }
`;
