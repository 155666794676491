const downloadFile = ({ fileName, type, file }) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  a.href = window.URL.createObjectURL(new Blob([file], { type }));

  a.setAttribute('download', fileName);
  a.click();

  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export default downloadFile;
