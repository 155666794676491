import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { SocketProvider } from '../../../hooks/socket';

import Header from '../../../components/Header';
import SideNav from '../../../components/SideNav';

import { WrapperBackground, WrapperContent, Children } from './styles';

export default function DefaultLayout({ children, ...rest }) {
  const [stateSideNav, setStateSideNav] = useState(false);

  const sideNavRef = useRef(null);
  const buttonChangeSideNavRef = useRef(null);

  const handleClickOutsideFromSideNav = useCallback(
    event => {
      if (
        sideNavRef.current &&
        !sideNavRef.current.contains(event.target) &&
        buttonChangeSideNavRef.current &&
        !buttonChangeSideNavRef.current.contains(event.target)
      ) {
        setStateSideNav(false);
      }
    },
    [sideNavRef, buttonChangeSideNavRef]
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutsideFromSideNav);

    return () => {
      document.removeEventListener('mouseup', handleClickOutsideFromSideNav);
    };
  });

  return (
    <SocketProvider>
      <WrapperBackground>
        <WrapperContent>
          <Header
            onToggleSideNav={() => setStateSideNav(old => !old)}
            buttonRef={buttonChangeSideNavRef}
            {...rest}
          />
          <SideNav
            stateSideNav={stateSideNav}
            sideNavRef={sideNavRef}
            onLinkClick={() => setStateSideNav(false)}
            {...rest}
          />

          <Children>{children}</Children>
        </WrapperContent>
      </WrapperBackground>
    </SocketProvider>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
