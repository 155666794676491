import api from '../../api';

export default {
  index: async () => {
    const res = await api.get('/fundos');
    return res.data;
  },

  search: async filters => {
    const res = await api.get('/fundos/search', { params: { ...filters } });
    return res;
  },

  show: async payload => {
    const { id } = payload;
    const res = await api.get(`/fundos/${id}`);
    return res.data;
  },

  store: async payload => {
    const res = await api.post('/fundos', payload);
    return res.data;
  },

  update: async payload => {
    const res = await api.put('/fundos', payload);
    return res.data;
  },
};
