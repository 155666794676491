import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { getUserType } from '../../../helpers/translate';

import ActivityIndicator from '../../../components/ActivityIndicator';

import { ContainerContacts, Contact } from './styles';

export default function Chats({ chatId, onChatClick }) {
  const [openedChats, setOpenedChats] = useState([]);
  const [loading, setLoading] = useState(true);

  const { profile: currentUser } = useSelector(({ profile }) => profile);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/chats');

        setOpenedChats(response.data);
      } catch (err) {
        toast.error('Erro ao carregar os chats');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const getUserReceiver = useCallback(
    users => {
      const receiver = users.find(user => user.id !== currentUser.id);
      return {
        name: receiver.name,
        id: receiver.id,
        type: getUserType(receiver.type),
      };
    },
    [currentUser.id]
  );

  if (loading) return <ActivityIndicator />;

  return (
    <>
      <ContainerContacts>
        {!!openedChats.length &&
          openedChats.map(chat => (
            <Link to={`/chat/${chat._id}`} key={chat._id}>
              <Contact active={chatId === chat._id} onClick={onChatClick}>
                <strong>{getUserReceiver(chat.users).name}</strong>
                <span>{getUserReceiver(chat.users).type}</span>
              </Contact>
            </Link>
          ))}
      </ContainerContacts>
    </>
  );
}

Chats.propTypes = {
  chatId: PropTypes.string,
  onChatClick: PropTypes.func,
};

Chats.defaultProps = {
  chatId: '',
  onChatClick: () => {},
};
