import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';

import api from '../../../services/core/http/profile';

import {
  updateProfileSuccess,
  updateProfileFailure,
  updateAvatarSuccess,
  updateAvatarFailure,
} from './actions';

export function* updateProfile({ payload }) {
  try {
    const profile = yield call(api.update, payload.profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(profile));
    payload.resetForm();
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateProfileFailure());
  }
}

export function* updateAvatar({ payload }) {
  try {
    const profile = yield call(api.updateAvatar, payload.file);

    toast.success('Avatar atualizado com sucesso!');

    yield put(updateAvatarSuccess(profile));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(updateAvatarFailure());
  }
}

export default all([
  takeLatest(types.UPDATE_REQUEST, updateProfile),
  takeLatest(types.UPDATE_AVATAR_REQUEST, updateAvatar),
]);
