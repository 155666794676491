const Types = {
  LIST_REQUEST: '@provider/LIST_REQUEST',
  LIST_FAILURE: '@provider/LIST_FAILURE',
  LIST_SUCCESS: '@provider/LIST_SUCCESS',

  SEARCH_REQUEST: '@provider/SEARCH_REQUEST',
  SEARCH_FAILURE: '@provider/SEARCH_FAILURE',
  SEARCH_SUCCESS: '@provider/SEARCH_SUCCESS',

  SHOW_REQUEST: '@provider/SHOW_REQUEST',
  SHOW_FAILURE: '@provider/SHOW_FAILURE',
  SHOW_SUCCESS: '@provider/SHOW_SUCCESS',
  RESET_LOADING_SHOW: '@provider/RESET_LOADING_SHOW',

  CREATE_REQUEST: '@provider/CREATE_REQUEST',
  CREATE_FAILURE: '@provider/CREATE_FAILURE',
  CREATE_SUCCESS: '@provider/CREATE_SUCCESS',

  UPDATE_REQUEST: '@provider/UPDATE_REQUEST',
  UPDATE_FAILURE: '@provider/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@provider/UPDATE_SUCCESS',

  DOWNLOAD_REPORT_REQUEST: '@provider/DOWNLOAD_REPORT_REQUEST',
  DOWNLOAD_REPORT_FAILURE: '@provider/DOWNLOAD_REPORT_FAILURE',
  DOWNLOAD_REPORT_SUCCESS: '@provider/DOWNLOAD_REPORT_SUCCESS',
};

export default Types;
