import styled from 'styled-components';
import styledBy from 'styled-by';

export const StepperList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .step__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;

    &::after {
      content: ' ';
      position: absolute;
      background-color: #aaa;
      z-index: 1;
      height: 2px;

      width: calc(100% - 30px);
      left: calc(50% + 15px);

      ${styledBy({
        visitedLine: `
          background-color: var(--success-color);
      `,
      })}
    }
  }

  :last-child .step__wrapper::after {
    display: none;
  }

  .step__indicator {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 12px;

    background: #aaa;
    border: 2px solid #aaa;
    color: #fff;

    ${styledBy({
      // active: `
      //   border: 2px solid #1976d2;
      //   background: #1976d2;

      // `,
      visited: `
        border: 2px solid var(--success-color);
        background: var(--success-color);
      `,
    })}
  }

  .step__label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.5rem;
    font-size: 13px;
    width: 100%;
    text-align: center;
    color: #aaa;
    word-break: break-word;
    font-weight: 500;

    ${styledBy({
      // active: `
      //   color: #1976d2;

      // `,
      visited: `
        color: var(--success-color);
        font-weight: bold;
      `,
    })}
  }

  .step__date {
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    color: #aaa;

    ${styledBy({
      visited: `
        color: var(--success-color-lighten);
        font-weight: 500;
      `,
    })}
  }
`;
