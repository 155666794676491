import styled, { css } from 'styled-components';
import Action from '../../../../../components/Action';

export const CustomAction = styled(Action)`
  height: 100%;
  display: flex;
  align-items: center;

  h4 {
    font-size: 14px;
    text-align: left;
    padding: 12px;
    color: #5c5c5c;
    position: relative;

    ::after {
      content: '';
      position: absolute;
      height: 1px;
      left: 10px;
      bottom: 8px;
      width: 92%;
      background: #e6e6e6;
    }
  }
`;

export const Container = styled.div`
  display: flex;
`;

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px;

  .filter-title {
    font-size: 12px;
    text-align: left;
    color: #5c5c5c;
    margin-bottom: 4px;
  }

  &.w-100 {
    width: 100px;
  }

  &.w-130 {
    width: 130px;
  }

  &.w-170 {
    width: 170px;
  }
`;

export const CustomButton = styled.button`
  background: none;
  text-align: left;
  padding: 6px 0px !important;
  font-size: 13px !important;
  border-radius: 4px;
  transition: all 0.5 ease-in-out;
  margin-bottom: 4px;

  ${({ selected }) =>
    selected &&
    css`
      background: #e3f2fd !important;
      padding: 6px !important;
      color: #0a69b5;
    `};
`;
