import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  isLoading: false,
};

export default function common(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.LOADING_START: {
        draft.isLoading = true;
        break;
      }
      case types.LOADING_FINISH: {
        draft.isLoading = false;
        break;
      }

      default:
    }
  });
}
