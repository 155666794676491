import React from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content, AnimationContainer } from './styles';
import { Banner } from '../styles';

import { signInRequest } from '../../../store/modules/auth/actions';

const logoImage = process.env.REACT_APP_LOGO_URL;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('O Email é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.auth.loading);

  function handleSignIn(values) {
    dispatch(signInRequest(values));
  }

  return (
    <Container>
      <Banner>
        <img src={logoImage} alt="Logo" className="logo-image" />
        <h2>Bem-vindo de volta!</h2>
      </Banner>

      <Content>
        <AnimationContainer>
          <h1>Faça seu login</h1>

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleSignIn(values)}
          >
            {({ handleSubmit, errors, values, touched, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  value={values.email}
                  error={errors.email && touched.email}
                  errorMessage={errors.email}
                  onChange={({ target }) =>
                    setFieldValue('email', target.value)
                  }
                />
                <Input
                  type="password"
                  name="password"
                  label="Senha"
                  value={values.password}
                  error={errors.password && touched.password}
                  errorMessage={errors.password}
                  onChange={({ target }) =>
                    setFieldValue('password', target.value)
                  }
                />

                <Button
                  type="submit"
                  label="Acessar"
                  color="primary"
                  loading={loading}
                  className="font-medium"
                />
                {/* <p className="txt-center">
                  <Link to="/forgot-password">Esqueci minha senha</Link>
                </p> */}
              </form>
            )}
          </Formik>
        </AnimationContainer>
      </Content>
    </Container>
  );
}
