const Types = {
  LIST_REQUEST: '@product/LIST_REQUEST',
  LIST_FAILURE: '@product/LIST_FAILURE',
  LIST_SUCCESS: '@product/LIST_SUCCESS',

  SEARCH_REQUEST: '@product/SEARCH_REQUEST',
  SEARCH_FAILURE: '@product/SEARCH_FAILURE',
  SEARCH_SUCCESS: '@product/SEARCH_SUCCESS',

  CREATE_REQUEST: '@product/CREATE_REQUEST',
  CREATE_FAILURE: '@product/CREATE_FAILURE',
  CREATE_SUCCESS: '@product/CREATE_SUCCESS',

  UPDATE_REQUEST: '@product/UPDATE_REQUEST',
  UPDATE_FAILURE: '@product/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@product/UPDATE_SUCCESS',

  DELETE_REQUEST: '@product/DELETE_REQUEST',
  DELETE_FAILURE: '@product/DELETE_FAILURE',
  DELETE_SUCCESS: '@product/DELETE_SUCCESS',

  SET_IS_OPENED_MODAL: '@product/SET_IS_OPENED_MODAL',
};

export default Types;
