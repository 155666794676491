import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-grid-system';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

export default function ModalDeleteConfirm({
  open,
  closeAction,
  handleConfirm,
  loading,
}) {
  return (
    <Modal
      title="Deseja realmente excluir?"
      open={open}
      width={{ width: '400px' }}
      closeAction={closeAction}
    >
      <Row justify="end">
        <Button
          label="Cancelar"
          variant="outlined"
          color="black"
          height="34px"
          onClick={closeAction}
          width="100px"
        />

        <Button
          className="ml-12 font-medium"
          label="Sim"
          color="error"
          type="submit"
          height="34px"
          width="100px"
          loading={loading}
          onClick={handleConfirm}
        />
      </Row>
    </Modal>
  );
}

ModalDeleteConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeAction: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ModalDeleteConfirm.defaultProps = {
  loading: false,
};
