import types from './types';

export const createProviderRequest = provider => ({
  type: types.CREATE_REQUEST,
  payload: { provider },
});
export const createProviderSuccess = provider => ({
  type: types.CREATE_SUCCESS,
  payload: { provider },
});
export const createProviderFailure = () => ({
  type: types.CREATE_FAILURE,
});

export const listProvidersRequest = () => ({
  type: types.LIST_REQUEST,
});
export const listProvidersSuccess = ({ providers }) => ({
  type: types.LIST_SUCCESS,
  payload: { providers },
});
export const listProvidersFailure = () => ({
  type: types.LIST_FAILURE,
});

export const searchProvidersRequest = ({ filters }) => ({
  type: types.SEARCH_REQUEST,
  payload: { filters },
});
export const searchProvidersSuccess = providers => ({
  type: types.SEARCH_SUCCESS,
  payload: { providers },
});
export const searchProvidersFailure = () => ({
  type: types.SEARCH_FAILURE,
});

export const showProviderRequest = id => ({
  type: types.SHOW_REQUEST,
  payload: { id },
});
export const showProviderSuccess = provider => ({
  type: types.SHOW_SUCCESS,
  payload: { provider },
});
export const showProviderFailure = () => ({
  type: types.SHOW_FAILURE,
});
export const resetLoadingShow = () => ({
  type: types.RESET_LOADING_SHOW,
});

export const updateProviderRequest = provider => ({
  type: types.UPDATE_REQUEST,
  payload: { provider },
});
export const updateProviderSuccess = provider => ({
  type: types.UPDATE_SUCCESS,
  payload: { provider },
});
export const updateProviderFailure = () => ({
  type: types.UPDATE_FAILURE,
});

export const downloadProviderReport = filters => ({
  type: types.DOWNLOAD_REPORT_REQUEST,
  payload: filters,
});
export const downloadProviderReportSuccess = () => ({
  type: types.DOWNLOAD_REPORT_SUCCESS,
});
export const downloadProviderReportFaliure = () => ({
  type: types.DOWNLOAD_REPORT_FAILURE,
});
