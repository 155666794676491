import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import * as actions from './actions';

import apiProducts from '../../../services/core/http/product';

export function* productsWorker() {
  try {
    const products = yield call(apiProducts.index,);    

    yield put(actions.listProductsSuccess({ products }));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(actions.listProductsFailure());
  }
}

export function* searchProductsWorker({ payload }) {
  try {
    const { filters } = payload;

    const response = yield call(apiProducts.search, filters);

    const totalCount = Number(response.headers['x-total-count']);
    const responseWithCount = { products: response.data, totalCount };

    yield put(actions.searchProductsSuccess(responseWithCount));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(actions.searchProductsFailure());
  }
}

export function* createProductWorker({ payload }) {
  try {
    const response = yield call(apiProducts.store, payload);

    yield put(actions.createSuccess({ product: response.data }));
    const filters = {  page: 1, per_page: 8, search: '' }
    yield put(actions.searchProductsRequest({ filters }));

  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(actions.createFailure());
  }
}

export function* updateProductWorker({ payload }) {
  try {
    yield call(apiProducts.update, payload);
    yield put(actions.updateSuccess());

    const filters = {  page: 1, per_page: 8, search: '' }
    yield put(actions.searchProductsRequest({ filters }));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(actions.updateFailure());
  }
}

export function* deleteProductWorker({ payload }) {
  try {
    yield call(apiProducts.delete, payload);
    
    yield put(actions.deleteSuccess());
    const filters = {  page: 1, per_page: 8, search: '' }
    yield put(actions.searchProductsRequest({ filters }));
  } catch ({ response }) {
    toast.error(
      (response && response.data.message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(actions.deleteFailure());
  }
}

export default all([
  takeLatest(types.LIST_REQUEST, productsWorker),
  takeLatest(types.SEARCH_REQUEST, searchProductsWorker),
  takeLatest(types.CREATE_REQUEST, createProductWorker),
  takeLatest(types.UPDATE_REQUEST, updateProductWorker),
  takeLatest(types.DELETE_REQUEST, deleteProductWorker),
]);
