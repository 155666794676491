import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-activity';
import * as FontAwesome from 'react-icons/fa';

import colorsValue from '../../styles/colors';

import { Container } from './styles';

export default function Button({
  variant,
  color,
  customColor,
  customBackground,
  width,
  height,
  label,
  type,
  onClick,
  loading,
  disabled,
  icon,
  iconSize,
  className,
  ...rest
}) {
  const Icon = FontAwesome[icon];

  const getColor = typeColor => {
    const colors = {
      primary: { primary: colorsValue.infoPrimary, secondary: '#fff' },
      success: { primary: colorsValue.infoSuccess, secondary: '#fff' },
      error: { primary: colorsValue.infoDanger, secondary: '#fff' },
      warning: { primary: colorsValue.infoWarning, secondary: '#fff' },
      black: { primary: '#424242', secondary: '#d4d4d5' },
      default: { primary: '#d4d4d561', secondary: '#424242' },
    };

    return colors[typeColor] || colors.default;
  };

  return (
    <Container
      width={width}
      height={height}
      color={getColor(color)}
      customColor={customColor}
      customBackground={customBackground}
      variant={variant}
      onClick={onClick}
      label={label}
      type={type}
      loading={loading ? 'true' : ''}
      disabled={loading || disabled}
      className={className}
      {...rest}
    >
      {loading && <Spinner size={15} />}
      {label && <span className="button__label">{label}</span>}
      {Icon && <Icon className="button__icon" size={iconSize} />}
    </Container>
  );
}

Button.propTypes = {
  color: PropTypes.oneOf([
    'default',
    'primary',
    'success',
    'error',
    'warning',
    'black',
  ]),
  variant: PropTypes.oneOf(['contained', 'outlined', 'clean']),
  customColor: PropTypes.string,
  customBackground: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  customColor: '',
  customBackground: '',
  width: '100%',
  height: '35px',
  label: '',
  icon: '',
  iconSize: '20',
  type: 'button',
  loading: false,
  disabled: false,
  onClick: () => {},
  className: '',
};
