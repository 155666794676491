import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  invoice: null,
  invoices: null,
  loadingList: false,
  loadingShow: true,
  loadingCreate: false,
  loadingUpdate: false,
  loadingVoucher: false,
  loadingDelete: false,
  loadingClone: false,
  loading: false,

  loadingProductUpdate: false,
  successUpdate: false,

  allUnits: []
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.ALL_DATA_TO_INVOICE_PAGE_STEP1_REQUEST: {
        draft.loadingShow = true;
        break;
      }
      case types.ALL_DATA_TO_INVOICE_PAGE_STEP1_SUCCESS: {
        const { invoice } = action.payload;

        draft.loadingShow = false;

        if (invoice) {
          const totalValue = invoice.products.reduce((subTotal, item) => {
            return subTotal + Number(item.amount);
          }, 0);

          draft.invoice = {
            ...invoice,
            total_value: Number(invoice.total_value) || totalValue,
          };
        }
        break;
      }
      case types.ALL_DATA_TO_INVOICE_PAGE_STEP1_FAILURE: {
        draft.loadingShow = false;
        break;
      }

      case types.LIST_REQUEST: {
        draft.loadingList = true;
        break;
      }
      case types.LIST_SUCCESS: {
        draft.invoices = action.payload.invoices;
        draft.loadingList = false;
        break;
      }
      case types.LIST_FAILURE: {
        draft.loadingList = false;
        break;
      }

      case types.SHOW_REQUEST: {
        draft.loadingShow = true;
        break;
      }
      case types.SHOW_SUCCESS: {
        const { invoice } = action.payload;

        const totalValue = invoice.products.reduce((subTotal, item) => {
          return subTotal + Number(item.amount);
        }, 0);

        draft.invoice = {
          ...invoice,
          total_value: invoice.total_value || totalValue,
        };

        draft.loadingShow = false;
        break;
      }
      case types.SHOW_FAILURE: {
        draft.loadingShow = false;
        break;
      }
      case types.RESET_LOADING_SHOW: {
        draft.loadingShow = true;
        break;
      }

      case types.UPDATE_TOTAL_VALUE: {
        draft.invoice = {
          ...state.invoice,
          total_value: action.payload.total_value,
        };
        break;
      }

      case types.CREATE_REQUEST: {
        draft.loadingCreate = true;
        break;
      }
      case types.CREATE_SUCCESS: {
        draft.invoice = action.payload.invoice;
        draft.loadingCreate = false;
        break;
      }
      case types.CREATE_FAILURE: {
        draft.loadingCreate = false;
        break;
      }

      case types.CLONE_REQUEST: {
        draft.loadingClone = true;
        break;
      }
      case types.CLONE_SUCCESS: {
        draft.loadingClone = false;
        break;
      }
      case types.CLONE_FAILURE: {
        draft.loadingClone = false;
        break;
      }

      case types.UPDATE_STEP1_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_STEP1_SUCCESS: {
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_STEP1_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.UPDATE_STEP2_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_STEP2_SUCCESS: {
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_STEP2_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.UPDATE_STEP3_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_STEP3_SUCCESS: {
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_STEP3_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.UPDATE_STEP4_REQUEST: {
        draft.loadingUpdate = true;
        break;
      }
      case types.UPDATE_STEP4_SUCCESS: {
        draft.loadingUpdate = false;
        break;
      }
      case types.UPDATE_STEP4_FAILURE: {
        draft.loadingUpdate = false;
        break;
      }

      case types.VOUCHER_REQUEST: {
        draft.loadingVoucher = true;
        break;
      }
      case types.VOUCHER_SUCCESS: {
        const { voucher, voucher_url } = action.payload;
        const updatedInvoice = { ...state.invoice, voucher, voucher_url };

        draft.invoice = updatedInvoice;
        draft.loadingVoucher = false;
        break;
      }
      case types.VOUCHER_FAILURE: {
        draft.loadingVoucher = false;
        break;
      }

      case types.VOUCHER_DELETE_REQUEST: {
        draft.loadingVoucher = true;
        break;
      }
      case types.VOUCHER_DELETE_SUCCESS: {
        const { voucher, voucher_url } = action.payload;
        const updatedInvoice = { ...state.invoice, voucher, voucher_url };

        draft.invoice = updatedInvoice;
        draft.loadingVoucher = false;
        break;
      }
      case types.VOUCHER_DELETE_FAILURE: {
        draft.loadingVoucher = false;
        break;
      }

      case types.DELETE_REQUEST: {
        draft.loadingDelete = true;
        break;
      }
      case types.DELETE_SUCCESS: {
        draft.loadingDelete = false;
        break;
      }
      case types.DELETE_FAILURE: {
        draft.loadingDelete = false;
        break;
      }

      case types.DOWNLOAD_REPORT_REQUEST: {
        draft.loading = true;
        break;
      }
      case types.DOWNLOAD_REPORT_SUCCESS: {
        draft.loading = false;
        break;
      }
      case types.DOWNLOAD_REPORT_FAILURE: {
        draft.loading = false;
        break;
      }

      case types.CREATE_PRODUCT_REQUEST: {
        draft.loadingCreate = true;
        break;
      }
      case types.CREATE_PRODUCT_SUCCESS: {
        const { invoice } = state;
        const { product } = action.payload;
        const newArrayProducts = [...invoice.products, action.payload.product];

        const subTotalProduct = product.quantity * product.unit_value;
        const newTotalValue =
          Number(invoice.total_value) + Number(subTotalProduct);

        draft.invoice = {
          ...invoice,
          products: newArrayProducts,
          total_value: newTotalValue,
        };
        draft.loadingCreate = false;
        break;
      }
      case types.CREATE_PRODUCT_FAILURE: {
        draft.loadingCreate = false;
        break;
      }

      case types.UPDATE_PRODUCT_REQUEST: {
        draft.loadingProductUpdate = true;
        draft.successUpdate = false;
        break;
      }
      case types.UPDATE_PRODUCT_SUCCESS: {
        const { invoice } = state;
        const { products } = invoice;
        const uploadedProduct = action.payload.product;

        const newProducts = products.map(product =>
          product.id === uploadedProduct.id ? uploadedProduct : product
        );

        const newTotalValue = newProducts.reduce((subTotal, item) => {
          return subTotal + Number(item.amount);
        }, 0);

        draft.invoice = {
          ...invoice,
          products: newProducts,
          total_value: newTotalValue,
        };
        draft.loadingProductUpdate = false;
        draft.successUpdate = true;
        break;
      }
      case types.UPDATE_PRODUCT_FAILURE: {
        draft.loadingProductUpdate = false;
        draft.successUpdate = false;
        break;
      }

      case types.DELETE_PRODUCT_REQUEST: {
        draft.loadingDelete = true;
        break;
      }
      case types.DELETE_PRODUCT_SUCCESS: {
        const { invoice } = state;
        const { products } = invoice;

        const idProductDeleted = action.payload.id;
        const newProducts = products.filter(
          product => product.id !== idProductDeleted
        );

        const newTotalValue = newProducts.reduce((subTotal, item) => {
          return subTotal + Number(item.amount);
        }, 0);

        draft.invoice = {
          ...invoice,
          products: newProducts,
          total_value: newTotalValue,
        };

        draft.loadingDelete = false;
        break;
      }
      case types.DELETE_PRODUCT_FAILURE: {
        draft.loadingDelete = false;
        break;
      }

      case types.CREATE_PRODUCT_UNIT_SUCCESS: {
        const { unit } = action.payload;
        draft.allUnits = [...state.allUnits, unit];
        break;
      }

      case types.LIST_PRODUCT_UNIT_SUCCESS: {
        draft.allUnits = action.payload.units;
        break;
      }

      default:
    }
  });
}
