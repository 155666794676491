import React, { useCallback } from 'react';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

import DetailsInfo from '../../../components/DetailsInfo';

import { formatDate } from '../../../helpers/formatDate';
import formatCpfCnpj from '../../../helpers/formatCpfCnpj';

function Contabilidade({ invoice }) {
  const getInfo = useCallback(
    info => {
      const { fundo, empenho, data_empenho } = invoice;

      const invoiceData = {
        fundo: fundo && fundo.name,
        fundo_cnpj: fundo && formatCpfCnpj(fundo.cnpj),
        empenho,
        data_empenho: formatDate(data_empenho),
        default: '---',
      };

      return invoiceData[info] || invoiceData.default;
    },
    [invoice]
  );

  return (
    <div>
      <h4 className="title">Contabilidade</h4>
      <Row>
        <Col md={3}>
          <DetailsInfo>
            <span>Fundo</span>
            <p>{getInfo('fundo')}</p>
          </DetailsInfo>
        </Col>
        <Col md={3}>
          <DetailsInfo>
            <span>CNPJ do Fundo</span>
            <p>{getInfo('fundo_cnpj')}</p>
          </DetailsInfo>
        </Col>

        <Col md={3}>
          <DetailsInfo>
            <span>Empenho</span>
            <p>{getInfo('empenho')}</p>
          </DetailsInfo>
        </Col>

        <Col md={3}>
          <DetailsInfo>
            <span>Data de empenho</span>
            <p>{getInfo('data_empenho')}</p>
          </DetailsInfo>
        </Col>
      </Row>
    </div>
  );
}

Contabilidade.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default Contabilidade;
