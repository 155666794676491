import styled from 'styled-components';

export const Container = styled.div`
  .react-select__control {
    appearance: none;
    background: var(--input-background);
    border: 2px solid var(--input-border) !important;
    border-radius: var(--input-radius);
    color: var(--primary-text);
    font-size: 14px;
    position: relative;
    width: 100%;
    outline: none;
    height: 36px;
    min-height: 36px;

    box-shadow: none !important;

    outline: ${({ error }) =>
      error && `1px auto var(--input-border-error)!important`};
  }

  .react-select__control--is-focused {
    outline: ${({ error }) =>
      error
        ? '1px auto var(--input-border-error)'
        : '1px auto var(--input-focus)'}!important;
  }

  .react-select__value-container {
    padding: 0px 8px !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    padding: 4px;
  }

  .react-select__menu {
    font-size: 14px;
  }

  .react-select__menu-list {
    border-radius: 4px;
    max-height: 220px !important;

    max-height: ${({ maxHeightMenuList }) => `${maxHeightMenuList}!important`};
  }

  .react-select__option {
    font-size: 14px;
    padding: 9px 15px;
  }

  .react-select__single-value {
    color: var(--primary-text);
  }

  .react-select__input input {
    color: var(--primary-text) !important;
  }
`;
