import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import Chip from '../../../../../components/Chip';
import { translate } from '../../../../../helpers/translate';

import * as S from './styles';

const FilterTags = ({ filters, onDelete, onClearAll, selectedFundo }) => {
  const formattedFilters = useMemo(() => {
    const { start_date, end_date, fundo_id, ...restFilters } = filters;
    const date_interval =
      start_date && end_date
        ? `${format(parseISO(start_date), 'dd/MM/yyyy')} - ${format(
            parseISO(end_date),
            'dd/MM/yyyy'
          )}`
        : '';

    const fundo =
      selectedFundo && selectedFundo.value === fundo_id
        ? selectedFundo.label
        : '';

    return { date_interval, fundo, ...restFilters };
  }, [filters, selectedFundo]);

  const handleDeleteFilter = useCallback(
    filterKey => {
      if (filterKey === 'date_interval') {
        onDelete({ start_date: '', end_date: '' });
        return;
      }

      if (filterKey === 'fundo') {
        onDelete({ fundo_id: null });
        return;
      }

      onDelete({ [filterKey]: '' });
    },
    [onDelete]
  );

  const hasFilter = useMemo(() => {
    if (!formattedFilters) return false;

    return Object.keys(formattedFilters).some(key => formattedFilters[key]);
  }, [formattedFilters]);

  return (
    <S.WrapperTags className="grid grid-cols-12 gap-2">
      <div className="d-flex flex-wrap gap-2  col-span-11">
        {Object.keys(formattedFilters).map(
          key =>
            formattedFilters[key] && (
              <Chip
                label={translate(formattedFilters[key]).toLowerCase()}
                size="small"
                onDelete={() => handleDeleteFilter(key)}
                variant="outlined"
                key={key}
              />
            )
        )}
      </div>

      {hasFilter && (
        <button
          className="col-span-1 button__clear-filters"
          type="button"
          onClick={onClearAll}
        >
          limpar filtros
        </button>
      )}
    </S.WrapperTags>
  );
};

FilterTags.propTypes = {
  filters: PropTypes.shape({
    type: PropTypes.string,
    priority: PropTypes.string,
    location: PropTypes.string,
    user_id: PropTypes.string,
    fundo_id: PropTypes.string,
    search: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedFundo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(null),
  ]),
  onClearAll: PropTypes.func.isRequired,
};

FilterTags.defaultProps = {
  selectedFundo: null,
};

export default FilterTags;
