import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { TiInfoOutline } from 'react-icons/ti';

import Button from '../Button';

import { Container, DropContainer, UploadMessage, InfoMessage } from './styles';

const maxSize = 1024 * 256 * 1;

const Upload = ({ onUpload, onDelete, label, previewUrl }) => {
  function renderDragMessage(isDragActive, isDragRejest) {
    if (!isDragActive) {
      return (
        <UploadMessage>Selecione ou arraste o arquivo aqui.</UploadMessage>
      );
    }

    if (isDragRejest) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  }

  function handleUpload(files) {
    const file = files[0];

    if (file.size > maxSize) {
      toast.error('O arquivo deve ter no máximo 256 kB');
      return;
    }

    onUpload(files);
  }

  return (
    <Container>
      <div className="header">
        {label && <p>{label}</p>}

        {previewUrl && (
          <Button
            color="black"
            variant="clean"
            type="button"
            width="50px"
            height="25px"
            onClick={onDelete}
            icon="FaTimes"
            title="Deletar comprovante"
          />
        )}
      </div>

      {previewUrl ? (
        <object
          width="100%"
          height="180"
          data={previewUrl}
          type="application/pdf"
          aria-label="Document"
        />
      ) : (
        <>
          <Dropzone
            accept=".pdf, application/pdf, text/pdf"
            // maxSize={maxSize}
            onDropAccepted={handleUpload}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <DropContainer
                {...getRootProps()}
                isDragActive={isDragActive}
                isDragReject={isDragReject}
              >
                <input {...getInputProps()} />
                {renderDragMessage(isDragActive, isDragReject)}
              </DropContainer>
            )}
          </Dropzone>

          <InfoMessage>
            <TiInfoOutline size={20} color="#FF872C" />
            <div>
              <p>Tamanho máximo aceito: 256kB</p>
              <p>Permitido apenas arquivos: pdf</p>
            </div>
          </InfoMessage>
        </>
      )}
    </Container>
  );
};

Upload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  previewUrl: PropTypes.string,
};

Upload.defaultProps = {
  label: '',
  previewUrl: '',
};

export default Upload;
