import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  th,
  td {
    padding: 4px !important;
  }

  td label {
    margin-bottom: 0 !important;
  }
`;

export const Header = styled.div`
  padding: 5px 10px;
  border: none;
  background: #dcdcdc42;
  display: flex;
  align-items: center;

  h4 {
    font-weight: 500;
  }
`;

const widthTransition = keyframes`
  from {
    opacity: 0;
    width: 0px;
  }

  to {
    opacity: 1;
    width: 100%;
  }
`;

export const ContainerAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  width: max-content;
  margin-left: auto;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  color: #65696c;

  padding: ${props => props.padding};

  .value__span {
    margin-left: 5px;
    color: #424242;
    font-size: 22px;
    min-width: max-content;
  }

  .value__input input {
    margin-left: 5px;
    color: #424242;
    font-size: 20px;
  }

  .value--hide {
    opacity: 0;
    width: 0;
    z-index: -9999;
    position: absolute;
  }

  .value--show {
    animation: ${widthTransition} 1s;
  }

  label {
    margin-bottom: 0;
    margin-right: 12px;
  }

  svg {
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }

    &.blue {
      color: #1564c0 !important;
    }
  }
`;
