import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { FooterButtons } from '../../../styles/containers';

import { formatDate } from '../../../helpers/formatDate';

import { fundosRequest } from '../../../store/modules/fundo/actions';

const validationSchema = Yup.object().shape({
  fundo_id: Yup.string().required('Informe o fundo'),
  empenho: Yup.string().required('Informe o numero do empenho'),
  data_empenho: Yup.string().required('Informe a data de empenho'),
});

export default function FormContabilidade({ data, onSubmit, loading }) {
  const [initialData, setInitialData] = useState(
    () =>
      data && {
        fundo_id: data.fundo_id || '',
        empenho: data.empenho || '',
        data_empenho: data.data_empenho
          ? formatDate(data.data_empenho, 'yyyy-MM-DD')
          : '',
      }
  );

  const fundos = useSelector(state => state.fundo.allFundos);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fundosRequest());

    return () => {
      setInitialData({});
    };
  }, [dispatch]);

  const fundoOptions = useMemo(() => {
      return fundos.map(fundo => ({
      value: fundo.id,
      label: fundo.name,
    }));
  }, [fundos])

  const onSubmitInvoice = values => {
    if (onSubmit) onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      onSubmit={values => {
        onSubmitInvoice(values);
      }}
      enableReinitialize
    >
      {({ handleSubmit, values, errors, setFieldValue, touched }) => (
        <form onSubmit={handleSubmit}>
          <div className="card__header">
            <h3>Contabilidade</h3>
          </div>
          <Row>
            <Col>
              <Field
                component={Select}
                name="fundo_id"
                label="Fundo"
                options={fundoOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Input
                label="Empenho"
                name="empenho"
                value={values.empenho}
                error={errors.empenho && touched.empenho}
                errorMessage={errors.empenho}
                onChange={({ target }) =>
                  setFieldValue('empenho', target.value)
                }
              />
            </Col>
            <Col md={6}>
              <Input
                label="Data de empenho"
                type="date"
                name="data_empenho"
                value={values.data_empenho}
                error={errors.data_empenho && touched.data_empenho}
                errorMessage={errors.data_empenho}
                onChange={({ target }) =>
                  setFieldValue('data_empenho', target.value)
                }
              />
            </Col>
          </Row>

          <FooterButtons>
            <Link to="/invoices">
              <Button
                label="Cancelar"
                color="black"
                variant="clean"
                type="button"
              />
            </Link>

            <Button
              label="Salvar"
              color="success"
              type="submit"
              loading={loading}
              width="85px"
              className="font-medium"
            />
          </FooterButtons>
        </form>
      )}
    </Formik>
  );
}

FormContabilidade.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

FormContabilidade.defaultProps = {
  data: {},
  onSubmit: () => {},
  loading: false,
};
