import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-activity/dist/react-activity.css';

import media from './media';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  #root, html, body {
    min-width: 100%;
    min-height: 100vh;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  html {
    font-size: 62.5%; /*10px*/ 
    box-sizing: border-box;
    line-height: 1.15;

    @media ${media.desktop} {
        font-size: 60%;
    }

    @media ${media.tablet} {
        font-size: 57.5%;
    }

    @media ${media.phone} {
        font-size: 55%;
    }
  }

  body, input, textarea, select, button, a, p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    box-sizing: inherit;
    color: var(--primary-text);
    box-shadow: none;
  }
  

  svg {
    color: currentColor!important;
  }

  button {
    background: none;
    border: 0;
    transition: 180ms ease-in-out;
  }

  a, button {
    outline: none;
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
  }

  ol, ul {
    list-style: none;
  }

  h1, h2, h3, h4, h5 {
    color: var(--primary-text);
  }

  /* Font Sizes */
  h1 {
  font-size: 4.9rem;
  }
  h2 {
  font-size: 3rem;
  }
  h3 {
  font-size: 2.4rem;
  }
  h4 {
  font-size: 2rem;
  }
  h5 {
  font-size: 1.8rem;
  }

  #root {
    
    --font-family: 'Roboto', sans-serif;

    --black: #111;
    --white: #fff;
    --gray-1: #f5f5f5;
    --gray-2: #d8d8d8;
    --gray-3: #9b9b9b;
    --gray-4: #4a4a4a;
    
    --blue-100: #e3f2fd;
    --blue-200: #90caf9;
    --blue-500: #2095f3;
    --blue-700: #1976d2;
    --blue-800: #1564c0;

    --green-100: #c8e6c9;
    --green-200: #a5d6a7;
    --green-400: #66bb69;
    --green-500: #4caf4f;
    --green-700: #388e3b;
    --green-800: #2E7D32;
    
    --red-100: #ffcdd2;
    --red-200: #ef9a9a;
    --red-400: #ef5350;
    --red-500: #f44336;
    --red-700: #d32f2f;
    
    --default-color: var(--gray-4);
    --default-color-lighten: #5c5c5c;
    --default-color-contrast: var(--white);
    --disabled-color: var(--gray-3);
    --disabled-color-contrast: var(--gray-1);
    --primary-color: var(--blue-700);
    --primary-color-lighten: var(--blue-500);
    --primary-color-contrast: var(--white);
    --secondary-color-contrast: var(--white);
    --success-color: #3ea06c;
    --success-color-lighten: var(--green-500);
    --success-color-contrast: var(--white);
    --danger-color: var(--red-700);
    --danger-color-lighten: var(--red-500);
    --danger-color-contrast: var(--white);

    --bg: #18191a;
    --surface-background: #fafafa;
    --primary-text: var(--gray-4);
    --secondary-text: #5c5c5c;
    --input-background: #fff;
    --input-disabled: var(--gray-1);
    --input-border: var(--gray-2);
    --input-focus: var(--blue-700);
    --input-error: var(--red-700);
    --input-border-error: #e47676;
    --input-radius: 4px;
    --shadow: rgba(0, 0, 0, 0.2);

  }

  /**
   **** Global Class ****
   */ 

  /* Text align */
  .txt-start { text-align: start!important; }
  .txt-center { text-align: center!important; }
  .txt-end { text-align: end!important; }

  /* Font weight */
  .font-thin	{ font-weight: 100; }
  .font-extralight	{ font-weight: 200; }
  .font-light	{ font-weight: 300; }
  .font-normal	{ font-weight: 400; }
  .font-medium	{ font-weight: 500; }
  .font-semibold	{ font-weight: 600; }
  .font-bold	{ font-weight: 700; }
  .font-extrabold	{ font-weight: 800; }
  .font-black	{ font-weight: 900; }

  /* Word Break */
  .break-normal	{ overflow-wrap: normal; word-break: normal; }
  .break-words { overflow-wrap: break-word; }
  .break-all { word-break: break-all; }

  /* Display */
  .d-flex { display: flex; }
  .flex-column { flex-direction: column; }
  .justify-center { justify-content: center; }
  .justify-end { justify-content: flex-end; }
  .justify-space-between { justify-content: space-between; }
  .align-center { align-items: center; }

  /* Flex wrap */
  .flex-wrap { flex-wrap: wrap; }
  .flex-wrap-reverse { flex-wrap: wrap-reverse; }
  .flex-nowrap { flex-wrap: nowrap; }
  
  /* Margin */
  .mb-0 { margin-bottom: 0!important; }
  .mb-6 { margin-bottom: 0.6rem!important; }
  .mb-12 { margin-bottom: 1.2rem!important; }
  .mb-24 { margin-bottom: 2.4rem!important; }
  .mb-42 { margin-bottom: 4.2rem!important; }
  .ml-12 { margin-left: 1.2rem!important; }
  .ml-6 { margin-left: 0.6rem!important; }

  /* Padding */
  .p-6 { padding: 0.6rem; }
  .p-12 { padding: 1.2rem; }
  .p-24 { padding: 2.4rem; }

  /* Position */
  .relative { position: relative; }
  
  /* Position */
  .cursor-pointer { cursor: pointer; } 

  /****************************************
  ****************************************
  * Grid
  ****************************************
  ****************************************/
  .grid { display: grid; }
  .grid-cols-1	{ grid-template-columns: repeat(1, minmax(0, 1fr)); }
  .grid-cols-2	{ grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .grid-cols-3	{ grid-template-columns: repeat(3, minmax(0, 1fr)); }
  .grid-cols-4	{ grid-template-columns: repeat(4, minmax(0, 1fr)); }
  .grid-cols-5	{ grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .grid-cols-6	{ grid-template-columns: repeat(6, minmax(0, 1fr)); }
  .grid-cols-7	{ grid-template-columns: repeat(7, minmax(0, 1fr)); }
  .grid-cols-8	{ grid-template-columns: repeat(8, minmax(0, 1fr)); }
  .grid-cols-9	{ grid-template-columns: repeat(9, minmax(0, 1fr)); }
  .grid-cols-10	{ grid-template-columns: repeat(10, minmax(0, 1fr)); }
  .grid-cols-11	{ grid-template-columns: repeat(11, minmax(0, 1fr)); }
  .grid-cols-12	{ grid-template-columns: repeat(12, minmax(0, 1fr)); }
  .grid-cols-none	{ grid-template-columns: none; }

  .col-span-1	{ grid-column: span 1 / span 1; }
  .col-span-2	{ grid-column: span 2 / span 2; }
  .col-span-3	{ grid-column: span 3 / span 3; }
  .col-span-4	{ grid-column: span 4 / span 4; }
  .col-span-5	{ grid-column: span 5 / span 5; }
  .col-span-6	{ grid-column: span 6 / span 6; }
  .col-span-7	{ grid-column: span 7 / span 7; }
  .col-span-8	{ grid-column: span 8 / span 8; }
  .col-span-9	{ grid-column: span 9 / span 9; }
  .col-span-10	{ grid-column: span 10 / span 10; }
  .col-span-11	{ grid-column: span 11 / span 11; }
  .col-span-12	{ grid-column: span 12 / span 12; }
  .col-span-full { grid-column: 1 / -1 };
  .col-start-1	{ grid-column-start: 1; }
  .col-start-2	{ grid-column-start: 2; }
  .col-start-3	{ grid-column-start: 3; }
  .col-start-4	{ grid-column-start: 4; }
  .col-start-5	{ grid-column-start: 5; }
  .col-start-6	{ grid-column-start: 6; }
  .col-start-7	{ grid-column-start: 7; }
  .col-start-8	{ grid-column-start: 8; }
  .col-start-9	{ grid-column-start: 9; }
  .col-start-10	{ grid-column-start: 10; }
  .col-start-11	{ grid-column-start: 11; }
  .col-start-12	{ grid-column-start: 12; }
  .col-start-13	{ grid-column-start: 13; }
  .col-start-auto	{ grid-column-start: auto; }
  .col-end-1	{ grid-column-end: 1; }
  .col-end-2	{ grid-column-end: 2; }
  .col-end-3	{ grid-column-end: 3; }
  .col-end-4	{ grid-column-end: 4; }
  .col-end-5	{ grid-column-end: 5; }
  .col-end-6	{ grid-column-end: 6; }
  .col-end-7	{ grid-column-end: 7; }
  .col-end-8	{ grid-column-end: 8; }
  .col-end-9	{ grid-column-end: 9; }
  .col-end-10	{ grid-column-end: 10; }
  .col-end-11	{ grid-column-end: 11; }
  .col-end-12	{ grid-column-end: 12; }
  .col-end-13	{ grid-column-end: 13; }
  .col-end-auto	{ grid-column-end: auto; }

  .gap-0	{ gap: 0px; }
  .gap-x-0	{ column-gap: 0px; }
  .gap-y-0	{ row-gap: 0px; }
  .gap-1	{ gap: 0.25rem; }
  .gap-x-1	{ column-gap: 0.25rem; }
  .gap-y-1	{ row-gap: 0.25rem; }
  .gap-2	{ gap: 0.5rem; }
  .gap-x-2	{ column-gap: 0.5rem; }
  .gap-y-2	{ row-gap: 0.5rem; }
  .gap-3	{ gap: 0.75rem; }
  .gap-x-3	{ column-gap: 0.75rem; }
  .gap-y-3	{ row-gap: 0.75rem; }
  .gap-4	{ gap: 1rem; }
  .gap-x-4	{ column-gap: 1rem; }
  .gap-y-4	{ row-gap: 1rem; }
  .gap-5	{ gap: 1.25rem; }
  .gap-x-5	{ column-gap: 1.25rem; }
  .gap-y-5	{ row-gap: 1.25rem; }
  .gap-6	{ gap: 1.5rem; }
  .gap-x-6	{ column-gap: 1.5rem; }
  .gap-y-6	{ row-gap: 1.5rem; }
  .gap-7	{ gap: 1.75rem; }
  .gap-x-7	{ column-gap: 1.75rem; }
  .gap-y-7	{ row-gap: 1.75rem; }
  .gap-8	{ gap: 2rem; }
  .gap-x-8	{ column-gap: 2rem; }
  .gap-y-8	{ row-gap: 2rem; }
  .gap-9	{ gap: 2.25rem; }
  .gap-x-9	{ column-gap: 2.25rem; }
  .gap-y-9	{ row-gap: 2.25rem; }
  .gap-10	{ gap: 2.5rem; }
  .gap-x-10	{ column-gap: 2.5rem; }
  .gap-y-10	{ row-gap: 2.5rem; }
  .gap-11	{ gap: 2.75rem; }
  .gap-x-11	{ column-gap: 2.75rem; }
  .gap-y-11	{ row-gap: 2.75rem; }
  .gap-12	{ gap: 3rem; }
  .gap-x-12	{ column-gap: 3rem; }
  .gap-y-12	{ row-gap: 3rem; }

  @media screen and (min-width: 640px) {
    .sm_grid-cols-1	{ grid-template-columns: repeat(1, minmax(0, 1fr)); }
    .sm_grid-cols-2	{ grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .sm_grid-cols-3	{ grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .sm_grid-cols-4	{ grid-template-columns: repeat(4, minmax(0, 1fr)); }
    .sm_grid-cols-5	{ grid-template-columns: repeat(5, minmax(0, 1fr)); }
    .sm_grid-cols-6	{ grid-template-columns: repeat(6, minmax(0, 1fr)); }
    .sm_grid-cols-7	{ grid-template-columns: repeat(7, minmax(0, 1fr)); }
    .sm_grid-cols-8	{ grid-template-columns: repeat(8, minmax(0, 1fr)); }
    .sm_grid-cols-9	{ grid-template-columns: repeat(9, minmax(0, 1fr)); }
    .sm_grid-cols-10	{ grid-template-columns: repeat(10, minmax(0, 1fr)); }
    .sm_grid-cols-11	{ grid-template-columns: repeat(11, minmax(0, 1fr)); }
    .sm_grid-cols-12	{ grid-template-columns: repeat(12, minmax(0, 1fr)); }
    .sm_grid-cols-none	{ grid-template-columns: none; }
    .sm_col-span-1	{ grid-column: span 1 / span 1; }
    .sm_col-span-2	{ grid-column: span 2 / span 2; }
    .sm_col-span-3	{ grid-column: span 3 / span 3; }
    .sm_col-span-4	{ grid-column: span 4 / span 4; }
    .sm_col-span-5	{ grid-column: span 5 / span 5; }
    .sm_col-span-6	{ grid-column: span 6 / span 6; }
    .sm_col-span-7	{ grid-column: span 7 / span 7; }
    .sm_col-span-8	{ grid-column: span 8 / span 8; }
    .sm_col-span-9	{ grid-column: span 9 / span 9; }
    .sm_col-span-10	{ grid-column: span 10 / span 10; }
    .sm_col-span-11	{ grid-column: span 11 / span 11; }
    .sm_col-span-12	{ grid-column: span 12 / span 12; }
    .sm_col-span-full { grid-column: 1 / -1 };
    .sm_col-start-1	{ grid-column-start: 1; }
    .sm_col-start-2	{ grid-column-start: 2; }
    .sm_col-start-3	{ grid-column-start: 3; }
    .sm_col-start-4	{ grid-column-start: 4; }
    .sm_col-start-5	{ grid-column-start: 5; }
    .sm_col-start-6	{ grid-column-start: 6; }
    .sm_col-start-7	{ grid-column-start: 7; }
    .sm_col-start-8	{ grid-column-start: 8; }
    .sm_col-start-9	{ grid-column-start: 9; }
    .sm_col-start-10	{ grid-column-start: 10; }
    .sm_col-start-11	{ grid-column-start: 11; }
    .sm_col-start-12	{ grid-column-start: 12; }
    .sm_col-start-13	{ grid-column-start: 13; }
    .sm_col-start-auto	{ grid-column-start: auto; }
    .sm_col-end-1	{ grid-column-end: 1; }
    .sm_col-end-2	{ grid-column-end: 2; }
    .sm_col-end-3	{ grid-column-end: 3; }
    .sm_col-end-4	{ grid-column-end: 4; }
    .sm_col-end-5	{ grid-column-end: 5; }
    .sm_col-end-6	{ grid-column-end: 6; }
    .sm_col-end-7	{ grid-column-end: 7; }
    .sm_col-end-8	{ grid-column-end: 8; }
    .sm_col-end-9	{ grid-column-end: 9; }
    .sm_col-end-10	{ grid-column-end: 10; }
    .sm_col-end-11	{ grid-column-end: 11; }
    .sm_col-end-12	{ grid-column-end: 12; }
    .sm_col-end-13	{ grid-column-end: 13; }
    .sm_col-end-auto	{ grid-column-end: auto; }    
  }

  @media screen and (min-width: 768px) {
    .md_grid-cols-1	{ grid-template-columns: repeat(1, minmax(0, 1fr)); }
    .md_grid-cols-2	{ grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .md_grid-cols-3	{ grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .md_grid-cols-4	{ grid-template-columns: repeat(4, minmax(0, 1fr)); }
    .md_grid-cols-5	{ grid-template-columns: repeat(5, minmax(0, 1fr)); }
    .md_grid-cols-6	{ grid-template-columns: repeat(6, minmax(0, 1fr)); }
    .md_grid-cols-7	{ grid-template-columns: repeat(7, minmax(0, 1fr)); }
    .md_grid-cols-8	{ grid-template-columns: repeat(8, minmax(0, 1fr)); }
    .md_grid-cols-9	{ grid-template-columns: repeat(9, minmax(0, 1fr)); }
    .md_grid-cols-10	{ grid-template-columns: repeat(10, minmax(0, 1fr)); }
    .md_grid-cols-11	{ grid-template-columns: repeat(11, minmax(0, 1fr)); }
    .md_grid-cols-12	{ grid-template-columns: repeat(12, minmax(0, 1fr)); }
    .md_grid-cols-none	{ grid-template-columns: none; }
    .md_col-span-1	{ grid-column: span 1 / span 1; }
    .md_col-span-2	{ grid-column: span 2 / span 2; }
    .md_col-span-3	{ grid-column: span 3 / span 3; }
    .md_col-span-4	{ grid-column: span 4 / span 4; }
    .md_col-span-5	{ grid-column: span 5 / span 5; }
    .md_col-span-6	{ grid-column: span 6 / span 6; }
    .md_col-span-7	{ grid-column: span 7 / span 7; }
    .md_col-span-8	{ grid-column: span 8 / span 8; }
    .md_col-span-9	{ grid-column: span 9 / span 9; }
    .md_col-span-10	{ grid-column: span 10 / span 10; }
    .md_col-span-11	{ grid-column: span 11 / span 11; }
    .md_col-span-12	{ grid-column: span 12 / span 12; }
    .md_col-span-full { grid-column: 1 / -1 };
    .md_col-start-1	{ grid-column-start: 1; }
    .md_col-start-2	{ grid-column-start: 2; }
    .md_col-start-3	{ grid-column-start: 3; }
    .md_col-start-4	{ grid-column-start: 4; }
    .md_col-start-5	{ grid-column-start: 5; }
    .md_col-start-6	{ grid-column-start: 6; }
    .md_col-start-7	{ grid-column-start: 7; }
    .md_col-start-8	{ grid-column-start: 8; }
    .md_col-start-9	{ grid-column-start: 9; }
    .md_col-start-10	{ grid-column-start: 10; }
    .md_col-start-11	{ grid-column-start: 11; }
    .md_col-start-12	{ grid-column-start: 12; }
    .md_col-start-13	{ grid-column-start: 13; }
    .md_col-start-auto	{ grid-column-start: auto; }
    .md_col-end-1	{ grid-column-end: 1; }
    .md_col-end-2	{ grid-column-end: 2; }
    .md_col-end-3	{ grid-column-end: 3; }
    .md_col-end-4	{ grid-column-end: 4; }
    .md_col-end-5	{ grid-column-end: 5; }
    .md_col-end-6	{ grid-column-end: 6; }
    .md_col-end-7	{ grid-column-end: 7; }
    .md_col-end-8	{ grid-column-end: 8; }
    .md_col-end-9	{ grid-column-end: 9; }
    .md_col-end-10	{ grid-column-end: 10; }
    .md_col-end-11	{ grid-column-end: 11; }
    .md_col-end-12	{ grid-column-end: 12; }
    .md_col-end-13	{ grid-column-end: 13; }
    .md_col-end-auto	{ grid-column-end: auto; }   
  }

  @media screen and (min-width: 1024px) {
    .lg_grid-cols-1	{ grid-template-columns: repeat(1, minmax(0, 1fr)); }
    .lg_grid-cols-2	{ grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .lg_grid-cols-3	{ grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .lg_grid-cols-4	{ grid-template-columns: repeat(4, minmax(0, 1fr)); }
    .lg_grid-cols-5	{ grid-template-columns: repeat(5, minmax(0, 1fr)); }
    .lg_grid-cols-6	{ grid-template-columns: repeat(6, minmax(0, 1fr)); }
    .lg_grid-cols-7	{ grid-template-columns: repeat(7, minmax(0, 1fr)); }
    .lg_grid-cols-8	{ grid-template-columns: repeat(8, minmax(0, 1fr)); }
    .lg_grid-cols-9	{ grid-template-columns: repeat(9, minmax(0, 1fr)); }
    .lg_grid-cols-10	{ grid-template-columns: repeat(10, minmax(0, 1fr)); }
    .lg_grid-cols-11	{ grid-template-columns: repeat(11, minmax(0, 1fr)); }
    .lg_grid-cols-12	{ grid-template-columns: repeat(12, minmax(0, 1fr)); }
    .lg_grid-cols-none	{ grid-template-columns: none; }
    .lg_col-span-1	{ grid-column: span 1 / span 1; }
    .lg_col-span-2	{ grid-column: span 2 / span 2; }
    .lg_col-span-3	{ grid-column: span 3 / span 3; }
    .lg_col-span-4	{ grid-column: span 4 / span 4; }
    .lg_col-span-5	{ grid-column: span 5 / span 5; }
    .lg_col-span-6	{ grid-column: span 6 / span 6; }
    .lg_col-span-7	{ grid-column: span 7 / span 7; }
    .lg_col-span-8	{ grid-column: span 8 / span 8; }
    .lg_col-span-9	{ grid-column: span 9 / span 9; }
    .lg_col-span-10	{ grid-column: span 10 / span 10; }
    .lg_col-span-11	{ grid-column: span 11 / span 11; }
    .lg_col-span-12	{ grid-column: span 12 / span 12; }
    .lg_col-span-full { grid-column: 1 / -1 };
    .lg_col-start-1	{ grid-column-start: 1; }
    .lg_col-start-2	{ grid-column-start: 2; }
    .lg_col-start-3	{ grid-column-start: 3; }
    .lg_col-start-4	{ grid-column-start: 4; }
    .lg_col-start-5	{ grid-column-start: 5; }
    .lg_col-start-6	{ grid-column-start: 6; }
    .lg_col-start-7	{ grid-column-start: 7; }
    .lg_col-start-8	{ grid-column-start: 8; }
    .lg_col-start-9	{ grid-column-start: 9; }
    .lg_col-start-10	{ grid-column-start: 10; }
    .lg_col-start-11	{ grid-column-start: 11; }
    .lg_col-start-12	{ grid-column-start: 12; }
    .lg_col-start-13	{ grid-column-start: 13; }
    .lg_col-start-auto	{ grid-column-start: auto; }
    .lg_col-end-1	{ grid-column-end: 1; }
    .lg_col-end-2	{ grid-column-end: 2; }
    .lg_col-end-3	{ grid-column-end: 3; }
    .lg_col-end-4	{ grid-column-end: 4; }
    .lg_col-end-5	{ grid-column-end: 5; }
    .lg_col-end-6	{ grid-column-end: 6; }
    .lg_col-end-7	{ grid-column-end: 7; }
    .lg_col-end-8	{ grid-column-end: 8; }
    .lg_col-end-9	{ grid-column-end: 9; }
    .lg_col-end-10	{ grid-column-end: 10; }
    .lg_col-end-11	{ grid-column-end: 11; }
    .lg_col-end-12	{ grid-column-end: 12; }
    .lg_col-end-13	{ grid-column-end: 13; }
    .lg_col-end-auto	{ grid-column-end: auto; }    
  }

  @media screen and (min-width: 1440px) {
    .xl_grid-cols-1	{ grid-template-columns: repeat(1, minmax(0, 1fr)); }
    .xl_grid-cols-2	{ grid-template-columns: repeat(2, minmax(0, 1fr)); }
    .xl_grid-cols-3	{ grid-template-columns: repeat(3, minmax(0, 1fr)); }
    .xl_grid-cols-4	{ grid-template-columns: repeat(4, minmax(0, 1fr)); }
    .xl_grid-cols-5	{ grid-template-columns: repeat(5, minmax(0, 1fr)); }
    .xl_grid-cols-6	{ grid-template-columns: repeat(6, minmax(0, 1fr)); }
    .xl_grid-cols-7	{ grid-template-columns: repeat(7, minmax(0, 1fr)); }
    .xl_grid-cols-8	{ grid-template-columns: repeat(8, minmax(0, 1fr)); }
    .xl_grid-cols-9	{ grid-template-columns: repeat(9, minmax(0, 1fr)); }
    .xl_grid-cols-10	{ grid-template-columns: repeat(10, minmax(0, 1fr)); }
    .xl_grid-cols-11	{ grid-template-columns: repeat(11, minmax(0, 1fr)); }
    .xl_grid-cols-12	{ grid-template-columns: repeat(12, minmax(0, 1fr)); }
    .xl_grid-cols-none	{ grid-template-columns: none; }
    .xl_col-span-1	{ grid-column: span 1 / span 1; }
    .xl_col-span-2	{ grid-column: span 2 / span 2; }
    .xl_col-span-3	{ grid-column: span 3 / span 3; }
    .xl_col-span-4	{ grid-column: span 4 / span 4; }
    .xl_col-span-5	{ grid-column: span 5 / span 5; }
    .xl_col-span-6	{ grid-column: span 6 / span 6; }
    .xl_col-span-7	{ grid-column: span 7 / span 7; }
    .xl_col-span-8	{ grid-column: span 8 / span 8; }
    .xl_col-span-9	{ grid-column: span 9 / span 9; }
    .xl_col-span-10	{ grid-column: span 10 / span 10; }
    .xl_col-span-11	{ grid-column: span 11 / span 11; }
    .xl_col-span-12	{ grid-column: span 12 / span 12; }
    .xl_col-span-full { grid-column: 1 / -1 };
    .xl_col-start-1	{ grid-column-start: 1; }
    .xl_col-start-2	{ grid-column-start: 2; }
    .xl_col-start-3	{ grid-column-start: 3; }
    .xl_col-start-4	{ grid-column-start: 4; }
    .xl_col-start-5	{ grid-column-start: 5; }
    .xl_col-start-6	{ grid-column-start: 6; }
    .xl_col-start-7	{ grid-column-start: 7; }
    .xl_col-start-8	{ grid-column-start: 8; }
    .xl_col-start-9	{ grid-column-start: 9; }
    .xl_col-start-10	{ grid-column-start: 10; }
    .xl_col-start-11	{ grid-column-start: 11; }
    .xl_col-start-12	{ grid-column-start: 12; }
    .xl_col-start-13	{ grid-column-start: 13; }
    .xl_col-start-auto	{ grid-column-start: auto; }
    .xl_col-end-1	{ grid-column-end: 1; }
    .xl_col-end-2	{ grid-column-end: 2; }
    .xl_col-end-3	{ grid-column-end: 3; }
    .xl_col-end-4	{ grid-column-end: 4; }
    .xl_col-end-5	{ grid-column-end: 5; }
    .xl_col-end-6	{ grid-column-end: 6; }
    .xl_col-end-7	{ grid-column-end: 7; }
    .xl_col-end-8	{ grid-column-end: 8; }
    .xl_col-end-9	{ grid-column-end: 9; }
    .xl_col-end-10	{ grid-column-end: 10; }
    .xl_col-end-11	{ grid-column-end: 11; }
    .xl_col-end-12	{ grid-column-end: 12; }
    .xl_col-end-13	{ grid-column-end: 13; }
    .xl_col-end-auto	{ grid-column-end: auto; }    
  }
`;
