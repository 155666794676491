import styled, { css } from 'styled-components';
import { BoxShadow } from '../../styles/variables';

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  margin-bottom: 1.6rem;

  background: #fff;
  border-radius: 3px;
  box-shadow: ${BoxShadow.card};
  display: flex;
  position: relative;
`;

export const ContainerMenu = styled.nav`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  padding: 6px 12px;
  width: 220px;

  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  background: #fff;

  @media screen and (min-width: 580px) {
    display: flex;
    position: relative;
  }
`;

export const ButtonToggleNav = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #727981;
  border-radius: 5px;

  @media screen and (min-width: 580px) {
    display: none;
  }

  &.button--open {
    left: 24px;
    top: 17px;
    z-index: 2;
    background: #f0f0f0;
  }

  &:hover {
    color: #5c5c5c;
    background: #e6e6e6;
  }
`;

export const Tabs = styled.div`
  margin: 22px 0;
  display: flex;
  justify-content: center;
`;

export const CustomButton = styled.button`
  margin: 0 12px;

  background: none;
  position: relative;
  font-weight: bold;
  color: var(--primary-text);

  opacity: 0.5;

  ${props =>
    props.active &&
    css`
      opacity: 1;

      ::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(1);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--primary-color);
        transform-origin: bottom left;
        transition: transform 0.3s ease-in-out;
      }
    `}
`;
