import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { debounce } from 'lodash';

import Button from '../../components/Button';
import { CustomInput } from '../../components/Input/styles';
import Table from '../../components/Table';
import Card from '../../components/Card';
import Paginate from '../../components/Paginate';

import addMaskToStringNumber from '../../helpers/addMaskToStringNumber';

import { searchProvidersRequest, downloadProviderReport } from '../../store/modules/provider/actions';

export default function List() {
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 8,
    search: '',
  });

  const { loadingFetch, providers } = useSelector(({ provider }) => provider);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(searchProvidersRequest({ filters }));
  }, [dispatch, filters]);

  const handleChangeFilter = filter => {
    setFilters(oldState => ({ ...oldState, ...filter }));
  };

  const handleSearchWithDebounce = useRef(
    debounce(filter => {
      handleChangeFilter(filter);
    }, 500)
  ).current;

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Tipo',
        accessor: 'document_type',
        Cell: ({ cell }) => {
          const document_type = cell.value;
          const providerType =
            document_type === 'cpf' ? 'Pessoa Física' : 'Pessoa Jurídica';
          return providerType;
        },
      },
      {
        Header: 'Cidade',
        accessor: 'city',
        Cell: ({ row }) => {
          const { city, state } = row.original;

          return `${city}-${state}`;
        },
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: ({ cell }) =>
          addMaskToStringNumber('(XX) X XXXX-XXXX', cell.value),
      },
    ],
    []
  );

  const handleDownloadReport = () => {
    dispatch(downloadProviderReport(filters));
  };

  const getRowProps = rowInfo => {
    const {
      original: { id },
    } = rowInfo;

    return {
      title: 'Ver detalhes',
      onClick: () => history.push(`/providers/${id}`),
    };
  };

  return (
    <Card>
      <div className="card__header">
        <h3>Fornecedores</h3>

        <div className="d-flex">

          <Link to="/providers/new">
            <Button
              color="primary"
              variant="outlined"
              label="Adicionar"
              height="3.4rem"
              className="font-medium"
            />
          </Link>
        
          <Button
            className="ml-12"
            variant="outlined"
            color="black"
            label="Baixar"
            iconSize="16"
            height="3.4rem"
            width="100px"
            onClick={handleDownloadReport}
            icon="FaDownload"
            disabled={
              providers && providers.providers && !providers.providers.length
            }
          />
        </div>
      </div>

      <CustomInput
        height="36px"
        placeholder="Buscar"
        mask={false}
        onChange={e => handleSearchWithDebounce({ search: e.target.value })}
        className="mb-12"
      />

      <Row>
        <Col>
          <Table
            columns={columns}
            data={(providers && providers.providers) || []}
            loading={loadingFetch}
            getRowProps={getRowProps}
            className="table__row--clickable"
          />

          <Paginate
            records={(providers && providers.totalCount) || 0}
            current={filters.page}
            limit={filters.per_page}
            onChange={page => handleChangeFilter({ page })}
            delta={1}
            fixed={1}
          />
        </Col>
      </Row>
    </Card>
  );
}

List.propTypes = {
  cell: PropTypes.shape({
    value: PropTypes.string,
  }),
};

List.defaultProps = {
  cell: {},
};
