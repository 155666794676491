import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import {
  notificationsSuccess,
  notificationsFailure,
  markAsReadSuccess,
  markAsReadFailure,
  // fundoSuccess,
  // fundoFailure,
  // createSuccess,
  // createFailure,
  // updateSuccess,
  // updateFailure,
} from './actions';

import api from '../../../services/core/http/notifications';

export function* notificationsWorker({ payload }) {
  try {
    const notifications = yield call(api.index, payload);

    yield put(notificationsSuccess(notifications));
  } catch ({ response }) {
    toast.error(
      (response && response.data.error_message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(notificationsFailure());
  }
}

export function* markAsReadWorker({ payload }) {
  try {
    const { notifications } = yield select(({ notification }) => notification);

    const updatedNotification = yield call(api.update, payload);

    const updatedNotifications = notifications.map(notification =>
      notification.id === updatedNotification.id
        ? updatedNotification
        : notification
    );

    yield put(markAsReadSuccess(updatedNotifications));
  } catch ({ response }) {
    toast.error(
      (response && response.data.error_message) ||
        'Erro de comunicação com o servidor'
    );
    yield put(markAsReadFailure());
  }
}

export default all([
  takeLatest(types.LIST_REQUEST, notificationsWorker),
  takeLatest(types.MARK_AS_READ_REQUEST, markAsReadWorker),
]);
