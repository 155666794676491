import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CardInfoWrapper } from './styles';

const CardInfo = ({ color, className, children, ...rest }) => {
  return (
    <CardInfoWrapper
      className={classNames('card-info', color, className)}
      {...rest}
    >
      {children}
    </CardInfoWrapper>
  );
};

CardInfo.propTypes = {
  color: PropTypes.oneOf(['danger']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CardInfo.defaultProps = {
  color: 'danger',
  className: '',
};

export default CardInfo;

export {
  CardInfoIcon,
  CardInfoContent,
  CardInfoTitle,
  CardInfoDescription,
} from './styles';
