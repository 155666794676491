const red = '#de5b52';
const green = '#3ea06c';
const blue = '#1564c0';
const blueLight = '#2095f3';
const yellow = '#dbab09';

const white = '#fff';
const grayLight = '#fafafa';
const gray = '#ddd';
const grayDark = '#424242';
const grayDarkest = '#24292e';

export default {
  backgroundDefault: gray,
  backgroundDark: grayDarkest,
  backgroundSideNav: white,
  backgroundCard: white,
  text: grayDark,
  textLight: grayLight,

  infoDanger: red,
  infoSuccess: green,
  infoPrimary: blueLight,
  infoWarning: yellow,

  invoiceStatusSd: 'rgb(80, 85, 88)',
  invoiceStatusControleInterno: 'rgba(154, 109, 215, 0.82)',
  invoiceStatusContabilidade: 'rgba(82, 156, 202, 0.9)',
  invoiceStatusAguardandoRecurso: 'rgb(224, 194, 68)',
  invoiceStatusFinalizada: 'rgba(62, 160, 108, 1)',
  invoiceStatusCancelada: 'rgb(222, 91, 82)',

  activeLink: blue,
};
