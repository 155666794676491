import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';

import { createProductUnitRequest } from '../../store/modules/invoice/actions';

import Button from '../Button';
import Select from '../Select';
import Currency from '../Currency';

import { Container } from './styles';

const validationSchemaNewProduct = Yup.object().shape({
  description: Yup.string().required(' '),
  unit: Yup.string().required(' '),
  quantity: Yup.string().required(' '),
  unit_value: Yup.string().required(' '),
  amount: Yup.string().required(' '),
});

export default function FormProduct({ loading, onSubmit }) {
  const units = useSelector(state => state.invoice.allUnits);
  const products = useSelector(state => state.product.allProducts);
  const [productOptions, setProductOptions] = useState([])

  const dispatch = useDispatch();

  useEffect(() => {
    if (products) {
      setProductOptions(() => products.map(product => ({
        value: product.name,
        label: product.name,
      })))
    } 
  }, [products])

  const handleCreateProductUnit = name => {
    dispatch(createProductUnitRequest({ name }));
  };  

  const handleAddProduct = name => {
    setProductOptions(prevState => ([...prevState, { value: name, label: name }]))
  }

  const unitOptions = useMemo(() => {
    return units ? units.map(fundo => ({
      value: fundo.name,
      label: fundo.name,
    })) : []
  }, [units])

  return (
    <Container>
      <Formik
        initialValues={{
          description: '',
          unit: '',
          quantity: '',
          unit_value: '',
          amount: '',
        }}
        validationSchema={validationSchemaNewProduct}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* <h4 className="form-title">Descrição do produto/serviço</h4> */}
            <Row>
              <Col md={5}>
                 <Field
                  component={Select}
                  name="description"
                  label="Descrição do Produto"
                  createMessage="Adicionar"
                  isCreatable
                  onCreateOption={handleAddProduct}
                  options={productOptions}
                  maxHeightMenuList="110px"
                />
              </Col>
              <Col md={1}>
                <Field
                  component={Select}
                  isCreatable
                  name="unit"
                  label="Unid."
                  onCreateOption={option => handleCreateProductUnit(option)}
                  options={unitOptions}
                  maxHeightMenuList="110px"
                />
              </Col>
              <Col md={1}>
                <Currency
                  label="Qtd."
                  name="quantity"
                  value={values.quantity}
                  error={errors.quantity && touched.quantity}
                  onChange={({ target }) => {
                    setFieldValue('amount', target.value * values.unit_value);
                    setFieldValue('quantity', target.value);
                  }}
                  prefix=""
                  fixedDecimalScale
                />
              </Col>
              <Col md={2}>
                <Currency
                  label="Val. unit."
                  name="unit_value"
                  value={values.unit_value}
                  error={errors.unit_value && touched.unit_value}
                  onChange={({ target }) => {
                    setFieldValue('amount', target.value * values.quantity);
                    setFieldValue('unit_value', target.value);
                  }}
                  fixedDecimalScale
                />
              </Col>
              <Col md={2}>
                <Currency
                  label="Total"
                  name="amount"
                  value={values.amount}
                  error={errors.amount && touched.amount}
                  disabled
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Col>
              <Col md={1}>
                <Button
                  icon="FaPlusCircle"
                  className="product__button-add"
                  variant="clean"
                  title="Adicionar"
                  type="submit"
                  loading={loading}
                  loadingSize={12}
                />

                {/* Botão utilizado como âncora para resetar o formulário,
                  pois não conseguia resetar chamando o método depois do submit */}
                <button
                  type="reset"
                  onClick={resetForm}
                  id="button-reset-form-new-product"
                  hidden
                >
                  Reset All
                </button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </Container>
  );
}

FormProduct.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

FormProduct.defaultProps = {
  loading: false,
};
