const Types = {
  LIST_REQUEST: '@fundo/LIST_REQUEST',
  LIST_FAILURE: '@fundo/LIST_FAILURE',
  LIST_SUCCESS: '@fundo/LIST_SUCCESS',

  SEARCH_REQUEST: '@fundo/SEARCH_REQUEST',
  SEARCH_FAILURE: '@fundo/SEARCH_FAILURE',
  SEARCH_SUCCESS: '@fundo/SEARCH_SUCCESS',

  SHOW_REQUEST: '@fundo/SHOW_REQUEST',
  SHOW_FAILURE: '@fundo/SHOW_FAILURE',
  SHOW_SUCCESS: '@fundo/SHOW_SUCCESS',
  RESET_LOADING_SHOW: '@fundo/RESET_LOADING_SHOW',

  CREATE_REQUEST: '@fundo/CREATE_REQUEST',
  CREATE_FAILURE: '@fundo/CREATE_FAILURE',
  CREATE_SUCCESS: '@fundo/CREATE_SUCCESS',

  UPDATE_REQUEST: '@fundo/UPDATE_REQUEST',
  UPDATE_FAILURE: '@fundo/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@fundo/UPDATE_SUCCESS',
};

export default Types;
