import types from './types';

export const listInvoicesRequest = queryParams => ({
  type: types.LIST_REQUEST,
  payload: queryParams,
});
export const listInvoicesSuccess = invoices => ({
  type: types.LIST_SUCCESS,
  payload: { invoices },
});
export const listInvoicesFailure = () => ({
  type: types.LIST_FAILURE,
});

export const allDataToInvoicePageStep1Request = ({ invoiceId }) => ({
  type: types.ALL_DATA_TO_INVOICE_PAGE_STEP1_REQUEST,
  payload: { invoiceId },
});
export const allDataToInvoicePageStep1Success = ({
  providersToSelect,
  providersArray,
  fundosToSelect,
  unitsToSelect,
  invoice,
}) => ({
  type: types.ALL_DATA_TO_INVOICE_PAGE_STEP1_SUCCESS,
  payload: {
    providersToSelect,
    providersArray,
    fundosToSelect,
    unitsToSelect,
    invoice,
  },
});
export const allDataToInvoicePageStep1Failure = () => ({
  type: types.ALL_DATA_TO_INVOICE_PAGE_STEP1_FAILURE,
});

export const showInvoiceRequest = id => ({
  type: types.SHOW_REQUEST,
  payload: { id },
});
export const showInvoiceSuccess = invoice => ({
  type: types.SHOW_SUCCESS,
  payload: { invoice },
});
export const showInvoiceFailure = () => ({
  type: types.SHOW_FAILURE,
});
export const resetLoadingShowInvoice = () => ({
  type: types.RESET_LOADING_SHOW,
});

export const cloneInvoiceRequest = id => ({
  type: types.CLONE_REQUEST,
  payload: { id },
});
export const cloneInvoiceSuccess = invoice => ({
  type: types.CLONE_SUCCESS,
  payload: { invoice },
});
export const cloneInvoiceFailure = () => ({
  type: types.CLONE_FAILURE,
});

export const createInvoiceRequest = invoice => ({
  type: types.CREATE_REQUEST,
  payload: invoice,
});
export const createInvoiceSuccess = invoice => ({
  type: types.CREATE_SUCCESS,
  payload: { invoice },
});
export const createInvoiceFailure = () => ({
  type: types.CREATE_FAILURE,
});

export const updateTotalValue = ({ total_value }) => ({
  type: types.UPDATE_TOTAL_VALUE,
  payload: { total_value },
});

export const updateStep1Request = ({ invoice }) => ({
  type: types.UPDATE_STEP1_REQUEST,
  payload: { invoice },
});
export const updateStep1Success = () => ({
  type: types.UPDATE_STEP1_SUCCESS,
});
export const updateStep1Failure = () => ({
  type: types.UPDATE_STEP1_FAILURE,
});

export const updateStep2Request = invoice => ({
  type: types.UPDATE_STEP2_REQUEST,
  payload: invoice,
});
export const updateStep2Success = () => ({
  type: types.UPDATE_STEP2_SUCCESS,
});
export const updateStep2Failure = () => ({
  type: types.UPDATE_STEP2_FAILURE,
});

export const updateStep3Request = invoice => ({
  type: types.UPDATE_STEP3_REQUEST,
  payload: invoice,
});
export const updateStep3Success = () => ({
  type: types.UPDATE_STEP3_SUCCESS,
});
export const updateStep3Failure = () => ({
  type: types.UPDATE_STEP3_FAILURE,
});

export const updateStep4Request = invoice => ({
  type: types.UPDATE_STEP4_REQUEST,
  payload: invoice,
});
export const updateStep4Success = () => ({
  type: types.UPDATE_STEP4_SUCCESS,
});
export const updateStep4Failure = () => ({
  type: types.UPDATE_STEP4_FAILURE,
});

export const voucherRequest = ({ invoiceId, voucher }) => ({
  type: types.VOUCHER_REQUEST,
  payload: { invoiceId, voucher },
});
export const voucherSuccess = invoice => ({
  type: types.VOUCHER_SUCCESS,
  payload: invoice,
});
export const voucherFailure = () => ({
  type: types.VOUCHER_FAILURE,
});

export const voucherDeleteRequest = ({ invoiceId }) => ({
  type: types.VOUCHER_DELETE_REQUEST,
  payload: { invoiceId },
});
export const voucherDeleteSuccess = invoice => ({
  type: types.VOUCHER_DELETE_SUCCESS,
  payload: invoice,
});
export const voucherDeleteFailure = () => ({
  type: types.VOUCHER_DELETE_FAILURE,
});

export const deleteRequest = ({ invoiceId }) => ({
  type: types.DELETE_REQUEST,
  payload: { invoiceId },
});
export const deleteSuccess = () => ({
  type: types.DELETE_SUCCESS,
});
export const deleteFailure = () => ({
  type: types.DELETE_FAILURE,
});

export const downloadInvoiceReport = filters => ({
  type: types.DOWNLOAD_REPORT_REQUEST,
  payload: filters,
});
export const downloadInvoiceReportSuccess = () => ({
  type: types.DOWNLOAD_REPORT_SUCCESS,
});
export const downloadInvoiceReportFaliure = () => ({
  type: types.DOWNLOAD_REPORT_FAILURE,
});

export const createProductRequest = product => ({
  type: types.CREATE_PRODUCT_REQUEST,
  payload: product,
});
export const createProductSuccess = product => ({
  type: types.CREATE_PRODUCT_SUCCESS,
  payload: { product },
});
export const createProductFailure = () => ({
  type: types.CREATE_PRODUCT_FAILURE,
});

export const updateProductRequest = product => ({
  type: types.UPDATE_PRODUCT_REQUEST,
  payload: product,
});
export const updateProductSuccess = product => ({
  type: types.UPDATE_PRODUCT_SUCCESS,
  payload: { product },
});
export const updateProductFailure = () => ({
  type: types.UPDATE_PRODUCT_FAILURE,
});

export const deleteProductRequest = ({ id }) => ({
  type: types.DELETE_PRODUCT_REQUEST,
  payload: { id },
});
export const deleteProductSuccess = product => ({
  type: types.DELETE_PRODUCT_SUCCESS,
  payload: product,
});
export const deleteProductFailure = () => ({
  type: types.DELETE_PRODUCT_FAILURE,
});

export const createProductUnitRequest = ({ name }) => ({
  type: types.CREATE_PRODUCT_UNIT_REQUEST,
  payload: { name },
});
export const createProductUnitSuccess = ({ unit }) => ({
  type: types.CREATE_PRODUCT_UNIT_SUCCESS,
  payload: { unit },
});
export const createProductUnitFailure = () => ({
  type: types.CREATE_PRODUCT_UNIT_FAILURE,
});
export const listProductUnitSuccess = ({ units }) => ({
  type: types.LIST_PRODUCT_UNIT_SUCCESS,
  payload: { units }
});
