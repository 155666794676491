import styled from 'styled-components';

const bannerImage = process.env.REACT_APP_BANNER_URL;

export const Banner = styled.div`
  flex: 1;
  background: url(${bannerImage}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 700px;

  h1,
  h2 {
    color: #fff;
    margin: 24px;
    z-index: 6;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 42px;
    font-weight: 500;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #1564c0e6;
  }

  .logo-image {
    z-index: 2;
    width: 70%;
    margin: 24px;
    object-fit: cover;
  }

  @media screen and (max-width: 728px) {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 100%;

    h1,
    h2 {
      margin: 12px;
      text-align: center;
    }

    h1 {
      font-size: 52px;
    }

    h2 {
      font-size: 34px;
    }
  }
`;
