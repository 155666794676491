import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-left: 12px;
`;

export const Info = styled.button`
  position: relative;

  border-radius: 5px;
  color: #fff;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  height: 36px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  span {
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
    color: #a8a8b3;
  }

  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 0 6px;
    object-fit: cover;
  }

  ${props =>
    props.visible &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: calc(100% + 9px);
        right: 5px;
        transform: translateX(-50%);
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0px 7.5px 8px;
        border-color: transparent transparent rgb(41, 41, 46);
      }
    `}
`;

export const Content = styled.div`
  position: absolute;
  right: 0px;
  top: calc(100% + 17px);
  width: 112px;
  box-shadow: rgba(0, 0, 0, 0.125) 0px 2px 5px;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  background: #24292e;
  padding: 0px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  color: #fff;

  :first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  :last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  :hover {
    color: #ddd;
  }
`;
