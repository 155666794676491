export const toFixedDecimal = (number, precision = 2) => {
  const spitedValues = String(number.toLocaleString()).split('.');
  let decimalValue = spitedValues.length > 1 ? spitedValues[1] : '';
  decimalValue = decimalValue.concat('00').substr(0, precision);

  return `${spitedValues[0]}.${decimalValue}`;
};

const formatDecimalValue = (value, precision = 2) => {
  if (!value) return Array(precision + 1).join('0');

  const stringValue = String(value);

  if (stringValue.length < precision) {
    return stringValue.padEnd(precision, '0');
  }

  return stringValue.substr(0, precision);
};

export const formatNumberBR = (number, precision = 3) => {
  if (!number) return '';

  const valueBR = Number(number).toLocaleString('pt-br', {
    currency: 'BRL',
    maximumFractionDigits: 12,
  });

  const [intValueBR, decimalValueBR] = valueBR.split(',');

  const formattedDecimalValueBR = formatDecimalValue(decimalValueBR, precision);
  const formattedFullValueBR = `${intValueBR},${formattedDecimalValueBR}`;

  return formattedFullValueBR;
};

const formatNumber = (number, precision = 2) => {
  const numberFixedDecimal = Number(number).toFixed(precision);
  const numberFormatBR = formatNumberBR(numberFixedDecimal, precision);

  return numberFormatBR;
};

export default formatNumber;
