import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const CustomContainerToast = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    padding: 8px 20px;
    border-radius: 3px;
  }

  .Toastify__toast--success {
    background: var(--green-100);
    color: var(--green-800);

    .Toastify__close-button {
      color: var(--green-800);
    }
  }

  .Toastify__toast--error {
    background: var(--red-100);
    color: var(--red-700);

    .Toastify__close-button {
      color: var(--red-700);
    }
  }

  .Toastify__toast--warning {
    background: #ffeeba;
    color: #856404;

    .Toastify__close-button {
      color: #856404;
    }
  }

  .Toastify__toast-body {
    margin: auto 0px;
    font-size: 16px;
    flex: 1 1 0%;
  }
`;
