import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
  margin-bottom: 1.2rem;
`;
export const ContainerInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  input {
    cursor: pointer;
    padding: 0 46px 0px 8px;
    width: 100%;
    z-index: 1;
    background: transparent;
    border-radius: 3px;
    color: #424242;

    height: 36px;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  .input__clear-button {
    position: absolute;
    right: 30px;
    display: flex;
    align-items: center;
    z-index: 4;
    width: 20px;
    color: #cfcfcf;
    cursor: pointer;

    svg {
      fill: currentColor;
    }

    &:hover {
      color: #4a4a4ac4;
    }
  }

  .input__arrow-down {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    z-index: 0;
    height: 100%;
    width: 20px;
    color: #cfcfcf;

    svg {
      fill: currentColor;
    }
  }
`;

export const ContainerMenu = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: space-between;
  background: #fff;
  position: absolute;
  top: 40px;
  left: ${({ horizontalAlign }) => horizontalAlign === 'left' && '0'};
  right: ${({ horizontalAlign }) => horizontalAlign === 'right' && '0'};
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  @media screen and (max-width: 440px) {
    flex-wrap: wrap;
  }
`;

export const Menu = styled.div`
  min-width: 125px;
  display: flex;
  flex-direction: column;

  .menu__option {
    margin-bottom: 5px;
    margin-right: 5px;
    text-align: left;

    padding: 6px 0px;
    border-radius: 3px;
    font-size: 13px;
    background: none;
    cursor: pointer;
    transition: all 0.5 ease-in-out;

    &.active {
      background: #e3f2fd;
      color: #0a69b5;
      padding: 6px;
    }
  }
`;

export const Calendars = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: center;
  flex-direction: column;

  & * {
    font-family: Lato, 'Roboto', Arial, Helvetica, sans-serif !important;
  }

  .calendars__body {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 780px) {
      flex-wrap: wrap;
    }
  }

  .calendars__footer {
    margin: 10px 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      margin: 0 5px;
      padding: 8px 15px;
      background: none;
      color: #444;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;

      &.button--success {
        background: var(--success-color);
        color: var(--success-color-contrast);
      }
    }
  }

  .react-datepicker {
    border: none;
    margin: 5px;
    font-size: 12px;
  }

  .react-datepicker__header {
    border: none;
    background: #fff;
    color: var(--primary-text);
    padding-top: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 14px;
    text-transform: capitalize;
    margin: 12px 0;
    color: var(--primary-text);
  }

  .react-datepicker__navigation {
    top: 10px;
    border: 8px solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ccc;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0px;
    padding: 5px 0px;
    width: 36px;
    text-align: center;
    border-radius: 0;
    text-transform: capitalize;
    margin-bottom: 2px;
    color: var(--primary-text);

    @media screen and (max-width: 500px) {
      /* padding: 5px 0px; */
      width: 30px;
    }
  }

  .react-datepicker__day--disabled {
    color: var(--disabled-color);
  }

  .react-datepicker__day--in-range {
    background: var(--blue-200) !important;
    color: #fff !important;
  }

  .react-datepicker__day--in-selecting-range {
    background: var(--blue-200);
    color: var(--primary-color-contrast);

    &.react-datepicker__day--range-start {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &.react-datepicker__day--range-end {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.react-datepicker__day--selecting-range-start {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background: var(--primary-color) !important;
    }
    &.react-datepicker__day--selecting-range-end {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background: var(--primary-color) !important;
    }
  }

  .react-datepicker__day--range-start {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: var(--primary-color) !important;
  }

  .react-datepicker__day--range-end {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: var(--primary-color) !important;
  }
`;
