export const forgotPasswordRequest = email => ({
  type: '@password/FORGOT_PASSWORD_REQUEST',
  payload: email,
});
export const forgotPasswordSuccess = () => ({
  type: '@password/FORGOT_PASSWORD_SUCCESS',
});
export const forgotPasswordFailure = () => ({
  type: '@password/FORGOT_PASSWORD_FAILURE',
});

export const resetPasswordRequest = ({
  password,
  password_confirmation,
  token,
}) => ({
  type: '@password/RESET_PASSWORD_REQUEST',
  payload: { password, password_confirmation, token },
});
export const resetPasswordSuccess = () => ({
  type: '@password/RESET_PASSWORD_SUCCESS',
});
export const resetPasswordFailure = () => ({
  type: '@password/RESET_PASSWORD_FAILURE',
});
