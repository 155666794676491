import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Upload from '../../../../components/Upload';

import { FooterButtons } from '../../../../styles/containers';
import { formatDate } from '../../../../helpers/formatDate';

import { Container } from './styles';

const validationSchema = Yup.object().shape({
  fonte_recurso: Yup.string().required('Informe a fonte do recurso'),
  status: Yup.string().required('Informe o status que deseja salvar'),
  data_pagamento: Yup.string()
    .when('status', {
      is: val => !!(val === 'finalizada'),
      then: Yup.string().required('Informe a data do pagamento'),
    })
    .nullable(),
  previsao_pagamento_inicio: Yup.string()
    .when('status', {
      is: val => !!(val === 'aguardando_recurso'),
      then: Yup.string().required('Informe a data início da previsão'),
    })
    .nullable(),
  previsao_pagamento_fim: Yup.string()
    .when('status', {
      is: val => !!(val === 'aguardando_recurso'),
      then: Yup.string().required('Informe a data início da previsão'),
    })
    .nullable(),
});

const statusOptions = [
  {
    label: 'Nova',
    value: 'nova',
  },
  {
    label: 'Autorizada',
    value: 'autorizada',
  },
  {
    label: 'Não Autorizada',
    value: 'nao_autorizada',
  },
  {
    label: 'Processada',
    value: 'processada',
  },
  {
    label: 'Aguardando Recurso',
    value: 'aguardando_recurso',
  },
  {
    label: 'Finalizada',
    value: 'finalizada',
  },
  {
    label: 'Cancelada',
    value: 'cancelada',
  },
];

export default function FormTesouraria({
  invoice,
  onSubmit,
  onSubmitVoucher,
  onDeleteVoucher,
  loading,
}) {
  const [initialData, setInitialData] = useState({
    fonte_recurso: '',
    data_pagamento: '',
    status: '',
    previsao_pagamento_inicio: '',
    previsao_pagamento_fim: '',
  });
  const [finishedInvoice, setFinishedInvoice] = useState(false);

  useEffect(() => {
    if (invoice) {
      const {
        fonte_recurso,
        data_pagamento,
        status,
        previsao_pagamento_inicio,
        previsao_pagamento_fim,
      } = invoice;

      if (status === 'finalizada') setFinishedInvoice(true);

      setInitialData({
        fonte_recurso,
        data_pagamento: data_pagamento
          ? formatDate(data_pagamento, 'yyyy-MM-DD')
          : '',
        status,
        previsao_pagamento_inicio: previsao_pagamento_inicio
          ? formatDate(previsao_pagamento_inicio, 'yyyy-MM-DD')
          : '',
        previsao_pagamento_fim: previsao_pagamento_fim
          ? formatDate(previsao_pagamento_fim, 'yyyy-MM-DD')
          : '',
      });
    }
    return () => {
      setInitialData({});
    };
  }, [invoice]);

  const onSubmitInvoice = values => {
    if (onSubmit) {
      const {
        previsao_pagamento_inicio,
        previsao_pagamento_fim,
        data_pagamento,
        ...restData
      } = values;

      const formattedValues = {
        ...restData,
        previsao_pagamento_inicio: previsao_pagamento_inicio || null,
        previsao_pagamento_fim: previsao_pagamento_fim || null,
        data_pagamento: data_pagamento || null,
      };

      onSubmit(formattedValues);
    }
  };

  function handleSubmitVoucher(files) {
    const data = new FormData();

    const file = files[0];
    data.append('voucher', file);

    onSubmitVoucher(data);
  }

  return (
    <Container>
      <Formik
        initialValues={initialData}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmitInvoice(values);
        }}
        enableReinitialize
      >
        {({ handleSubmit, values, errors, setFieldValue, touched }) => (
          <form onSubmit={handleSubmit}>
            <div className="card__header">
              <h3>Tesouraria</h3>
            </div>
            <Row>
              <Col md={6}>
                <Input
                  label="Fonte de recurso"
                  name="fonte_recurso"
                  value={values.fonte_recurso}
                  error={errors.fonte_recurso && touched.fonte_recurso}
                  errorMessage={errors.fonte_recurso}
                  onChange={({ target }) =>
                    setFieldValue('fonte_recurso', target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <Field
                  component={Select}
                  name="status"
                  label="Status"
                  options={statusOptions}
                  disabled={finishedInvoice}
                />
              </Col>

              <Col md={6}>
                <Input
                  label="Previsão de pagamento - início"
                  type="date"
                  name="previsao_pagamento_inicio"
                  value={values.previsao_pagamento_inicio || ''}
                  error={
                    touched.previsao_pagamento_inicio &&
                    errors.previsao_pagamento_inicio
                  }
                  errorMessage={
                    errors.previsao_pagamento_inicio
                      ? errors.previsao_pagamento_inicio
                      : ''
                  }
                  onChange={({ target }) =>
                    setFieldValue('previsao_pagamento_inicio', target.value)
                  }
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Previsão de pagamento - fim"
                  type="date"
                  name="previsao_pagamento"
                  value={values.previsao_pagamento_fim || ''}
                  error={
                    touched.previsao_pagamento_fim &&
                    errors.previsao_pagamento_fim
                  }
                  errorMessage={
                    errors.previsao_pagamento_fim
                      ? errors.previsao_pagamento_fim
                      : ''
                  }
                  onChange={({ target }) =>
                    setFieldValue('previsao_pagamento_fim', target.value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Input
                  label="Data de pagamento"
                  name="data_pagamento"
                  type="date"
                  value={values.data_pagamento || ''}
                  error={errors.data_pagamento && touched.data_pagamento}
                  errorMessage={errors.data_pagamento}
                  onChange={({ target }) =>
                    setFieldValue('data_pagamento', target.value)
                  }
                />
              </Col>
            </Row>

            <Upload
              label="Comprovante de pagamento"
              onUpload={file => handleSubmitVoucher(file)}
              onDelete={() => onDeleteVoucher(invoice.id)}
              previewUrl={invoice && invoice.voucher_url}
            />

            <FooterButtons>
              <Link to="/invoices">
                <Button
                  label="Cancelar"
                  color="black"
                  variant="clean"
                  type="button"
                />
              </Link>

              <Button
                label="Salvar"
                color="success"
                type="submit"
                loading={loading}
                width="85px"
                className="font-medium"
              />
            </FooterButtons>
          </form>
        )}
      </Formik>
    </Container>
  );
}

FormTesouraria.propTypes = {
  invoice: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitVoucher: PropTypes.func,
  onDeleteVoucher: PropTypes.func,
  loading: PropTypes.bool,
};

FormTesouraria.defaultProps = {
  invoice: {},
  onSubmit: () => {},
  onSubmitVoucher: () => {},
  onDeleteVoucher: () => {},
  loading: false,
};
