import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdNotifications } from 'react-icons/md';
import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { toast } from 'react-toastify';
import api from '../../services/api';

import { useSocket } from '../../hooks/socket';

import {
  Container,
  Badge,
  NotificationContent,
  NotificationHeader,
  NotificationList,
  NotificationEmptyMessage,
  Notification,
} from './styles';

export default function InvoiceNotifications() {
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { profile: user } = useSelector(({ profile }) => profile);

  const history = useHistory();
  const notificationsContentRef = useRef(null);
  const buttonRef = useRef(null);

  const { socket } = useSocket();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/invoices/notifications/by-user');

        const formttedNotifications =
          response &&
          response.data.length &&
          response.data.map(_notification => {
            return {
              ..._notification,
              timeDistance: formatDistance(
                parseISO(_notification.createdAt),
                new Date(),
                {
                  addSuffix: true,
                  locale: pt,
                }
              ),
            };
          });

        setNotifications(formttedNotifications);
      } catch (err) {
        toast.error('Erro ao carregar as notificações');
      }
    })();
  }, []);

  useEffect(() => {
    socket.on(`notifications/invoices/${user.type}`, notification => {
      const formattedNotification = {
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.createdAt),
          new Date(),
          {
            addSuffix: true,
            locale: pt,
          }
        ),
      };

      setNotifications(oldNotifications => {
        const notificationsExists = oldNotifications.find(
          findNotitification => findNotitification._id === notification._id
        );

        if (notificationsExists) return oldNotifications;

        return [formattedNotification, ...oldNotifications];
      });
    });
  }, [socket, user.type]);

  function handleClickOutside(event) {
    if (
      notificationsContentRef.current &&
      !notificationsContentRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  });

  const checkIfHasUnread = useMemo(
    () =>
      notifications && notifications.find(notification => !notification.read),
    [notifications]
  );

  const handleMarkAsRead = useCallback(
    async id => {
      try {
        const response = await api.patch(`/invoices/notifications/${id}/read`);

        const updatedNotification = response.data;

        const updatedNotifications = notifications.map(notification =>
          notification._id === updatedNotification._id
            ? {
                ...updatedNotification,
                timeDistance: notification.timeDistance,
              }
            : notification
        );

        setNotifications(updatedNotifications);
      } catch (err) {
        toast.error('Erro ao atualizar notificação');
      }
    },
    [notifications]
  );

  const handleInvoiceEditPage = useCallback(
    id => {
      history.push(`/invoice/${id}/step/${user.type}`);
      setVisible(false);
    },
    [user.type, history]
  );

  return (
    <Container>
      <Badge
        hasUnread={checkIfHasUnread}
        onClick={() => setVisible(!visible)}
        visible={visible}
        ref={buttonRef}
      >
        <MdNotifications color="#fff" size={22} />
      </Badge>

      <NotificationContent visible={visible} ref={notificationsContentRef}>
        <NotificationHeader>Notificações</NotificationHeader>

        <NotificationList>
          {notifications && notifications.length ? (
            <>
              {notifications.map(notification => (
                <Notification
                  key={notification._id}
                  unread={!notification.read}
                >
                  <div className="info">
                    <h5>{notification.content}</h5>
                    <time>{notification.timeDistance}</time>
                  </div>
                  <div className="actions">
                    {!notification.read && (
                      <button
                        type="button"
                        className="button__mark-as-read"
                        onClick={() => handleMarkAsRead(notification._id)}
                      >
                        Marcar como lida
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={() =>
                        handleInvoiceEditPage(notification.invoice_id)
                      }
                    >
                      Visualizar
                    </button>
                  </div>
                </Notification>
              ))}
            </>
          ) : (
            <NotificationEmptyMessage>
              Não há notificações
            </NotificationEmptyMessage>
          )}
        </NotificationList>
      </NotificationContent>
    </Container>
  );
}
